import React, { useEffect } from "react";
import Dashboard from "../containers/Dashboard";
import Settings from "../containers/Settings/SettingsPage";
import Awareness from "../containers/Awareness&OutreachPage";
import OperatorTrainee from "../containers/OperatorTraineesPage";
import Supervisor from "../containers/SupervisorsPage";
import Users from "../containers/UsersPage";
import Operators from "../containers/OperatorsPage";
import ChiefOperator from "../containers/ChiefOperatorPage";
import LoginPage from "../containers/LoginPage";
import PageNotFound from "../components/PageNotFound";
import ProfilePage from "../containers/ProfilePage";
//Dashboard
import DashboardOperators from "../containers/Dashboard/Operators";
import DashboardSupervision from "../containers/Dashboard/Supervision";
import DashboardSupervisorDuties from "../containers/Dashboard/SupervisorDuties";
import DashboardTraineeOperators from "../containers/Dashboard/TraineeOperators";

import { Switch, Route, useLocation } from "react-router-dom";
import AuthRoute from "./authRoute";
import PublicRoute from "./publicRoute";
import { clearAlert } from "../redux/actions/alertActions";
import { useDispatch } from "react-redux";
import Notifications from "../containers/Notifications";

function Routes(props) {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearAlert());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Switch>
      <PublicRoute exact path="/" component={LoginPage} />;
      <AuthRoute exact path="/dashboard" component={Dashboard} />
      <AuthRoute
        exact
        path="/dashboard/operators"
        component={DashboardOperators}
      />
      <AuthRoute
        exact
        path="/dashboard/supervision"
        component={DashboardSupervision}
      />
      <AuthRoute
        exact
        path="/dashboard/supervisor-duties"
        component={DashboardSupervisorDuties}
      />
      <AuthRoute
        exact
        path="/dashboard/trainee-operators"
        component={DashboardTraineeOperators}
      />
      <AuthRoute exact path="/operators" component={Operators} />
      <AuthRoute exact path="/operatorTrainee" component={OperatorTrainee} />
      <AuthRoute exact path="/chiefOperator" component={ChiefOperator} />
      <AuthRoute exact path="/awareness" component={Awareness} />
      <AuthRoute exact path="/supervisor" component={Supervisor} />
      <AuthRoute exact path="/settings" component={Settings} />
      <AuthRoute exact path="/users" component={Users} />
      <AuthRoute exact path="/profile" component={ProfilePage} />
      <AuthRoute exact path="/notifications" component={Notifications} />
      <Route path="*" exact={true} component={PageNotFound} />
    </Switch>
  );
}

export default Routes;
