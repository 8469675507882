import React, { useState, useEffect } from "react";
import "../components/Calendar";
import CalendarComponent from "../components/Calendar";
import TraineesList from "../components/Trainee Components/TraineesList";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchTrainees,
  approveTrainee,
  rejectTrainee,
  getTraineeShifts,
  clearMessage,
} from "../redux/actions/traineesActions";
import { fetchSettings } from "../redux/actions/settingsActions";
import moment from "moment";
import ShiftsModal from "./Modals/Operators And Trainees/ShiftsModal";
import CalendarLegend from "../components/CalendarLegend";
import { getOperatorsShifts } from "../redux/actions/operatorsActions";
import Alert from "../components/Alert";

function OperatorTrainee(props) {
  const dispatch = useDispatch();
  const [date, setDate] = useState();
  const trainees = useSelector((state) => state.trainees.trainees);
  const shifts = useSelector((state) => state.trainees.shifts);
  const settings = useSelector((state) => state.settings.settings);
  const operatorShifts = useSelector((state) => state.operators.shifts);
  const isLoading = useSelector((state) => state.loading.isFetchingTrainees);

  const onDateClick = (date) => {
    let month = date.split("-")[1];
    let year = date.split("-")[0];
    dispatch(getOperatorsShifts(month, year));
    dispatch(getTraineeShifts(month, year));
    dispatch(clearMessage());
    setDate(date);
    document.getElementById("shiftsModalButton").click();
  };

  const onMonthChange = (month, year) => {
    dispatch(getOperatorsShifts(month, year));
    dispatch(getTraineeShifts(month, year));
  };

  //approve or decline trainee
  const handleTraineeState = (userId, status) => {
    if (status) dispatch(approveTrainee(userId));
    else dispatch(rejectTrainee(userId));
  };

  useEffect(() => {
    const initTrainees = () => {
      const curr = moment(date);
      const currentMonth = curr.format("MM");
      const currentYear = curr.format("YYYY");
      if (!shifts) dispatch(getTraineeShifts(currentMonth, currentYear));
      if (!operatorShifts)
        dispatch(getOperatorsShifts(currentMonth, currentYear));
      if (!settings) dispatch(fetchSettings());
    };

    initTrainees();
  }, []);

  const viewTraineesButton = () => {
    return (
      <button
        onClick={() => dispatch(fetchTrainees())}
        id="traineeModalButton"
        data-toggle="modal"
        data-target="#operatorTraineeModal"
        className="btn btn-green w-100 mb-10"
      >
        View Trainees
      </button>
    );
  };

  return (
    <div className="content-wrapper">
      <section className="content-header page-title">
        <h1>Trainees</h1>
      </section>
      <Alert />
      {settings && shifts && (
        <CalendarComponent
          onDateClick={onDateClick}
          shifts={shifts}
          maxPerDay={settings.operators_per_shift * settings.shits_per_day}
          onMonthChange={onMonthChange}
        >
          <CalendarLegend>{viewTraineesButton()}</CalendarLegend>
        </CalendarComponent>
      )}

      {/* button to launch the shift modal */}
      <button
        id="shiftsModalButton"
        className="btn btn-primary hidden"
        data-toggle="modal"
        data-target="#shiftsModal"
      >
        Launch Modal
      </button>

      {/* list of trainees modal */}
      <TraineesList
        trainees={trainees}
        handleTraineeState={handleTraineeState}
        isLoading={isLoading}
      />

      {/* list of shifts modal */}
      {date && operatorShifts && (
        <ShiftsModal selectedDate={date} userType="trainee" />
      )}
    </div>
  );
}

export default OperatorTrainee;
