import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../../components/Alert";
import { ExportCSV } from "../../components/ExportCSV";
import DataTable from "react-data-table-component";
import { fetchSupervisionData } from "../../redux/actions/dashboardActions";
import { getLocalTime } from "../../helpers";
import { useState } from "react";

const headers = [
  { label: "Date of Session", key: "from_date" },
  { label: "Facilitator", key: "name" },
  { label: "Type of Session", key: "type" },
  { label: "Number of attendees", key: "total_attendees" },
];
const columns = [
  {
    name: "Date of Session",
    selector: "from_date",
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {getLocalTime(row.from_date, "MM/DD/YYYY HH:mm")}
      </div>
    ),
  },
  {
    name: "Facilitator",
    selector: "name",
    sortable: true,
  },
  {
    name: "Type of Session",
    selector: "type",
    sortable: true,
  },
  {
    name: "Number of attendees",
    selector: "total_attendees",
    sortable: true,
  },
];

function Supervision() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dashboard.supervision);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const fetchData = () => {
    dispatch(fetchSupervisionData(fromDate, toDate));
  };

  return (
    <div className="content-wrapper">
      <div className="wrap">
        {/* add user button triggers the add user modal*/}
        <section className="content-header pl-0 page-title flex">
          <h1 className="flexGrow">Dashboard - Supervision</h1>
          <ExportCSV fileName="supervision" csvData={data} headers={headers} />
        </section>
        <Alert />
        <div style={{ display: "flex", marginBottom: "20px" }}>
          <input
            type="date"
            className="form-control"
            style={{ marginRight: "20px" }}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <input
            type="date"
            className="form-control"
            style={{ marginRight: "20px" }}
            onChange={(e) => setToDate(e.target.value)}
            min={fromDate}
          />
          <button
            disabled={!fromDate || !toDate}
            className="btn btn-primary"
            onClick={fetchData}
          >
            Submit
          </button>
        </div>

        <DataTable
          title="Supervision"
          columns={columns}
          data={data}
        />
      </div>
    </div>
  );
}

export default Supervision;
