import React, { useState } from "react";

function PushAnnouncement(props) {
  const [message, setMessage] = useState("");
  const [error, hasError] = useState(false);

  const checkError = () => {
    if (error) return "has-error";
    else return "";
  };

  const validate = () => {
    let flag = true;
    if (message.length === 0) {
      flag = false;
      hasError(true);
    }

    if (flag) {
      hasError(false);
      setMessage("");
      props.sendAnnouncement(message);
    }
  };

  return (
    <div className="box box-success collapsed-box">
      <div className="box-header with-border">
        <h3 className="box-title">Push Announcement</h3>

        <div className="box-tools pull-right">
          <button
            type="button"
            className="btn btn-box-tool"
            data-widget="collapse"
          >
            <i className="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <div className={"box-body form-group " + checkError()}>
        <div className="input-group ml-20 ">
          <input
            type="text"
            name="message"
            placeholder="Type Message ..."
            className="form-control"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <span className="input-group-btn">
            <button
              className="btn btn-green"
              type="button"
              onClick={() => validate()}
            >
              Send
            </button>
          </span>
        </div>
        {error && <span class="ml-20 help-block">Please enter a message.</span>}
      </div>
    </div>
  );
}

export default PushAnnouncement;
