import { usersConstants, API_URL } from "../constants";
import axios from "axios";
import { successAlert, errorAlert } from "./alertActions";
import {
  updatingUser,
  updatingUserPermissions,
  showLoadingOverlay,
} from "./loadingActions";

const isFetching = () => ({
  type: usersConstants.FETCH_USERS,
});

const fetchUsersSuccess = (users) => ({
  type: usersConstants.FETCH_USERS_SUCCESS,
  payload: users,
});

const fetchUsersFailure = () => {
  return { type: usersConstants.FETCH_USERS_FAILURE };
};

const addUserSuccess = (user) => {
  return { type: usersConstants.ADD_USER, payload: user };
};

export const deleteUserSuccess = (userId) => {
  return { type: usersConstants.DELETE_USER, payload: userId };
};

const updateUserSuccess = (newUser) => {
  return {
    type: usersConstants.UPDATE_USER,
    payload: newUser,
  };
};

const updatePermissionsSuccess = (roles, userId) => ({
  type: usersConstants.UPDATE_PERMISSIONS_SUCCESS,
  payload: { roles: roles, userId: userId },
});

const userError = (message) => ({
  type: usersConstants.SHOW_ERROR_MESSAGE,
  payload: message,
});

export const clearErrorMessage = () => {
  return function (dispatch) {
    dispatch({
      type: usersConstants.CLEAR_ERROR_MESSAGE,
    });
  };
};

export const fetchUsers = (start, rows) => {
  let startNum = start ? start : -1;
  let rowsNum = rows ? rows : -1;

  return function (dispatch) {
    dispatch(isFetching());
    return axios
      .get(API_URL + `/admin/get_user_list?start=${startNum}&rows=${rowsNum}`)
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(fetchUsersSuccess(response.data.users));
        } else if (
          response.data.status === "0" &&
          response.data.msg === "No Users"
        ) {
          dispatch(fetchUsersSuccess([]));
        }
      })
      .catch((error) => {
        dispatch(fetchUsersFailure());
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const resetUserPassword = id => {
  return function (dispatch){
    dispatch(updatingUser(true));
    return axios.post(`${API_URL}/api/reset_user_password`, id).then(response => {
      if (response.data.status === "1") {
        dispatch(successAlert("Password changed successfully!"));
        //close the modal
        document.getElementById("user-modal-close-btn").click();
      } else {
        dispatch(userError(response.data.msg));
      }
      dispatch(updatingUser(false));
    })
    .catch((error) => {
      dispatch(updatingUser(false));
      document.getElementById("user-modal-close-btn").click();
      dispatch(errorAlert("Something went wrong.."));
    });
  }
}

export const removeProfilePicture = id => {
  return function (dispatch){
    dispatch(updatingUser(true));
    return axios.post(`${API_URL}/api/delete_user_avatar`, id).then(response => {
      if (response.data.status === "1") {
        dispatch(successAlert("Picture has been deleted!"));
        //close the modal
        document.getElementById("user-modal-close-btn").click();
      } else {
        dispatch(userError(response.data.msg));
      }
      dispatch(updatingUser(false));
    })
    .catch((error) => {
      dispatch(updatingUser(false));
      document.getElementById("user-modal-close-btn").click();
      dispatch(errorAlert("Something went wrong.."));
    });
  }
}

export const getUserById = (id) => {
  return function (dispatch) {
    return axios
      .get(API_URL + `/admin/get_user_by_id?id=${id}`)
      .then((response) => {
        if (response.data.status === "1")
          dispatch(updateUserSuccess(response.data.user));
        else dispatch(errorAlert("Something went wrong.."));
      })
      .catch((error) => dispatch(errorAlert("Something went wrong..")));
  };
};

export const addUser = (userData) => {
  return function (dispatch) {
    dispatch(updatingUser(true));
    return axios
      .post(API_URL + "/admin/add_user", userData)
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(addUserSuccess(response.data.user));
          document.getElementById("user-modal-close-btn").click();
          dispatch(successAlert("User is addedd successfully!"));
        } else {
          dispatch(userError(response.data.msg));
        }
        dispatch(updatingUser(false));
      })
      .catch((error) => {
        dispatch(updatingUser(false));
        document.getElementById("user-modal-close-btn").click();
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const deleteUser = (userId) => {
  return function (dispatch) {
    dispatch(showLoadingOverlay(true));
    return axios
      .post(API_URL + "/admin/delete_user", userId)
      .then((response) => {
        if (response.data.status === "1") {
          let userid = userId.get("id");
          // let date = moment().format("YYYY-MM-DD");
          dispatch(deleteUserSuccess(userid));
          // dispatch(removeUserFromTrainees(userid, date));
          // dispatch(removeUserFromOperators(userid, date));
          // dispatch(removeUserFromChiefs(userid, date));
          // dispatch(removeUserFromSessions(userid, date));
          // dispatch(removeUserFromMeetings(userid, date));
          dispatch(successAlert("User deleted successfully"));
        } else {
          dispatch(errorAlert(response.data.msg));
        }
        dispatch(showLoadingOverlay(false));
      })
      .catch((error) => {
        dispatch(showLoadingOverlay(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const updateUser = (updatedUser) => {
  return function (dispatch) {
    dispatch(updatingUser(true));
    return axios
      .post(API_URL + "/admin/edit_user", updatedUser)
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(updateUserSuccess(response.data.user));
          dispatch(successAlert("User updated successfully!"));
          //close the modal
          document.getElementById("user-modal-close-btn").click();
        } else {
          dispatch(userError(response.data.msg));
        }
        dispatch(updatingUser(false));
      })
      .catch((error) => {
        dispatch(updatingUser(false));
        document.getElementById("user-modal-close-btn").click();
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const updateUserPermissions = (data) => {
  return function (dispatch) {
    dispatch(updatingUserPermissions(true));
    return axios
      .post(API_URL + "/admin/update_user_roles", data)
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(
            updatePermissionsSuccess(response.data.roles, data.get("id"))
          );
          dispatch(successAlert("Permissions updated successfully"));
        } else {
          dispatch(errorAlert(response.data.msg));
        }
        dispatch(updatingUserPermissions(false));
        document.getElementById("permissions-close-btn").click();
      })
      .catch((error) => {
        dispatch(updatingUserPermissions(false));
        document.getElementById("permissions-close-btn").click();
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};
