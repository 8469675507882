import React, { useEffect, useState } from "react";
import $ from "jquery";
import select2 from "select2";
import { useSelector } from "react-redux";
import BoxLoading from "../BoxLoading";
import Notification from "../Shifts Components/Notification";
import { sort } from "../../helpers";

const selectUserPermissions = (userRoles) => {
  let idsArray = [];
  for (let index = 0; index < userRoles.length; index++) {
    idsArray.push(userRoles[index].id);
  }
  $(".edit-permissions-select").val(idsArray);
};

const renderPermissions = (permissions) => {
  let sortedPermissions = sort(permissions, "name")

  return sortedPermissions.map((item, index) => {
    return (
      <option key={"edit-permission-" + item.id} value={item.id}>
        {item.name}
      </option>
    );
  });
};

function PermissionsModal(props) {
  const { user, permissions, editPermissions } = props;
  const [errorMessage, hasError] = useState("");
  const loading = useSelector((state) => state.loading.isUpdatingPermissions);
  const message1 =
    "A user can't be an operator and an operator trianee at the same time.";
  const message2 =
    "A user can't be an awareness officer and awareness trianee at the same time.";

  useEffect(() => {
    hasError("");
    user && selectUserPermissions(user.roles);
    $(".select2").select2();
  }, [user]);

  const updatePermissions = (e) => {
    e.preventDefault();

    const selectedPermissions = $(".edit-permissions-select").select2("data");

    let operatorError = selectedPermissions.filter(
      (shift) => shift.id === "2" || shift.id === "3"
    );

    let officerError = selectedPermissions.filter(
      (shift) => shift.id === "5" || shift.id === "6"
    );

    if (operatorError.length >= 2) {
      hasError(message1);
    } else if (officerError.length >= 2) {
      hasError(message2);
    } else {
      hasError("");
      //format the permissions
      let permissions_string = "";
      for (let index = 0; index < selectedPermissions.length; index++) {
        let current = selectedPermissions[index];
        index === selectedPermissions.length - 1
          ? (permissions_string += current.id)
          : (permissions_string += current.id + ",");
      }

      let formdata = new FormData();
      formdata.append("roles", permissions_string);
      formdata.append("id", user.id);
      editPermissions(formdata);
    }
  };

  return (
    <div className="modal fade" id="PermissionsModal">
      <div className="modal-dialog">
        <div className="modal-content box">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 className="modal-title">
              Update {user && user.name}'s Permissions
            </h4>
          </div>
          <div className="modal-body">
            {errorMessage.length > 0 && (
              <Notification message={errorMessage} type="danger" />
            )}
            <form id="updatePermissionsForm" onSubmit={updatePermissions}>
              <div className="form-group">
                <label>Permissions</label>
                <select
                  className="select2 edit-permissions-select"
                  multiple="multiple"
                  style={{ width: "100%" }}
                  required
                >
                  {renderPermissions(permissions)}
                </select>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-green"
              type="submit"
              form="updatePermissionsForm"
            >
              Save changes
            </button>
            <button
              type="button"
              className="btn btn-dark-gray"
              data-dismiss="modal"
              id="permissions-close-btn"
            >
              Cancel
            </button>
          </div>
          {loading && <BoxLoading />}
        </div>
      </div>
    </div>
  );
}

export default PermissionsModal;
