import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import Alert from "../components/Alert";

const SRC = "https://embrace-dashboard.herokuapp.com/";

function Dashboard() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      let iframe = document.getElementById("dashboard-iframe");
      if (iframe) iframe.src = SRC;
    }
  }, [location]);

  useEffect(() => {
    window.scroll(0, 0);
    document.body.style.overflowY = "hidden";

    return () => (document.body.style.overflowY = "scroll");
  }, []);
  return (
    <Redirect to="/dashboard/operators" />
    // <div className="content-wrapper">
    //   <Alert />
    //   <div className="iframe-wrapper">
    //     <iframe
    //       id="dashboard-iframe"
    //       frameborder="0"
    //       src={SRC}
    //       className="dashboard-iframe"
    //       title="dashboard-iframe"
    //     ></iframe>
    //   </div>
    // </div>
  );
}

export default Dashboard;
