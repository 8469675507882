import React from "react";
import { Link } from "react-router-dom";
import "../../css/stylesheet.css";

function Sidebar() {
  return (
    <aside className="main-sidebar fixed">
      <section className="sidebar mt-20">
        <ul className="sidebar-menu">
          {/* <li>
            <Link to="/dashboard" replace>
              <i className="fa fa-th"></i>
              <span>Dashboard / Analytics </span>
            </Link>
          </li> */}
          <li className="active treeview">
            <a href="#">
              <i className="fa fa-dashboard"></i> <span>Dashboard</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-left pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li>
                <Link to="/dashboard/operators">
                  <i className="fa fa-circle-o"></i> Operators
                </Link>
              </li>
              <li className="active">
                <Link to="/dashboard/supervision">
                  <i className="fa fa-circle-o"></i> Supervision
                </Link>
              </li>
              <li className="active">
                <Link to="/dashboard/supervisor-duties">
                  <i className="fa fa-circle-o"></i> Supervisor Duties
                </Link>
              </li>
              <li className="active">
                <Link to="/dashboard/trainee-operators">
                  <i className="fa fa-circle-o"></i> Trainee Operators
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/operators">
              <i className="fa fa-user"></i>
              <span>Operators </span>
            </Link>
          </li>
          <li>
            <Link to="/operatorTrainee">
              <i className="fa fa-user"></i>
              <span>Operator Trainee </span>
            </Link>
          </li>
          <li>
            <Link to="/chiefOperator">
              <i className="fa fa-user"></i>
              <span>Chief Operator </span>
            </Link>
          </li>
          <li>
            <Link to="/supervisor">
              <i className="fa fa-user"></i>
              <span> Supervisor </span>
            </Link>
          </li>
          <li>
            <Link to="/awareness">
              <i className="fa fa-group"></i>
              <span>Awareness & Outreach </span>
            </Link>
          </li>
          <li>
            <Link to="/users">
              <i className="fa fa-user"></i>
              <span>Users </span>
            </Link>
          </li>
          <li>
            <Link to="/settings">
              <i className="fa fa-cog"></i>
              <span>Settings </span>
            </Link>
          </li>
          <li>
            <Link to="/notifications">
              <i className="fa fa-bell"></i>
              <span>Notifications </span>
            </Link>
          </li>
        </ul>
      </section>
      {/* /.sidebar */}
    </aside>
  );
}

export default Sidebar;
