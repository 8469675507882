import { loadingConstants } from "../constants";

export const updatingProfile = (status) => ({
  type: loadingConstants.UPDATING_PROFILE,
  payload: status,
});

export const updatingEmail = (status) => ({
  type: loadingConstants.UPDATING_EMAIL,
  payload: status,
});

export const updatingPassword = (status) => ({
  type: loadingConstants.UPDATING_PASSWORD,
  payload: status,
});

export const updatingOperatorsModal = (status) => ({
  type: loadingConstants.OPERATORS_MODAL,
  payload: status,
});

export const updatingSupervisorsModal = (status) => ({
  type: loadingConstants.SUPERVISORS_MODAL,
  payload: status,
});

export const updatingUser = (status) => ({
  type: loadingConstants.ADD_USER_MODAL,
  payload: status,
});

export const showLoadingOverlay = (status) => ({
  type: loadingConstants.LOADING_OVERLAY,
  payload: status,
});
export const fetchTraineesLoading = (status) => ({
  type: loadingConstants.FETCH_TRAINEES_LOADING,
  payload: status,
});

export const updatingUserPermissions = (status) => ({
  type: loadingConstants.PERMISSIONS_MODAL,
  payload: status,
});

export const loadingMeetings = (status) => ({
  type: loadingConstants.MEETINGS_MODAL_LOADING,
  payload: status,
});

export const loadingDashboardOperators = (status) => ({
  type: loadingConstants.DASHBOARD_OPERATORS_TABLE,
  payload: status,
});
