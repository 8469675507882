import React, { useState, useEffect } from "react";
import CalendarComponent from "../components/Calendar";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { fetchSettings } from "../redux/actions/settingsActions";
import { getChiefsShifts } from "../redux/actions/chiefsActions";
import Alert from "../components/Alert";
import { clearMessage } from "../redux/actions/chiefsActions";
import ShiftsModal from "./Modals/Operators And Trainees/ShiftsModal";


function ChiefOperator() {
  const dispatch = useDispatch();
  const [date, setDate] = useState();
  const shifts = useSelector((state) => state.chiefs.shifts);
  const settings = useSelector((state) => state.settings.settings);

  const onDateClick = (date) => {
    let month = date.split("-")[1];
    let year = date.split("-")[0];

    dispatch(getChiefsShifts(month, year));
    setDate(date);
    //trigger click event on the hidden button to show the modal
    dispatch(clearMessage());
    document.getElementById("chiefOperatorsButton").click();
  };

  const onMonthChange = (month, year) => {
    dispatch(getChiefsShifts(month, year));
  };

  useEffect(() => {
    const curr = moment(date);
    const currentMonth = curr.format("MM");
    const currentYear = curr.format("YYYY");
    if (!shifts) dispatch(getChiefsShifts(currentMonth, currentYear)); //send month + year
    if (!settings) dispatch(fetchSettings());
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header page-title">
        <h1>Chief Operator</h1>
      </section>
      <Alert />
      {settings && shifts && (
        <center>
          <div className="calendar">
            <CalendarComponent
              onDateClick={onDateClick}
              shifts={shifts}
              maxPerDay={settings.chief_operators_per_day}
              type="chief"
              onMonthChange={onMonthChange}
            />
          </div>
        </center>
      )}

      {/* Launch Modal Button  */}
      <button
        id="chiefOperatorsButton"
        className="btn btn-primary hidden"
        data-toggle="modal"
        data-target="#shiftsModal"
      >
        Launch Modal
      </button>

      {/* Modal */}
      {settings && shifts && (
        <ShiftsModal selectedDate={date} userType="chief" />
      )}
    </div>
  );
}

export default ChiefOperator;
