import React, { useState, useEffect } from "react";
import UsersDropdown from "./UsersDropdown";

function AddAttendeeForm(props) {
  const [showForm, toggleShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [errorMsg, hasError] = useState(false);

  const { onSubmit, data, userType } = props;

  useEffect(() => {
    hasError(false);
  }, [props]);

  //handles form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!selectedUser) hasError(true);
    else {
      onSubmit(selectedUser, data, userType);
      setSelectedUser(null);
      hasError(false);
      toggleShow(false);
    }
  };

  //renders the form to assign a user to the shift
  const renderForm = () => {
    return (
      <>
        <form onSubmit={handleFormSubmit} className="flex">
          <div className="input-group flexGrow">
            <UsersDropdown
              setSelectedUser={setSelectedUser}
              userType={userType}
            />

            <span className="input-group-btn">
              <button
                className="green bg-white btn users-select-cancel-btn"
                type="submit"
                title="Add"
              >
                <i className="fa fa-plus"></i> Add
              </button>
            </span>
          </div>
          <i
            className="fa fa-minus-circle add-circles text-red"
            onClick={() => {
              toggleShow(false);
              hasError(false);
            }}
          ></i>
        </form>
        {errorMsg && showForm && (
          <small className="text-danger pl-10 ">Please select a user</small>
        )}
      </>
    );
  };

  return (
    <div className="mr-6">
      {showForm ? (
        renderForm()
      ) : (
        <i
          className="fa fa-plus-circle add-circles"
          onClick={() => {
            toggleShow(true);
            hasError(false);
          }}
        ></i>
      )}
    </div>
  );
}

export default AddAttendeeForm;
