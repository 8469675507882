import React from "react";
import "../../css/stylesheet.css";
import SessionAttendee from "./SessionAttendee";
import AddAttendeeForm from "./AddAttendeeForm";
import { getLocalTime } from "../../helpers";

function SessionInformation(props) {
  const { item, onDelete, onAddAttendee, onUpdateAttendee } = props;

  //handles add attendee form submit
  const onSubmit = (userid, date, type) => {
    let formdata = new FormData();
    formdata.append("session_id", item.id);
    formdata.append("date", date);
    formdata.append("user_id", userid);
    formdata.append("type", type);

    onAddAttendee(formdata);
  };

  const updateAttendee = (userid, id, type) => {
    let formdata = new FormData();
    formdata.append("user_id", userid);
    formdata.append("session_id", item.id);
    formdata.append("date", item.from_date);
    formdata.append("id", id);
    formdata.append("type", type);

    onUpdateAttendee(formdata);
  };

  const addAttendeeForm = (type, title) => {
    return (
      <div className="session-list">
        <p className="session-list-item title">{title}</p>
        <AddAttendeeForm
          data={item.from_date}
          onSubmit={onSubmit}
          userType={type}
        />
      </div>
    );
  };

  const renderAttendees = (user, title, type) => {
    return (
      <SessionAttendee
        key={"session-attendee-" + user.id}
        session={user}
        onDelete={onDelete}
        updateAttendee={updateAttendee}
        title={title} //for titles
        type={type}
      />
    );
  };

  const getAttendee = (type, attendees) => {
    switch (type) {
      case "trainee":
        if (attendees) {
          let user = attendees.filter((user) => Number(user.type) === 2);
          if (user && user.length > 0) {
            return renderAttendees(user[0], "Trainee", 2);
          } else {
            return addAttendeeForm(2, "Trainee");
          }
        } else {
          return addAttendeeForm(2, "Trainee");
        }
      case "officer":
        if (attendees) {
          let user = attendees.filter((user) => Number(user.type) === 0);
          if (user && user.length > 0) {
            return renderAttendees(user[0], "Officer", 0);
          } else {
            return addAttendeeForm(0, "Officer");
          }
        } else {
          return addAttendeeForm(0, "Officer");
        }
      case "facilitator":
        if (attendees) {
          let user = attendees.filter((user) => Number(user.type) === 1);
          if (user && user.length > 0) {
            return renderAttendees(user[0], "Facilitator", 1);
          } else {
            return addAttendeeForm(1, "Facilitator");
          }
        } else {
          return addAttendeeForm(1, "Facilitator");
        }
      default:
        return;
    }
  };

  return (
    <div>
      <p className="title">
        Date: <span className="span-data">{getLocalTime(item.from_date,"dddd MMM DD, YYYY")}</span>
      </p>
      <p className="title">
        Time: <span className="span-data">{getLocalTime(item.from_date, "hh:mm A")}</span>
      </p>
      <p className="title">
        Location: <span className="span-data">{item.location}</span>
      </p>
      <p className="title">
        Audience: <span className="span-data">{item.target}</span>
      </p>
      <p className="title">
        Language: <span className="span-data">{item.language}</span>
      </p>
      <p className="title">
        Notes: <span className="span-data">{item.notes}</span>
      </p>
      <p className="title">
        Compensated: <span className="span-data">{item.cost} {item.currency}</span>
      </p>

      {getAttendee("officer", item.attendees)}
      {getAttendee("facilitator", item.attendees)}
      {getAttendee("trainee", item.attendees)}
    </div>
  );
}

export default SessionInformation;
