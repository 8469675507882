import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/actions/authenticatedUserActions";
import BoxLoading from "../components/BoxLoading";

const errorMessage = () => {
  return (
    <div className="alert alert-danger">
      The username or password you entered is wrong!
    </div>
  );
};

function LoginPage(props) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [rememberMe, setRememberMe] = useState(false);

  const loading = useSelector((state) => state.authenticatedUser.validating);
  const hasError = useSelector(
    (state) => state.authenticatedUser.didInvalidate
  );

  document.body.classList.add("bg-green");

  const onSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("email", email);
    formdata.append("password", password);
    dispatch(login(formdata, rememberMe));
  };

  return (
    <div className="login-wrapper bg-green">
      <div className="login-box">
        <div className="login-logo">
          <a href="/">
            <span className="logo-lg logo-image">
              <img src="/images/embrace-logo.png" alt="Embrace Logo" />
            </span>
          </a>
        </div>

        <div className="box login-box-body">
          {hasError && errorMessage()}
          <form onSubmit={onSubmit}>
            <div className="form-group has-feedback mt-30">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
            </div>
            <div className="form-group has-feedback">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="glyphicon glyphicon-lock form-control-feedback"></span>
            </div>
            <div className="row mt-20">
              <div className="col-xs-8">
                <div className="checkbox icheck-success d-inline">
                  <input
                    type="checkbox"
                    id="rememberMe"
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <label htmlFor="rememberMe">Remember Me</label>
                </div>
              </div>
              <div className="col-xs-4">
                <button type="submit" className="btn btn-green">
                  Sign In
                </button>
              </div>
            </div>
          </form>
          <br />
          {loading && <BoxLoading />}
        </div>
        <div className="text-center">
            <img
              src="/images/atf-logo.png"
              alt="Embrace Logo"
              id="atf-img-logo"
            />
          </div>
      </div>
    </div>
  );
}

export default LoginPage;
