import moment from "moment-timezone";
import store from "./redux/store";

export const getOperatorShifts = (selectedDate) => {
  const dayStartTime = store.getState().settings.settings.starttime;
  const shiftsPerDay = store.getState().settings.settings.shits_per_day;
  const hoursPerShift = store.getState().settings.settings.hours_per_shift;

  //First shift start time in Beirut
  let shiftTime = moment(selectedDate + " " + dayStartTime).format(
    "YYYY-MM-DD HH:mm:ss"
  );

  //first shift start date in UTC
  const beirutOffset = moment.tz(shiftTime, "EET").utcOffset();
  const shiftStartDateUTC = moment(shiftTime)
    .subtract(beirutOffset, "minutes")
    .toDate();

  //UTC offset for the selected date
  const offset = moment(selectedDate + " " + dayStartTime).utcOffset();

  //first shift's local start time to display the shifts
  const localStartDate = moment(shiftTime)
    .add(offset - beirutOffset, "minutes")
    .format("YYYY-MM-DD HH:mm:ss");

  let localShiftTime = moment(localStartDate);
  let utcShiftTime = moment(shiftStartDateUTC);
  let shifts = [];

  for (let i = 1; i <= shiftsPerDay; i++) {
    let utcFromDate = moment(utcShiftTime).format("YYYY-MM-DD HH:mm:ss");
    let displayStartDate = localShiftTime.format("hh:mm A");
    localShiftTime = localShiftTime.add(hoursPerShift, "hours");
    utcShiftTime = utcShiftTime.add(hoursPerShift, "hours");
    localShiftTime = moment(
      `${selectedDate} ${localShiftTime.format("HH:mm:ss")}`
    );
    let utcEndDate = moment(utcShiftTime).format("YYYY-MM-DD HH:mm:ss");

    let displayEndDate = localShiftTime.format("hh:mm A");
    let displayDate = `${displayStartDate} - ${displayEndDate}`;

    utcShiftTime = moment(`${selectedDate} ${utcShiftTime.format("HH:mm:ss")}`);

    shifts.push({ utcFromDate, utcEndDate, displayDate });
  }

  return shifts;
};

export const formatDate = (date, to, from = null) => {
  if (from) {
    return moment(date, from).format(to);
  }
  return moment(date).format(to);
};

export const getUTCDate = (date, format) => {
  return moment(date).utc().format(format);
};

export const getLocalTime = (date, format) => {
  const localdate = moment.utc(date).toDate();
  return moment(localdate).format(format);
};

export const sort = (array, key) => {
  return array.sort((a, b) => a[key].localeCompare(b[key]));
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const isEmpty = (value) => {
  var isEmptyObject = function (a) {
    if (typeof a.length === 'undefined') { // it's an Object, not an Array
      var hasNonempty = Object.keys(a).some(function nonEmpty(element) {
        return !isEmpty(a[element]);
      });
      return hasNonempty ? false : isEmptyObject(Object.keys(a));
    }

    return !a.some(function nonEmpty(element) { // check if array is really not empty as JS thinks
      return !isEmpty(element); // at least one element should be non-empty
    });
  };
  return (
    value == false
    || typeof value === 'undefined'
    || value == null
    || (typeof value === 'object' && isEmptyObject(value))
  );
}
