import React from "react";

function ShiftsSettings(props) {
  const {
    dayStartTime,
    setDayStartTime,
    shiftsPerDay,
    setShiftsPerDay,
    hoursPerShift,
    setHoursPerShift,
    operatorsPerShift,
    setOperatorsPerShift,
  } = props;

  return (
    <div className="box box-success">
      <div className="box-header with-border">
        <h3 className="box-title">Shifts</h3>

        <div className="box-tools pull-right">
          <button type="button" className="btn btn-box-tool" data-widget="collapse">
            <i className="fa fa-minus"></i>
          </button>
        </div>
      </div>
      <div className="box-body">
        <div className="form-horizontal form-group ml-20">
          <label className="">Day Start Time</label>
          <input
            type="time"
            className="form-control"
            defaultValue={dayStartTime}
            onChange={(e) => setDayStartTime(e.target.value)}
            required
          />
        </div>

        <div className="form-horizontal form-group ml-20">
          <label># of Shifts / Day</label>
          <input
            type="number"
            className="form-control"
            defaultValue={shiftsPerDay}
            onChange={(e) => setShiftsPerDay(e.target.value)}
            required
            min={0}
          />
        </div>

        <div className="form-horizontal form-group ml-20">
          <label># of Hours / Shift</label>
          <input
          step="0.01"
            type="number"
            className="form-control"
            defaultValue={hoursPerShift}
            onChange={(e) => setHoursPerShift(e.target.value)}
            required
            min={0}
          />
        </div>
        <div className="form-horizontal form-group ml-20">
          <label># of Operators / Shift</label>
          <input
            type="number"
            className="form-control"
            defaultValue={operatorsPerShift}
            onChange={(e) => setOperatorsPerShift(e.target.value)}
            required
            min={0}
          />
        </div>
      </div>
    </div>
  );
}

export default ShiftsSettings;
