import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearAlert } from "../redux/actions/alertActions";

function Alert() {
  const dispatch = useDispatch();
  const alertMessage = useSelector((state) => state.alerts);
  const type = alertMessage.type;
  const message = alertMessage.message;

  useEffect(() => {
    if (message)
      setTimeout(() => {
        dispatch(clearAlert());
      }, 5000);
  }, [message]);

  const errorMessage = () => {
    return (
      <div className="alert alert-error fade in alert-dismissible mt-20 ml-10 mr-10">
        <h4>
          <i className="fa fa-ban"></i>&nbsp;Error!
        </h4>
        {message}
      </div>
    );
  };

  const successMessage = () => {
    return (
      <div className="alert alert-success fade in alert-dismissible mt-20 ml-10 mr-10">
        <h4>
          <i className="fa fa-check"></i>&nbsp;Success!
        </h4>
        {message}
      </div>
    );
  };

  return (
    <Fragment>
      {message && type === "danger" && errorMessage()}
      {message && type === "success" && successMessage()}
    </Fragment>
  );
}

export default Alert;
