import { chiefConstants, API_URL, usersConstants } from "../constants";
import Axios from "axios";
import { errorAlert } from "./alertActions";
import { updatingSupervisorsModal, showLoadingOverlay } from "./loadingActions";
import moment from "moment";

const isFetching = () => ({
  type: chiefConstants.FETCH_CHIEF_OPERATOR,
});

const fetchChiefOperatorsSuccess = (users) => ({
  type: chiefConstants.FETCH_CHIEF_OPERATORS_SUCCESS,
  payload: users,
});

const fetchChiefsShiftsFailure = () => ({
  type: chiefConstants.FETCH_CHIEF_SHITFS_FAILURE,
});

const fetchChiefsShiftsSuccess = (shifts) => ({
  type: chiefConstants.FETCH_CHIEF_SHIFTS_SUCCESS,
  payload: shifts,
});

const addShiftSuccess = (shift) => ({
  type: chiefConstants.ADD_CHIEF_SHIFT_SUCCESS,
  payload: shift,
});

const updateChiefShiftSuccess = (id, data) => ({
  type: chiefConstants.UPDATE_CHIEF_SHIFT_SUCCESS,
  payload: { id: id, data: data },
});

const deleteShiftSuccess = (id) => ({
  type: chiefConstants.DELETE_CHIEF_SHIFT,
  payload: id,
});

const chiefShiftsError = (message) => ({
  type: chiefConstants.SHOW_ERROR_MESSAGE,
  payload: message,
});

const clearErrorMessage = () => ({
  type: chiefConstants.CLEAR_ERROR_MESSAGE,
});
export const removeUserFromChiefs = (userid, date) => {
  return function (dispatch) {
    dispatch({
      type: usersConstants.REMOVE_DELETED_USER,
      payload: { id: userid, date: date },
    });
  };
};

export const clearMessage = () => {
  return function (dispatch) {
    dispatch(clearErrorMessage());
  };
};

export const fetchChiefOperators = () => {
  return function (dispatch) {
    dispatch(showLoadingOverlay(true));
    return Axios.get(API_URL + "/operator_chief/get_user_list?start=-1&rows=-1")
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(fetchChiefOperatorsSuccess(response.data.users));
        } else if (
          response.data.status === "0" &&
          response.data.msg === "No Users"
        ) {
          dispatch(fetchChiefOperatorsSuccess([]));
        } else {
          dispatch(errorAlert(response.data.msg));
        }
        dispatch(showLoadingOverlay(false));
      })
      .catch((error) => {
        dispatch(errorAlert("Something went wrong.."));
        dispatch(showLoadingOverlay(false));
      });
  };
};

export const getChiefsShifts = (month, year) => {
  return function (dispatch) {
    dispatch(isFetching());
    dispatch(showLoadingOverlay(true));
    const date = "year=" + year + "&month=" + month;

    return Axios.get(
      API_URL + "/operator_chief/get_shifts_for_year_month?" + date
    )
      .then((response) => {
        if (response.data.status === "1") {
          if (response.data.shifts)
            dispatch(
              fetchChiefsShiftsSuccess([
                ...response.data.shifts,
                ...response.data.previous,
                ...response.data.next,
              ])
            );
          else dispatch(fetchChiefsShiftsSuccess([])); //no shifts
        } else if (
          response.data.status === "0" &&
          response.data.msg === "No Shifts"
        ) {
          dispatch(fetchChiefsShiftsSuccess([]));
        } else {
          dispatch(errorAlert(response.data.msg));
        }
        dispatch(showLoadingOverlay(false));
      })
      .catch((error) => {
        dispatch(fetchChiefsShiftsFailure());
        dispatch(errorAlert("Something went wrong.."));
        dispatch(showLoadingOverlay(false));
      });
  };
};

export const addChiefShift = (data) => {
  return function (dispatch) {
    dispatch(clearErrorMessage());
    dispatch(updatingSupervisorsModal(true));
    return Axios.post(API_URL + "/operator_chief/add_shift", data)
      .then((response) => {
        if (response.data.status === "1")
          dispatch(addShiftSuccess(response.data.shift));
        else dispatch(chiefShiftsError(response.data.msg));
        dispatch(updatingSupervisorsModal(false));
      })
      .catch((error) => {
        dispatch(updatingSupervisorsModal(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const updateChiefShift = (data) => {
  return function (dispatch) {
    dispatch(clearErrorMessage());
    dispatch(updatingSupervisorsModal(true));
    return Axios.post(API_URL + "/operator_chief/edit_shift", data)
      .then((response) => {
        if (response.data.status === "1")
          dispatch(
            updateChiefShiftSuccess(data.get("id"), response.data.shift)
          );
        else dispatch(chiefShiftsError(response.data.msg));
        dispatch(updatingSupervisorsModal(false));
      })
      .catch((error) => {
        dispatch(updatingSupervisorsModal(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const deleteChiefShift = (shiftid) => {
  return function (dispatch) {
    dispatch(clearErrorMessage());
    dispatch(updatingSupervisorsModal(true));
    return Axios.post(API_URL + "/operator_chief/delete_shift", shiftid)
      .then((response) => {
        if (response.data.status === "1")
          dispatch(deleteShiftSuccess(shiftid.get("id")));
        else dispatch(chiefShiftsError(response.data.msg));
        dispatch(updatingSupervisorsModal(false));
      })
      .catch((error) => {
        dispatch(updatingSupervisorsModal(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const deleteChiefsShiftsAfterCurrentDate = () => {
  return function (dispatch) {
    const currentDate = moment();
    const formatDate = currentDate.format("YYYY-MM-DD");
    dispatch({
      type: chiefConstants.DELETE_CHIEF_SHIFTS_BY_DATE,
      payload: formatDate,
    });
  };
};
