import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sort } from "../../helpers";
import { getAwarenessOfficersAndTrainees } from "../../redux/actions/sessionsActions";

function UsersDropdown(props) {
  const { setSelectedUser, userType } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const users = useSelector((state) => state.sessions.sessionUsers);

  useEffect(() => {
    dispatch(getAwarenessOfficersAndTrainees());
  }, []);

  useEffect(() => {
    if (users) {
      if (userType === 2) {
        // awareness trainees
        let array = [];
        users.map((user) => {
          return user.roles.forEach((role) => {
            let name = String(role.name).toLowerCase();
            if (name.includes("trainee") && name.includes("awareness")) {
              array.push(user);
              return user;
            }
          });
        });
        setData(array);
      } else {
        //awareness officers
        let array = [];
        users.map((user) => {
          return user.roles.forEach((role) => {
            let name = String(role.name).toLowerCase();
            if (name.includes("officer") && name.includes("awareness"))
              array.push(user);
          });
        });

        setData(array);
      }
    }
  }, [users, userType]);

  const getMessage = () => {
    return data && data.length === 0 ? "No Users" : "Loading...";
  };

  //populate dropdown
  const renderUsers = () => {
    let sorted = sort(data, "name");
    return sorted.map((user, index) => {
      return (
        <option value={user.id} key={"user-dropdown-" + index}>
          {user.name}
        </option>
      );
    });
  };

  return (
    <select
      style={props.style ? props.style : null}
      required
      className="form-control"
      disabled={data && data.length > 0 ? false : true}
      onChange={(e) => setSelectedUser(e.target.value)}
    >
      {data && data.length > 0 ? (
        <>
          <option value={null}>Select a User</option> {renderUsers()}
        </>
      ) : (
        <option value={null}>{getMessage()}</option>
      )}
    </select>
  );
}

export default UsersDropdown;
