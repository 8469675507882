import React, { useState } from "react";
import UsersSelect from "../UsersSelect";

function EditAttendee(props) {
  const [selectedUser, setSelectedUser] = useState(null);
  const { attendee, setEditMode } = props;
  const [errorMsg, hasError] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!selectedUser) hasError(true);
    else {
      let data = new FormData();
      data.append("user_id", selectedUser);
      data.append("id", attendee.id);
      data.append("meeting_id", attendee.meeting_id);

      data.append("date", attendee.date);

      props.onSubmit(data);
      hasError(false)
      setEditMode(false);
    }
  };

  const onCancel = (e) => {
    e.preventDefault();
    hasError(false)
    setEditMode(false);
  };
  return (
    <form onSubmit={onSubmit} className="flexGrow">
      <div className="input-group">
        <UsersSelect setSelectedUser={setSelectedUser} userType="meeting" />
        <span className="input-group-btn">
          <button
            className="green bg-white btn users-select-submit-btn"
            type="submit"
            title="Save"
          >
            <i className="fa fa-check"></i>
          </button>
          <button
            className="red bg-white btn users-select-cancel-btn "
            onClick={(e) => onCancel(e)}
            type="button"
            title="Cancel"
          >
            <i className="fa fa-close"></i>
          </button>
        </span>
      </div>
      {errorMsg && (
        <small className="text-danger pl-10 ">Please select a user</small>
      )}
    </form>
  );
}

export default EditAttendee;
