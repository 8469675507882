import React from "react";
import "../css/stylesheet.css";

function LoadingOverlay(props) {
  return (
    <div id="overlay">
      <div id="loading-icon">
        <div className="overlay">
          <i className="fa fa-refresh fa-spin"></i>
        </div>
      </div>
    </div>
  );
}

export default LoadingOverlay;
