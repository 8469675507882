import React, { useState, Fragment } from "react";
import GroupMeetingEditForm from "./GroupMeetingEditForm";
import ActionButtons from "./ActionButtons";
import MeetingAttendee from "./MeetingAttendee";
import PlusIconForm from "../Shifts Components/PlusIconForm";
import { getLocalTime } from "../../helpers";

function GroupMeeting(props) {
  const [editMode, toggleEditMode] = useState(false);

  const {
    item,
    onDeleteAttendee,
    onEditAttendee,
    onAddAttendee,
    onDeleteMeeting,
    markComplete,
    updateGroupMeeting,
  } = props;

  const handleAddAttendee = (user, date) => {
    const data = new FormData();
    data.append("meeting_id", item.id);

    data.append("date", date);

    data.append("user_id", user);
    onAddAttendee(data);
  };

  //meeting attendee
  const renderAttendees = () => {
    return item.attendees.map((attendee, index) => {
      return (
        <li key={"attendee-" + index}>
          <MeetingAttendee
            attendee={attendee}
            meetingId={attendee.meeting_id}
            user={item.user}
            onDeleteAttendee={onDeleteAttendee}
            onEditAttendee={onEditAttendee}
          />
        </li>
      );
    });
  };

  return (
    <Fragment>
      {editMode && (
        <GroupMeetingEditForm
          data={item}
          onSubmit={updateGroupMeeting}
          setEditMode={toggleEditMode}
        />
      )}
      <div className="row">
        {/* column 1 - Meeting Information */}
        <div className="col-sm-6 pl-15">
          {!editMode && (
            <Fragment>
              <div className="pl-15">
                <p className="title">
                  Date:
                  <span className="span-data">
                    {getLocalTime(item.from_date, "dddd MMM DD, YYYY")}
                  </span>
                </p>
                <p className="title">
                  Time:
                  <span className="span-data">
                    {getLocalTime(item.from_date, "hh:mm A")} -{" "}
                    {getLocalTime(item.to_date, "hh:mm A")}
                  </span>
                </p>
                <p className="title">
                  Location: <span className="span-data">{item.location}</span>
                </p>
              </div>

              <p className="title">
                Moderator:
                <span className="span-data"> {item.user.name}</span>
              </p>
              <p className="title">
                Type: <span className="span-data">Group</span>
              </p>
            </Fragment>
          )}
        </div>

        {/* column 2 - Action Buttons*/}
        <div className="col-sm-6 text-right">
          {item.status === "0" ? (
            !editMode && (
              <ActionButtons
                editMode={editMode}
                toggleEditMode={toggleEditMode}
                onDeleteMeeting={onDeleteMeeting}
                meetingId={item.id}
                markComplete={markComplete}
              />
            )
          ) : (
            <p className="btn-green page-link green-border">Complete</p>
          )}
        </div>
      </div>

      {!editMode && (
        <Fragment>
          <p className="title">Attendees: </p>
          <ul>
            {item.attendees && renderAttendees()}

            <li className="shift-container flex title mt-10">
              Add Attendee
              <PlusIconForm
                onSubmit={handleAddAttendee}
                data={item.from_date}
                userType="meeting"
              />
            </li>
          </ul>
        </Fragment>
      )}
    </Fragment>
  );
}

export default GroupMeeting;
