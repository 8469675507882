import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import moment from "moment";

function CalendarComponent(props) {
  const { shifts, maxPerDay, onMonthChange, type } = props;
  const didMountRef = useRef(false);
  const [events, setEvents] = useState([]);

  //display supevisors/chief operators on calendar
  useEffect(() => {
    const loadItems = () => {
      let users = [];
      for (let index = 0; index < shifts.length; index++) {
        if (type) {
          let obj = {
            title: shifts[index].user.name,
            start: shifts[index].day,
            backgroundColor: "transparent",
          };

          if (!users.includes(obj)) users.push(obj);
        }
      }

      setEvents(users);
    };

    loadItems();
  }, [shifts,type]);

  useEffect(() => {
    if (!didMountRef.current) didMountRef.current = true;
  });

  const onDateClick = (info) => {
    return props.onDateClick ? props.onDateClick(info.dateStr) : null;
  };

  const getColorCode = (date) => {
    let momentDate = moment(date);
    let day = momentDate.format("YYYY-MM-DD");
    let count = 0;
    for (let index = 0; index < shifts.length; index++) {
      const shiftDate = type
        ? shifts[index].day
        : shifts[index].from_date.split(" ")[0];

      if (shiftDate === day) count++;
    }

    //settings is the number of shifts per day in the settings
    if (count >= maxPerDay) {
      return "green";
    } else if (count > 0 && count < maxPerDay)
      return !type ? "#f39c12" : "transparent";
  };

  //when new dates are displayed
  const daysRender = (info) => {
    if (didMountRef.current) {
      let date = moment(info.view.currentStart);
      let month = date.format("MM");
      let year = date.format("YYYY");
      onMonthChange(month, year);
    }
  };

  return (
    <div className="wrap">
      <div className="row">
        {!type && (
          <div className="col-md-3 col-lg-push-9">{props.children}</div>
        )}
        <div className={!type ? "col-md-9 col-lg-pull-3" : ""}>
          <div className="box box-success">
            <div className="box-body no-padding">
              <FullCalendar
                header={{ left: "prev", center: "title", right: "next" }}
                defaultView="dayGridMonth"
                plugins={[dayGridPlugin, interactionPlugin]}
                dateClick={onDateClick} // handles on day click
                weekends={true} //weekends are hidden when set to false
                events={events}
                showNonCurrentDates={false} //hide prev and next months dates
                fixedWeekCount={false}
                datesRender={daysRender} //triggered when prev and next buttons are clicked
                dayRender={(dayRenderInfo) => {
                  dayRenderInfo.el.style.backgroundColor = getColorCode(
                    dayRenderInfo.date
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalendarComponent;
