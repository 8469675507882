import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../../components/Alert";
import { ExportCSV } from "../../components/ExportCSV";
import { fetchTraineesData } from "../../redux/actions/dashboardActions";
import DataTable from "react-data-table-component";
import { useState } from "react";

const headers = [
  { label: "Name", key: "name" },
  { label: "Hours Required", key: "hours_required" },
  { label: "Hours Completed", key: "hours_completed" },
];

const columns = [
  {
    name: "Name",
    selector: "name",
    sortable: true,
  },
  {
    name: "Hours Required",
    selector: "hours_required",
    sortable: true,
  },
  {
    name: "Hours Completed",
    selector: "hours_completed",
    sortable: true,
  },
];

function TraineeOperators() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dashboard.trainees);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const fetchData = () => {
    dispatch(fetchTraineesData(fromDate, toDate));
  };

  return (
    <div className="content-wrapper">
      <div className="wrap">
        {/* add user button triggers the add user modal*/}
        <section className="content-header pl-0 page-title flex">
          <h1 className="flexGrow">Dashboard - Trainee Operators</h1>
          <ExportCSV fileName="Trainees" csvData={data} headers={headers} />
        </section>
        <Alert />

        <div style={{ display: "flex", marginBottom: "20px" }}>
          <input
            type="date"
            className="form-control"
            style={{ marginRight: "20px" }}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <input
            type="date"
            className="form-control"
            style={{ marginRight: "20px" }}
            onChange={(e) => setToDate(e.target.value)}
            min={fromDate}
          />
          <button
            disabled={!fromDate || !toDate}
            className="btn btn-primary"
            onClick={fetchData}
          >
            Submit
          </button>
        </div>

        <DataTable title="Trainee Operators" columns={columns} data={data} />
      </div>
    </div>
  );
}

export default TraineeOperators;
