import React, { useEffect } from "react";
import CreateSessionForm from "../components/AwarenessComponents/CreateSessionForm";
import SessionCard from "../components/AwarenessComponents/SessionCard";
import { useDispatch, useSelector } from "react-redux";
import {
  createSession,
  completeSession,
  updateSession,
  fetchSessions,
  deleteSessionAttendee,
  addSessionAttendee,
  updateSessionAttendee,
} from "../redux/actions/sessionsActions";
import Jumbotron from "../components/Jumbotron";
import SessionInformation from "../components/AwarenessComponents/SessionInformation";
import Alert from "../components/Alert";

function Awareness(props) {
  const dispatch = useDispatch();
  const sessions = useSelector((state) => state.sessions.sessions);

  //handles create session form submit
  const onSubmit = (data) => {
    dispatch(createSession(data));
  };

  const markSessionComplete = (sessionId) => {
    let session = new FormData();
    session.append("id", sessionId);
    dispatch(completeSession(session));
  };

  const updateSessionInfo = (session) => {
    dispatch(updateSession(session));
  };

  const addAttendee = (data) => {
    dispatch(addSessionAttendee(data));
  };

  const updateAttendee = (data) => {
    dispatch(updateSessionAttendee(data));
  };

  const deleteAttendee = (id, sessionId) => {
    let formdata = new FormData();
    formdata.append("id", id);
    dispatch(deleteSessionAttendee(formdata, sessionId));
  };

  useEffect(() => {
    const initSessions = () => {
      dispatch(fetchSessions());
    };

    initSessions();
  }, []);

  return (
    <div className="content-wrapper">
      <div className="wrap">
        <section className="content-header page-title">
          <h1>
            Awareness & Outreach
            {/* create a new session button, displays the CreateSessionForm Modal */}
            <small
              data-toggle="modal"
              data-target="#createSessionModal"
              className="page-link"
            >
              <i className="fa fa-plus"></i> <b>Create Session</b>
            </small>
          </h1>
        </section>
        <Alert />
        {/* Create session modal */}
        {<CreateSessionForm onSubmit={onSubmit} />}

        {/* list of sessions */}
        {sessions && sessions.length > 0 ? (
          <div className="flex-container">
            {sessions.map((item, index) => {
              return (
                <SessionCard
                  item={item}
                  key={index + "-awareness-card"}
                  onComplete={markSessionComplete}
                  updateSession={updateSessionInfo}
                >
                  <SessionInformation
                    item={item}
                    onDelete={deleteAttendee}
                    onAddAttendee={addAttendee}
                    onUpdateAttendee={updateAttendee}
                  />
                </SessionCard>
              );
            })}
          </div>
        ) : (
          <Jumbotron message="No Sessions" />
        )}
      </div>
    </div>
  );
}

export default Awareness;
