import React, { useState } from "react";
import { useSelector } from "react-redux";
import BoxLoading from "../BoxLoading";

function ChangeEmailForm(props) {
  const { useremail, userid, changeEmail } = props;

  const [email, setEmail] = useState(useremail);
  const [editMode, setEditMode] = useState(false);

  const loading = useSelector(state => state.loading.isUpdatingEmail)

  const onSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("old_email", useremail);
    formdata.append("new_email", email);
    formdata.append("id", userid);
    changeEmail(formdata);
    setEditMode(false);
  };

  return (
    <div className="box box-success">
      <div className="box-header with-border">
        <h3 className="box-title">Change Email</h3>

        <div className="box-tools pull-right">
          <button
            type="button"
            className="btn btn-box-tool"
            data-widget="collapse"
          >
            <i className="fa fa-minus"></i>
          </button>
        </div>
      </div>
      <div className="box-body">
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label>Email</label>
            <div className=" flex">
              <input
                className="form-control"
                value={email}
                disabled={!editMode}
                onChange={(e) => setEmail(e.target.value)}
              />

              {editMode ? (
                <>
                  <button
                    className="btn icon-button plus-icon"
                    title="Save"
                    type="submit"
                    onClick={() => setEditMode(true)}
                  >
                    <i className="fa fa-check"></i>
                  </button>
                  <button
                    className="btn icon-button delete-icon"
                    title="Save"
                    onClick={() => setEditMode(false)}
                  >
                    <i className="fa fa-close"></i>
                  </button>
                </>
              ) : (
                <button
                  className="btn icon-button edit-icon"
                  title="Change Email"
                  onClick={() => setEditMode(true)}
                >
                  <i className="fa fa-pencil"></i>
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      {loading && <BoxLoading />}
    </div>
  );
}

export default ChangeEmailForm;
