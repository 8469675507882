import React, { useState, useEffect } from "react";
import TableOfUsers from "../components/UserComponents/UsersTable";
import AddUserForm from "../components/UserComponents/AddUserModal";
import "../css/stylesheet.css";
import DeleteConfirmation from "../components/DeleteConfirmation";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchUsers,
  addUser,
  deleteUser,
  updateUser,
  updateUserPermissions,
  resetUserPassword,
  removeProfilePicture
} from "../redux/actions/usersActions";
import { fetchPermissions } from "../redux/actions/permissionsActions";
import Alert from "../components/Alert";

function Users(props) {
  const dispatch = useDispatch();
  // selectedUser = {user: user, index:index}
  const [selectedUser, setSelectedUser] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const isUpdating = useSelector((state) => state.loading.isUpdatingUser);
  const isLoading = useSelector((state) => state.users.isFetching);

  //get users and roles form the state
  const users = useSelector((state) => state.users.users);
  const permissions = useSelector((state) => state.permissions.permissions);
  const [filteredUsers, setfilteredUsers] = useState(users);

  //modal error
  const error = useSelector((state) => state.users.errorMessage);

  useEffect(() => {
    const init = () => {
      dispatch(fetchUsers());
      if (permissions.length === 0) dispatch(fetchPermissions());
    };

    init();
  }, []);

  useEffect(() => {
    setfilteredUsers(users);
  }, [users]);

  //update user permissions
  const editPermissions = (formdata) => {
    dispatch(updateUserPermissions(formdata));
  };

  //Handles submit button in the edit user form
  const handleEdit = (data) => {
    dispatch(updateUser(data));
  };

  //Handles submit button in the add user form
  const handleSubmit = (data) => {
    dispatch(addUser(data));
    setSelectedUser(null);
  };

  const removePicture = (id) => {
    let data = new FormData()
    data.append('id',id)
    dispatch(removeProfilePicture(data));
  }
  //handles the delete button in the DeleteConfirmation Modal
  const onDelete = () => {
    var userId = new FormData();
    userId.append("id", userToDelete.user.id);
    dispatch(deleteUser(userId));
    setUserToDelete(null); //reset user
  };

  //filter users
  const searchUsers = (value) => {
    if (value === "") {
      setfilteredUsers(users);
    } else {
      let filtered = [];
      users.map((user) => {
        if (user.name.toLowerCase().indexOf(value.toLowerCase()) >= 0)
          filtered.push(user);
      });
      setfilteredUsers(filtered);
    }
  };

  const resetPassword = id => {
    let formdata = new FormData()
    formdata.append('id', id)
    dispatch(resetUserPassword(formdata))
  }

  return (
    <div className="content-wrapper">
      <div className="wrap">
        {/* add user button triggers the add user modal*/}
        <section className="content-header pl-0 page-title flex">
          <h1 className="flexGrow">Users</h1>
          <button
            className="btn btn-green"
            data-toggle="modal"
            data-target="#FormModal"
            id="modalButton"
            onClick={() => {
              setSelectedUser(null);
            }}
          >
            <i className="fa fa-user-plus"></i> Add User
          </button>
        </section>
        <Alert />
        {/* Form Modal */}
        {/* if the editUserInfo is not null then the admin is editing a user */}
        <AddUserForm
          userInfo={selectedUser ? selectedUser.user : null}
          onSubmit={selectedUser ? handleEdit : handleSubmit}
          title={selectedUser ? "Edit User" : "Add User"}
          permissions={permissions}
          loading={isUpdating}
          error={error}
          resetPassword={resetPassword}
          removePicture={removePicture}
        />

        <button
          className="btn hidden"
          data-toggle="modal"
          data-target="#FormModal"
          id="editModalButton"
        />

        {/* List of users */}
        {filteredUsers && (
          <TableOfUsers
            selectUser={setSelectedUser}
            onDelete={onDelete}
            data={filteredUsers}
            permissions={permissions}
            editPermissions={editPermissions}
            setUserToDelete={setUserToDelete}
            isLoading={isLoading}
          >
            {/* search input */}
            <div className="has-feedback">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                onChange={(e) => searchUsers(e.target.value)}
              />
              <span className="glyphicon glyphicon-search form-control-feedback"></span>
            </div>
          </TableOfUsers>
        )}

        {/* delete confirmation dialog */}
        <DeleteConfirmation onDelete={onDelete} />
      </div>
    </div>
  );
}

export default Users;
