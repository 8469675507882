import React, { useEffect } from "react";
import Profile from "../components/Profile/Profile";
import { useSelector, useDispatch } from "react-redux";
import {
  updateUserEmail,
  logoutUser,
  updateUserPassword,
  updateUserProfile,
} from "../redux/actions/authenticatedUserActions";
import ChangeEmailForm from "../components/Profile/ChangeEmailForm";
import ChangePasswordForm from "../components/Profile/ChangePassword";
import Alert from "../components/Alert";
import { getUserById } from "../redux/actions/usersActions";

function ProfilePage() {
  const user = useSelector((state) => state.authenticatedUser.loggedInUser);
  const dispatch = useDispatch();

  const changeEmail = (data) => {
    dispatch(updateUserEmail(data));
  };

  const logout = () => {
    dispatch(logoutUser());
  };

  const updatePassword = (formdata) => {
    formdata.append("id", user.id);
    dispatch(updateUserPassword(formdata));
  };

  const updateProfile = (data) => {
    dispatch(updateUserProfile(data));
  };

  useEffect(() => {
    dispatch(getUserById(user.id));
  });

  return (
    <div className="content-wrapper">
      <div className="wrap">
        <section className="content-header pl-0 page-title flex">
          <h1 className="flexGrow">Profile</h1>
          <button
            className="btn btn-dark-gray page-link-gray"
            onClick={() => logout()}
          >
            <i className="fa fa-power-off"></i> Logout
          </button>
        </section>

        <Alert />

        <div className="row">
          <div className="col-md-6">
            {user && <Profile user={user} updateProfile={updateProfile} />}
          </div>
          <div className="col-md-6">
            {user && (
              <ChangeEmailForm
                useremail={user.email}
                userid={user.id}
                changeEmail={changeEmail}
              />
            )}
            {user && <ChangePasswordForm updatePassword={updatePassword} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
