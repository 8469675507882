import React from "react";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../redux/store";
import Routes from "../routes";
import Layout from "../components/Layout";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <HashRouter>
          <Layout />
          <Routes />
        </HashRouter>
      </Provider>
    </div>
  );
}

export default App;
