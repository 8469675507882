import { usersConstants } from "../constants";

const initialState = {
  isFetching: false,
  didInvalidate: false,
  users: null,
  errorMessage: null,
};
const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case usersConstants.FETCH_USERS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case usersConstants.FETCH_USERS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        users: action.payload,
      });
    case usersConstants.FETCH_USERS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
      });
    case usersConstants.ADD_USER:
      return Object.assign({}, state, {
        users: [...state.users, action.payload],
      });
    case usersConstants.DELETE_USER:
      return Object.assign({}, state, {
        isFetching: false,
        users: state.users
          ? state.users.filter((item, index) => item.id !== action.payload)
          : null,
      });
    case usersConstants.UPDATE_USER:
      return Object.assign({}, state, {
        users: state.users ? state.users.map((item, index) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        }) : null,
      });
    case usersConstants.UPDATE_PERMISSIONS_SUCCESS:
      return Object.assign({}, state, {
        users: state.users ? state.users.map((item, index) => {
          if (item.id === action.payload.userId) {
            let roles = action.payload.roles;
            return { ...item, roles };
          }
          return item;
        }):null,
      });
    case usersConstants.SHOW_ERROR_MESSAGE:
      return Object.assign({}, state, {
        errorMessage: action.payload,
      });
    case usersConstants.CLEAR_ERROR_MESSAGE:
      return Object.assign({}, state, {
        errorMessage: null,
      });
    default:
      return state;
  }
};

export default usersReducer;
