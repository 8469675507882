import React from "react";
import "../../css/stylesheet.css";
import { Link } from "react-router-dom";

function Header(props) {
  const { name } = props;

  const pushMenu = () => {
    var body = document.body;
    if (body.clientWidth > 768) {
      if (body.classList.contains("sidebar-collapse")) {
        body.className += " sidebar-collapse";
      } else {
        body.className = body.className.replace("sidebar-collapse", "");
      }
    } else {
      if (!body.classList.contains("sidebar-open")) {
      
        body.className = body.className.replace(" sidebar-open", "");
      } else {
        body.className += " sidebar-open";
      }
    }
  };
  return (
    <header className="main-header">
      {/* Logo */}
      <a href="/" className="logo fixed">
        {/* mini logo for sidebar mini 50x50 pixels */}
        <span className="logo-mini logo-image-small">
          <img src="/images/small-logo.png" alt="Embrace Logo" />
        </span>
        {/* logo for regular state and mobile devices */}
        <span className="logo-lg logo-image">
          <img src="/images/embrace-logo.png" alt="Embrace Logo" />
        </span>
      </a>
      {/* Header Navbar: style can be found in header.less */}
      <nav className="navbar navbar-static-top" role="navigation">
        {/* Sidebar toggle button*/}
        <button
          className="sidebar-toggle anchorButton"
          data-toggle="offcanvas"
          onClick={() => pushMenu()}
        >
          <span className="sr-only">Toggle navigation</span>
        </button>
        <div className="navbar-custom-menu">
          <ul className="nav navbar-nav">
            <li className="dropdown user user-menu">
              <Link to="/profile">
                <img
                  src="/images/user-icon.png"
                  className="user-image"
                  alt="User"
                />
                <span className="hidden-xs">{name}</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
