import { traineeConstants, usersConstants } from "../constants";

const initialState = {
  isFetching: false,
  didInvalidate: false,
  trainees: null,
  shifts: null,
  errorMessage: null,
};

const traineesReducer = (state = initialState, action) => {
  switch (action.type) {
    case traineeConstants.FETCH_TRAINEES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });

    case traineeConstants.FETCH_TRAINEES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        trainees: action.payload,
      });

    case traineeConstants.FETCH_TRAINEES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
      });
    case traineeConstants.UPDATE_TRAINEE_APPROVAL:
      return Object.assign({}, state, {
        trainees: state.trainees.filter(
          (item, index) => item.user.id !== action.payload.id
        ),
        shifts: state.shifts
          ? state.shifts.filter(
              (shift) =>
                !(
                  shift.trainee_user.id === action.payload.id &&
                  shift.from_date.split(" ")[0] > action.payload.date
                )
            )
          : null,
      });
    case traineeConstants.FETCH_TRAINEE_SHIFTS_SUCCESS:
      return Object.assign({}, state, {
        shifts: action.payload,
      });
    case traineeConstants.DELETE_TRAINEE_SHIFT_SUCCESS:
      return Object.assign({}, state, {
        shifts: state.shifts.filter(
          (item) => Number(item.shift_trainees_id) !== Number(action.payload)
        ),
      });
    case traineeConstants.ADD_TRAINEE_SHIFT_SUCCESS:
      return Object.assign({}, state, {
        shifts: [...state.shifts, action.payload],
      });
    case traineeConstants.UPDATE_TRAINEE_SHIFT_SUCCESS:
      return Object.assign({}, state, {
        shifts: state.shifts.map((shift) => {
          if (Number(shift.shift_trainees_id) === Number(action.payload.id))
            return action.payload.shift;
          else return shift;
        }),
      });
    case usersConstants.REMOVE_DELETED_USER:
      return Object.assign({}, state, {
        trainees: state.trainees
          ? state.trainees.filter(
              (trainee) => trainee.user.id !== action.payload
            )
          : null,
        shifts: state.shifts
          ? state.shifts.filter(
              (shift) =>
                !(
                  shift.from_date.split(" ")[0] > action.payload.date &&
                  shift.trainee_user.id === action.payload.id
                )
            )
          : null,
      });
    case traineeConstants.SHOW_ERROR_MESSAGE:
      return Object.assign({}, state, {
        errorMessage: action.payload,
      });
    case traineeConstants.CLEAR_ERROR_MESSAGE:
      return Object.assign({}, state, {
        errorMessage: null,
      });
    default:
      return state;
  }
};

export default traineesReducer;
