import { permissionsConstants } from "../constants";

const initialState = {
  isFetching: false,
  didInvalidate: false,
  permissions: [],
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case permissionsConstants.FETCH_PERMISSIONS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    //   sessions are recieved successfully
    case permissionsConstants.FETCH_PERMISSIONS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        permissions: action.payload,
      });
    //   something went wrong while fetching
    case permissionsConstants.FETCH_PERMISSIONS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
      });
    default:
      return state;
  }
};

export default permissionsReducer
