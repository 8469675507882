import React, { useState, Fragment, useEffect } from "react";
import EditAttendee from "./EditAttendee";

function MeetingAttendee(props) {
  const [editUser, setEditUserMode] = useState(false);

  const { attendee, meetingId, onDeleteAttendee, onEditAttendee } = props;

  useEffect(() => {
    setEditUserMode(false);
  }, [props]);

  return (
    <div className="flex">
      {editUser ? (
        <EditAttendee
          attendee={attendee}
          onSubmit={onEditAttendee}
          setEditMode={setEditUserMode}
        />
      ) : (
        <Fragment>
          <div className="breakWord">{attendee.user.name}</div>
          <div className="flexGrow text-right">
            <button
              className="btn icon-button edit-icon mr-1"
              onClick={() => setEditUserMode(true)}
            >
             Edit
            </button>
            <button
              className="btn icon-button delete-icon"
              onClick={() => onDeleteAttendee(attendee.id, meetingId)}
            >
               Remove
            </button>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default MeetingAttendee;
