import { authUserConstants } from "../constants";
let user = null;
if (localStorage.getItem("embrace-admin-user"))
  user = localStorage.getItem("embrace-admin-user");
if (sessionStorage.getItem("embrace-admin-user"))
  user = sessionStorage.getItem("embrace-admin-user");

const userInfo =
  user === null || user === "undefined" ? null : JSON.parse(user);

const initialState = {
  validating: false,
  didInvalidate: false,
  isAuthUser:
    localStorage.getItem("embrace-admin-token") ||
    sessionStorage.getItem("embrace-admin-token")
      ? true
      : false,
  loggedInUser: userInfo,
  notifications: [],
};

const authenticatedUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case authUserConstants.LOGIN_REQUEST:
      return Object.assign({}, state, {
        validating: true,
        didInvalidate: false,
      });
    case authUserConstants.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        validating: false,
        isAuthUser: true,
        loggedInUser: action.payload.user,
      });
    case authUserConstants.LOGIN_FAILURE:
      return Object.assign({}, state, {
        validating: false,
        didInvalidate: true,
      });
    case authUserConstants.UPDATE_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        loggedInUser: action.payload,
      });
    case authUserConstants.LOGOUT:
      return Object.assign({}, state, {
        isAuthUser: false,
        loggedInUser: null,
        notifications: [],
      });
    case authUserConstants.FETCH_NOTIFICATIONS_SUCCESS:
      return Object.assign({}, state, {
        notifications: action.payload,
      });
    default:
      return state;
  }
};

export default authenticatedUserReducer;
