import React, { Fragment } from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUserById } from "../redux/actions/usersActions";

function PublicRoute(props) {
  const isAuthUser = useSelector((state) => state.authenticatedUser.isAuthUser);
  const user = useSelector((state) => state.authenticatedUser.loggedInUser);
  const dispatch = useDispatch();

  const refreshUser = () => {
    dispatch(getUserById(user.id))
  }

  return isAuthUser ? (
    <Fragment>
      {refreshUser()} <Redirect to="/dashboard" />{" "}
    </Fragment>
  ) : (
    <Route {...props} />
  );
}

export default PublicRoute;
