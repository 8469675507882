import { chiefConstants, usersConstants } from "../constants";

const initialState = {
  isFetching: false,
  didInvalidate: false,
  shifts: null,
  errorMessage: null,
  chiefOperators: null,
};

const chiefsReducer = (state = initialState, action) => {
  switch (action.type) {
    case chiefConstants.FETCH_CHIEF_OPERATOR:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case chiefConstants.FETCH_CHIEF_OPERATORS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        chiefOperators: action.payload,
      });
    case chiefConstants.FETCH_CHIEF_SHITFS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
      });
    case chiefConstants.FETCH_CHIEF_SHIFTS_SUCCESS:
      return Object.assign({}, state, {
        shifts: action.payload,
      });
    case chiefConstants.ADD_CHIEF_SHIFT_SUCCESS:
      return Object.assign({}, state, {
        shifts: [...state.shifts, action.payload],
      });
    case chiefConstants.DELETE_CHIEF_SHIFT:
      return Object.assign({}, state, {
        shifts: state.shifts.filter(
          (item, index) => item.id !== action.payload
        ),
      });
    case chiefConstants.UPDATE_CHIEF_SHIFT_SUCCESS:
      return Object.assign({}, state, {
        shifts: state.shifts.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload.data;
          } else {
            return item;
          }
        }),
      });
    case chiefConstants.SHOW_ERROR_MESSAGE:
      return Object.assign({}, state, {
        errorMessage: action.payload,
      });
    case chiefConstants.CLEAR_ERROR_MESSAGE:
      return Object.assign({}, state, {
        errorMessage: null,
      });
    case chiefConstants.DELETE_CHIEF_SHIFTS_BY_DATE:
      return Object.assign({}, state, {
        shifts: state.shifts.filter((item) => item.day < action.payload),
      });
    case usersConstants.REMOVE_DELETED_USER:

      return Object.assign({}, state, {
        operators: state.chiefOperators
          ? state.chiefOperators.filter((user) => user.id !== action.payload)
          : null,
          shifts: state.shifts
          ? state.shifts.filter(
              (shift) =>
                !(
                  shift.day > action.payload.date &&
                  shift.user.id === action.payload.id
                )
            )
          : null,
      });
    default:
      return state;
  }
};

export default chiefsReducer;
