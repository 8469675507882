import React from "react";

const getGender = (gender) => {
  switch (gender) {
    case "Male":
      return "Male";
    case "Female":
      return "Female";
    case "Inter":
      return "Intersex";
    default:
      return "Prefer not to say";
  }
};

function UserRow(props) {
  const { item, index, editUser, deleteUser, setSelectedUser } = props;

  return (
    <tr key={"permission-list-" + index}>
      <td>
        <b>{index + 1}.</b>
      </td>
      <td>{item.name}</td>
      <td>{item.email}</td>
      <td>{item.phone}</td>
      <td>{item.age}</td>
      <td>{getGender(item.gender)}</td>
      <td>{item.start_date ? item.start_date.split(" ")[0] : ""}</td>
      <td>
        <button
          className="icon-button text-primary"
          title="Permissions"
          onClick={() => setSelectedUser(item)}
        >
          <i className="fa fa-edit"></i> Update Permissions
        </button>
      </td>
      <td>
        {/* Edit User button */}
        <button
          className="icon-button edit-icon"
          onClick={() => editUser(item, index)}
          title="Edit User"
        >
          <i className="fa fa-pencil"></i> Edit
        </button>
      </td>
      <td>
        {/* Delete user button */}
        <button
          className="icon-button delete-icon"
          onClick={() => deleteUser(item, index)}
          data-toggle="modal"
          data-target="#delete-confirm-modal"
          title="Delete User"
        >
          <i className="fa fa-times-circle"></i> Delete
        </button>
      </td>
    </tr>
  );
}

export default UserRow;
