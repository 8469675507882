import React from "react";

function UserActionButtons(props) {
  const { onDelete, setEditMode, shiftId } = props;

  const handleDelete = () => {
    let id = new FormData();
    id.append("id", shiftId);
    onDelete(id);
  };

  // edit and delete buttons
  const renderActionButtons = () => {
    return (
      <>
        <button
          className="icon-button edit-icon mr-5"
          onClick={() => setEditMode(true)}
        >
          <i className="fa fa-pencil"></i>
        </button>
        <button
          className="icon-button delete-icon"
          onClick={() => handleDelete()}
        >
          <i className="fa fa-trash"></i>
        </button>
      </>
    );
  };

  return  renderActionButtons();
}

export default UserActionButtons;
