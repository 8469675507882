import React, { useState } from "react";
import "../../css/stylesheet.css";
import EditSessionForm from "./EditSessionForm";

function SessionCard(props) {
  const [editMode, setEditMode] = useState(false);
  const {
    item,
    onComplete,
    updateSession
  } = props;

  // Delete, Complete and Edit Buttons
  const cardActionButtons = () => {
    return (
      <div className="alignSelfRight">
        <button className="icon-button edit-icon"  onClick={() => setEditMode(true)}>
          <i className="fa fa-pencil"></i> Edit
        </button>
        <button
          className="icon-button complete-icon"
          onClick={() => onComplete(item.id)}
        >
          <i className="fa fa-check"></i> Complete
        </button>
      </div>
    );
  };

  return (
    <div className="awareness-card box box-default">
      <div className="box-header with-border">
        {!editMode && cardActionButtons()}
      </div>
      <div className="box-body">
        {!editMode ? (
         props.children
        ) : (
          // Show edit form when editMode is true
          <EditSessionForm
            setEditMode={setEditMode}
            item={item}
            updateSession={updateSession}
          />
        )}
      </div>
    </div>
  );
}

export default SessionCard;
