import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../components/Alert";
import { fetchNotifications } from "../redux/actions/authenticatedUserActions";

function Notifications() {
  const dispatch = useDispatch();
  const { loggedInUser, notifications } = useSelector(
    (state) => state.authenticatedUser
  );
  useEffect(() => {
    dispatch(fetchNotifications(loggedInUser.id));
  }, [loggedInUser]);

  return (
    <div className="content-wrapper">
      <div className="wrap">
        {/* add user button triggers the add user modal*/}
        <section className="content-header pl-0 page-title flex">
          <h1 className="flexGrow">Notifications</h1>
        </section>
        <Alert />

        {notifications.map((item, index) => (
          <div
            style={{
              borderBottom: "1px solid lightgray",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
            key={`notification-${index}`}
          >
            <p>{item.message}</p>
            <small className="text-muted">{moment(item.created).format('MMM DD YYYY | hh:mm A')}</small>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Notifications;
