import React, { useEffect, useState } from "react";
import EditableUserForm from "../../../components/Shifts Components/EditableUserForm";
import { getOperatorShifts } from "../../../helpers";

function OperatorsShifts(props) {
  const {
    selectedDate,
    shifts,
    renderAddShiftIcons,
    onDelete,
    onUpdateOperator,
  } = props;

  const [availableShitfs, setAvailableShifts] = useState([]);

  useEffect(() => {
    const init = () => {
      setAvailableShifts(getOperatorShifts(selectedDate));
    };

    init();
  }, [selectedDate]);

  return availableShitfs.map((shift, index) => {
    //count the number of users per shift
    let count = 0;

    return (
      <div key={"operator-shift-modal-" + index}>
        <h4>{shift.displayDate}</h4>
        {shifts.map((item, i) => {
           if("2022-04-01 07:00:00" == item.from_date) {
            console.log('item', item);
            }
          if (
            shift.utcFromDate === item.from_date &&
            shift.utcEndDate === item.to_date
          ) {
           
            count++;
            return (
              <EditableUserForm
                key={"operator-shift-" + i}
                onDelete={onDelete}
                user={item.user}
                shiftId={item.id}
                onUpdate={onUpdateOperator}
                data={item.from_date}
                userType="operator"
              />
            );
          }
        })}

        {renderAddShiftIcons(count, shift.utcFromDate)}
        {index + 1 < availableShitfs.length && <hr />}
      </div>
    );
  });
}

export default OperatorsShifts;
