import React from "react";

function ActionButtons(props) {
  //Render these buttons when not editing
  const readModeActionButtons = () => {
    return (
      <div className="flex">
        <button
          className="btn icon-button complete-icon mr-1"
          title="Complete"
          onClick={() => props.markComplete(props.meetingId)}
        >
          <i className="fa fa-check"></i> Complete
        </button>
        <button
          className="btn icon-button edit-icon mr-1"
          onClick={() => props.toggleEditMode(true)}
          title="Edit"
        >
          <i className="fa fa-pencil"></i> Edit
        </button>
        <button
          className="btn icon-button delete-icon"
          title="Delete"
          onClick={() => props.onDeleteMeeting(props.meetingId)}
        >
          <i className="fa fa-close"></i> Delete
        </button>
      </div>
    );
  };
  return readModeActionButtons();
}

export default ActionButtons;
