import { supervisorsConstants, API_URL } from "../constants";
import Axios from "axios";
import { errorAlert } from "./alertActions";
import {
  updatingSupervisorsModal,
  showLoadingOverlay,
  loadingMeetings,
} from "./loadingActions";
import moment from "moment";

const fetchSupervisorsSuccess = (users) => ({
  type: supervisorsConstants.FETCH_SUPERVISORS_SUCCESS,
  payload: users,
});
const fetchMeetingsSuccess = (meetings) => {
  return {
    type: supervisorsConstants.FETCH_MEETINGS_SUCCESS,
    payload: meetings,
  };
};

const fetchMeetingsFailure = () => {
  return { type: supervisorsConstants.FETCH_MEETINGS_FAILURE };
};

/* MEETING ACTIONS */
const updateMeetingSuccess = (meeting) => {
  return {
    type: supervisorsConstants.UPDATE_MEETING_SUCCESS,
    payload: meeting,
  };
};

const deleteMeetingSuccess = (id) => {
  return { type: supervisorsConstants.DELETE_MEETING, payload: id };
};
const markMeetingCompleteSuccess = (id) => {
  return {
    type: supervisorsConstants.MARK_MEETING_COMPLETE,
    payload: id,
  };
};

/* MEETING ATTENDEES ACTIONS */
const addMeetingAttendeeSuccess = (meeting) => ({
  type: supervisorsConstants.ADD_MEETING_ATTENDEE_SUCCESS,
  payload: meeting,
});

const updateMeetingAttendeeSuccess = (meeting) => ({
  type: supervisorsConstants.UPDATE_MEETING_ATTENDEE_SUCCESS,
  payload: meeting,
});

const deleteAttendeeSuccess = (userMeetingId, meetingId) => ({
  type: supervisorsConstants.DELETE_MEETING_ATTENDEE,
  payload: { userMeetingId: userMeetingId, meetingId: meetingId },
});

/* SHIFTS ACTIONS */
const fetchShiftsSuccess = (shifts) => ({
  type: supervisorsConstants.FETCH_SUPERVISOR_SHIFTS,
  payload: shifts,
});

const addSupervisorShiftSuccess = (data) => ({
  type: supervisorsConstants.ADD_SUPERVISOR_SHIFT_SUCCESS,
  payload: data,
});

const updateSupervisorShiftSuccess = (id, data) => ({
  type: supervisorsConstants.UPDATE_SUPERVISOR_SHIFT_SUCCESS,
  payload: { id: id, data: data },
});

const deleteSupervisorShiftSuccess = (id) => ({
  type: supervisorsConstants.DELETE_SUPERVISOR_SHIFT_SUCCESS,
  payload: id,
});

const supervisorsShiftErrors = (message) => ({
  type: supervisorsConstants.SHOW_ERROR_MESSAGE,
  payload: message,
});

const clearErrorMessage = () => ({
  type: supervisorsConstants.CLEAR_ERROR_MESSAGE,
});

export const clearMessage = () => {
  return function (dispatch) {
    dispatch(clearErrorMessage());
  };
};

export const fetchSupervisors = () => {
  return function (dispatch) {
    return Axios.get(API_URL + "/supervisor/get_user_list?start=-1&rows=-1")
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(fetchSupervisorsSuccess(response.data.users));
        } else if (
          response.data.status === "0" &&
          response.data.msg === "No Users"
        ) {
          dispatch(fetchSupervisorsSuccess([]));
        } else {
          dispatch(errorAlert(response.data.msg));
        }
      })
      .catch((error) => {
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

/* MEETINGS */
export const fetchMeetingsByMonth = (month, year) => {
  const date = "year=" + year + "&month=" + month;
  return function (dispatch) {
    dispatch(loadingMeetings(true));
    return Axios.get(
      API_URL + "/supervisor/get_meetings_for_year_month?" + date
    )
      .then((response) => {
        if (response.data.status === "1")
          dispatch(fetchMeetingsSuccess(response.data.meetings));
        else if (
          response.data.status === "0" &&
          response.data.msg === "No Meetings"
        )
          dispatch(fetchMeetingsSuccess([]));
        dispatch(loadingMeetings(false));
      })
      .catch((error) => {
        dispatch(loadingMeetings(false));
        dispatch(errorAlert("Something went wrong"));
      });
  };
};

export const fetchMeetings = () => {
  return function (dispatch) {
    dispatch(loadingMeetings(true));
    return Axios.get(`${API_URL}/supervisor/get_meetings_for_all`)
      .then((response) => {
        if (response.data.status === "1")
          dispatch(fetchMeetingsSuccess(response.data.meetings));
        else if (
          response.data.status === "0" &&
          response.data.msg === "No Meetings"
        )
          dispatch(fetchMeetingsSuccess([]));
        dispatch(loadingMeetings(false));
      })
      .catch((error) => {
        dispatch(loadingMeetings(false));
        dispatch(errorAlert("Something went wrong"));
      });
  };
};

export const completeMeeting = (meetingId) => {
  return function (dispatch) {
    dispatch(loadingMeetings(true));
    return Axios.post(API_URL + "/supervisor/complete_meeting", meetingId)
      .then((response) => {
        if (response.data.status === "1")
          dispatch(markMeetingCompleteSuccess(meetingId.get("id")));
        else dispatch(errorAlert("Something went wrong.."));
        dispatch(loadingMeetings(false));
      })
      .catch((error) => {
        dispatch(loadingMeetings(false));
        dispatch(fetchMeetingsFailure());
        dispatch(errorAlert("Something went wrong"));
      });
  };
};

export const deleteMeeting = (meetingId) => {
  return function (dispatch) {
    dispatch(loadingMeetings(true));
    return Axios.post(API_URL + "/supervisor/delete_meeting", meetingId)
      .then((response) => {
        if (response.data.status === "1")
          dispatch(deleteMeetingSuccess(meetingId.get("id")));
        else dispatch(errorAlert("Something went wrong.."));
        dispatch(loadingMeetings(false));
      })
      .catch((error) => {
        dispatch(loadingMeetings(false));
        dispatch(errorAlert("Something went wrong"));
      });
  };
};

export const editGroupMeeting = (data) => {
  return function (dispatch) {
    dispatch(loadingMeetings(true));
    return Axios.post(API_URL + "/supervisor/edit_group_meeting", data)
      .then((response) => {
        if (response.data.status === "1")
          dispatch(updateMeetingSuccess(response.data.meeting));
        else dispatch(errorAlert("Something went wrong.."));
        dispatch(loadingMeetings(false));
      })
      .catch((error) => {
        dispatch(loadingMeetings(false));
        dispatch(errorAlert("Something went wrong"));
      });
  };
};

export const edit1on1Meeting = (data) => {
  return function (dispatch) {
    dispatch(loadingMeetings(true));
    return Axios.post(API_URL + "/supervisor/edit_single_meeting", data)
      .then((response) => {
        if (response.data.status === "1")
          dispatch(updateMeetingSuccess(response.data.meeting));
        else dispatch(errorAlert("Something went wrong.."));
        dispatch(loadingMeetings(false));
      })
      .catch((error) => {
        dispatch(loadingMeetings(false));
        dispatch(errorAlert("Something went wrong"));
      });
  };
};

/* MEETING ATTENDEES*/
export const addMeetingAttendee = (data) => {
  return function (dispatch) {
    dispatch(clearErrorMessage());
    dispatch(loadingMeetings(true));
    return Axios.post(API_URL + "/supervisor/add_meeting_attendees", data)
      .then((response) => {
        if (response.data.status === "1")
          dispatch(addMeetingAttendeeSuccess(response.data.meeting));
        else dispatch(supervisorsShiftErrors(response.data.msg));
        dispatch(loadingMeetings(false));
      })
      .catch((error) => {
        dispatch(loadingMeetings(false));
        dispatch(errorAlert("Something went wrong"));
      });
  };
};

export const updateMeetingAttendee = (data) => {
  return function (dispatch) {
    dispatch(clearErrorMessage());
    dispatch(loadingMeetings(true));
    return Axios.post(API_URL + "/supervisor/edit_meeting_attendees", data)
      .then((response) => {
        if (response.data.status === "1")
          dispatch(updateMeetingAttendeeSuccess(response.data.meeting));
        else dispatch(supervisorsShiftErrors(response.data.msg));
        dispatch(loadingMeetings(false));
      })
      .catch((error) => {
        dispatch(loadingMeetings(false));
        dispatch(errorAlert("Something went wrong"));
      });
  };
};

export const deleteMeetingAttendee = (formdata) => {
  return function (dispatch) {
    dispatch(clearErrorMessage());
    dispatch(loadingMeetings(true));
    return Axios.post(
      API_URL + "/supervisor/delete_meeting_attendees",
      formdata
    )
      .then((response) => {
        if (response.data.status === "1")
          dispatch(
            deleteAttendeeSuccess(
              formdata.get("id"),
              formdata.get("meeting_id")
            )
          );
        else dispatch(errorAlert(response.data.msg));
        dispatch(loadingMeetings(false));
      })
      .catch((error) => {
        dispatch(loadingMeetings(false));
        dispatch(errorAlert("Something went wrong"));
      });
  };
};

export const removeUserFromMeetings = (userid, date) => {
  return function (dispatch) {
    dispatch({
      type: supervisorsConstants.REMOVE_DELETED_USER,
      payload: { id: userid, date: date },
    });
  };
};
/* SHIFTS */

export const getSupervisorsShifts = (month, year) => {
  return function (dispatch) {
    dispatch(showLoadingOverlay(true));
    const date = "year=" + year + "&month=" + month;
    return Axios.get(API_URL + "/supervisor/get_shifts_for_year_month?" + date)
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(
            fetchShiftsSuccess([
              ...response.data.shifts,
              ...response.data.previous,
              ...response.data.next,
            ])
          );
        } else if (
          response.data.status === "0" &&
          response.data.msg === "No Shifts"
        )
          dispatch(fetchShiftsSuccess([]));
        else dispatch(errorAlert(response.data.msg));
        dispatch(showLoadingOverlay(false));
      })
      .catch((error) => {
        dispatch(showLoadingOverlay(false));
        dispatch(errorAlert("Something went wrong"));
      });
  };
};

export const addSupervisorShift = (data) => {
  return function (dispatch) {
    dispatch(updatingSupervisorsModal(true));
    dispatch(clearErrorMessage());
    return Axios.post(API_URL + "/supervisor/add_shift", data)
      .then((response) => {
        if (response.data.status === "1")
          dispatch(addSupervisorShiftSuccess(response.data.shift));
        else dispatch(supervisorsShiftErrors(response.data.msg));
        dispatch(updatingSupervisorsModal(false));
      })
      .catch((error) => {
        dispatch(updatingSupervisorsModal(false));
        dispatch(errorAlert("Something went wrong"));
      });
  };
};

export const updateSupervisorShift = (data) => {
  return function (dispatch) {
    dispatch(updatingSupervisorsModal(true));
    dispatch(clearErrorMessage());
    return Axios.post(API_URL + "/supervisor/edit_shift", data)
      .then((response) => {
        if (response.data.status === "1")
          dispatch(
            updateSupervisorShiftSuccess(data.get("id"), response.data.shift)
          );
        else dispatch(supervisorsShiftErrors(response.data.msg));
        dispatch(updatingSupervisorsModal(false));
      })
      .catch((error) => {
        dispatch(updatingSupervisorsModal(false));
        dispatch(errorAlert("Something went wrong"));
      });
  };
};

export const deleteSupervisorShift = (shiftid) => {
  return function (dispatch) {
    dispatch(clearErrorMessage());
    dispatch(updatingSupervisorsModal(true));
    return Axios.post(API_URL + "/supervisor/delete_shift", shiftid)
      .then((response) => {
        if (response.data.status === "1")
          dispatch(deleteSupervisorShiftSuccess(shiftid.get("id")));
        else dispatch(supervisorsShiftErrors(response.data.msg));
        dispatch(updatingSupervisorsModal(false));
      })
      .catch((error) => {
        dispatch(updatingSupervisorsModal(false));
        dispatch(errorAlert("Something went wrong"));
      });
  };
};

export const deleteSupervisorSessionsAfterCurrentDate = () => {
  return function (dispatch) {
    const currentDate = moment();
    const formatDate = currentDate.format("YYYY-MM-DD");
    dispatch({
      type: supervisorsConstants.DELETE_SHIFTS_AND_MEETINGS_BY_DATE,
      payload: formatDate,
    });
  };
};
