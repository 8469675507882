import axios from "axios";
import { API_URL, dashboardConstants } from "../constants";
import { errorAlert } from "./alertActions";
import { loadingDashboardOperators } from "./loadingActions";

const fetchOperatorsDataSuccess = (data) => ({
  type: dashboardConstants.FETCH_OPERATORS_DATA_SUCCESS,
  payload: data,
});

const fetchSupervisionDataSuccess = (data) => ({
  type: dashboardConstants.FETCH_SUPERVISION_DATA_SUCCESS,
  payload: data,
});

const fetchSupervisorsDataSuccess = (data) => ({
  type: dashboardConstants.FETCH_SUPERVISORS_DATA_SUCCESS,
  payload: data,
});

const fetchTraineesDataSuccess = (data) => ({
  type: dashboardConstants.FETCH_TRAINEES_DATA_SUCCESS,
  payload: data,
});

export const fetchOperatorsData = (fromDate, toDate) => {
  return function (dispatch) {
    if (!fromDate || !toDate) {
      dispatch(errorAlert("Please select a date range"));
    }

    dispatch(loadingDashboardOperators(true));
    return axios
      .get(
        API_URL + `/admin/get_dashboard_operators?from=${fromDate}&to=${toDate}`
      )
      .then((response) => {
        if (response.data.status === "1") {
          let data = response.data.users.map((item) => {
            return {
              ...item,
              total_operator_shifts:
                parseInt(item.total_day_shifts) +
                parseInt(item.total_night_shifts),
            };
          });

          dispatch(fetchOperatorsDataSuccess(data));
        } else if (response.data.status === "0") {
          dispatch(fetchOperatorsDataSuccess([]));
        }
        dispatch(loadingDashboardOperators(false));
      })
      .catch((error) => {
        dispatch(loadingDashboardOperators(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const fetchSupervisionData = (fromDate, toDate) => {
  return function (dispatch) {
    dispatch(loadingDashboardOperators(true));
    return axios
      .get(
        API_URL +
          `/admin/get_dashboard_supervision?from=${fromDate}&to=${toDate}`
      )
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(fetchSupervisionDataSuccess(response.data.meetings));
        } else if (response.data.status === "0") {
          dispatch(fetchSupervisionDataSuccess([]));
        }
        dispatch(loadingDashboardOperators(false));
      })
      .catch((error) => {
        dispatch(loadingDashboardOperators(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const fetchSupervisorsData = (fromDate, toDate) => {
  return function (dispatch) {
    dispatch(loadingDashboardOperators(true));
    return axios
      .get(
        API_URL +
          `/admin/get_dashboard_supervisors?from=${fromDate}&to=${toDate}`
      )
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(fetchSupervisorsDataSuccess(response.data.users));
        } else if (response.data.status === "0") {
          dispatch(fetchSupervisorsDataSuccess([]));
        }
        dispatch(loadingDashboardOperators(false));
      })
      .catch((error) => {
        dispatch(loadingDashboardOperators(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const fetchTraineesData = (fromDate, toDate) => {
  return function (dispatch) {
    dispatch(loadingDashboardOperators(true));
    return axios
      .get(
        API_URL + `/admin/get_dashboard_trainees?from=${fromDate}&to=${toDate}`
      )
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(fetchTraineesDataSuccess(response.data.users));
        } else if (response.data.status === "0") {
          dispatch(fetchTraineesDataSuccess([]));
        }
        dispatch(loadingDashboardOperators(false));
      })
      .catch((error) => {
        dispatch(loadingDashboardOperators(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};
