import React from "react";
import Alert from "../../components/Alert";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchOperatorsData } from "../../redux/actions/dashboardActions";
import { ExportCSV } from "../../components/ExportCSV";
import { useState } from "react";

const headers = [
  { label: "id", key: "sub_id" },
  { label: "Operator Name", key: "name" },
  { label: "CO Shifts Covered", key: "total_co_shifts" },
  {
    label: "Monthly Required Shifts",
    key: "shifts_required_per_month",
  },
  { label: "Day Slots", key: "total_day_shifts" },
  { label: "Night Slots", key: "total_night_shifts" },
  { label: "Total Covered Shifts", key: "total_operator_shifts" },
  { label: "# of External Group Sessions", key: "total_external_sessions" },
  { label: "# of Internal Group Sessions", key: "total_group_sessions" },
  {
    label: "# of Individual Sessions",
    key: "total_supervision_sessions",
  },
  { label: "# shifts (2am-6am)", key: "total_night_shifts_2" },
  { label: "Phone No.", key: "phone" },
  { label: "Dates Booked", key: "dates_booked_list" },
];

function DatesBookedModal({ currentRow }) {
  return (
    <div
      className="modal fade"
      id="datesBookedModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="true"
    >
      {currentRow && (
        <div className="modal-dialog" role="document">
          <div className="modal-content box">
            <div className="modal-header">
              <h4 className="modal-title">Dates Booked</h4>
              <h5>{currentRow.name}</h5>
            </div>
            <div className="modal-body">
              <ul>
                {currentRow.dates_booked_list.split(",").map((e) => (
                  <li>{e}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function Operators() {
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [currentRow, setCurrentRow] = useState(null);

  const columns = [
    {
      name: "Id",
      selector: "sub_id",
      sortable: true,
    },
    {
      name: "Operator Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "CO Shifts Covered",
      selector: "total_co_shifts",
      sortable: true,
    },
    {
      name: "Monthly Required Shifts",
      selector: "shifts_required_per_month",
      sortable: true,
    },
    {
      name: "Day Slots",
      selector: "total_day_shifts",
      sortable: true,
    },
    {
      name: "Night Slots",
      selector: "total_night_shifts",
      sortable: true,
    },
    {
      name: "Total Covered Shifts",
      sortable: true,
      selector: "total_operator_shifts",
    },
    {
      name: "# of External Group Sessions",
      selector: "total_external_sessions",
      sortable: true,
    },
    {
      name: "# of Internal Group Sessions",
      selector: "total_group_sessions",
      sortable: true,
    },

    {
      name: "# of Individual Sessions",
      selector: "total_supervision_sessions",
      sortable: true,
    },
    {
      name: "# shifts (2am-6am)",
      selector: "total_night_shifts_2",
      sortable: true,
    },
    {
      name: "Phone No.",
      selector: "phone",
      sortable: false,
    },

    {
      name: "Dates Booked",
      // selector: "dates_booked_list"
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      cell: (row) => (
        <>
          {row.dates_booked_list.length > 0 && (
            <button
              className="icon-button text-primary"
              onClick={() => showDatesBooked(row)}
              title="Edit User"
            >
              <i className="fa fa-eye"></i>
            </button>
          )}
        </>
      ),
    },
  ];

  const data = useSelector((state) => state.dashboard.operators);

  const showDatesBooked = (rowData) => {
    window.jQuery("#datesBookedModal").modal("show");
    setCurrentRow(rowData);
  };

  const fetchData = () => {
    dispatch(fetchOperatorsData(fromDate, toDate));
  };

  return (
    <div className="content-wrapper">
      <div className="wrap">
        {/* add user button triggers the add user modal*/}
        <section className="content-header pl-0 page-title flex">
          <h1 className="flexGrow">Dashboard - Operators</h1>
          <ExportCSV fileName="operators" csvData={data} headers={headers} />
        </section>
        <Alert />
        <div style={{ display: "flex", marginBottom: "20px" }}>
          <input
            type="date"
            className="form-control"
            style={{ marginRight: "20px" }}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <input
            type="date"
            className="form-control"
            style={{ marginRight: "20px" }}
            onChange={(e) => setToDate(e.target.value)}
            min={fromDate}
          />
          <button
            disabled={!fromDate || !toDate}
            className="btn btn-primary"
            onClick={fetchData}
          >
            Submit
          </button>
        </div>

        <DataTable title="Operators" columns={columns} data={data} responsive />
      </div>
      <DatesBookedModal currentRow={currentRow} />
    </div>
  );
}

export default Operators;
