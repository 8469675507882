import React, { Fragment } from "react";
import OneOnOneMeeting from "../../components/MeetingsComponents/OneOnOneMeeting";
import GroupMeeting from "../../components/MeetingsComponents/GroupMeeting";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteMeeting,
  completeMeeting,
  deleteMeetingAttendee,
  editGroupMeeting,
  edit1on1Meeting,
  addMeetingAttendee,
  updateMeetingAttendee,
} from "../../redux/actions/supervisorsActions";
import BoxLoading from "../../components/BoxLoading";
import Notification from "../../components/Shifts Components/Notification";

function MeetingsModal(props) {
  const meetings = useSelector((state) => state.supervisors.meetings);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.loadingMeetings);
  const errorMessage = useSelector((state) => state.supervisors.errorMessage);

  //add meeting attendee
  const onAddAttendee = (data) => {
    dispatch(addMeetingAttendee(data));
  };

  //delete meeting attendee
  const onDeleteAttendee = (id, meetingId) => {
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("meeting_id", meetingId);

    dispatch(deleteMeetingAttendee(formdata));
  };

  const onEditAttendee = (data) => {
    dispatch(updateMeetingAttendee(data));
  };

  //update group meeting
  const updateGroupMeeting = (data) => {
    dispatch(editGroupMeeting(data));
  };

  //update 1 on 1 meeting
  const update1on1Meeting = (data) => {
    dispatch(edit1on1Meeting(data));
  };

  //delete meeting
  const onDeleteMeeting = (meetingId) => {
    let id = new FormData();
    id.append("id", meetingId);
    dispatch(deleteMeeting(id));
  };

  const markComplete = (meetingId) => {
    let id = new FormData();
    id.append("id", meetingId);
    dispatch(completeMeeting(id));
  };

  const renderData = (type) => {
    let filteredMeetings = [];
    if (type === "group") {
      filteredMeetings = meetings.filter((meeting) => meeting.type !== "1on1");
    } else {
      filteredMeetings = meetings.filter((meeting) => meeting.type === "1on1");
    }
    return filteredMeetings.map((item, index) => {
      return (
        <Fragment key={"meeting-" + index}>
          {item.type === "1on1" ? (
            <OneOnOneMeeting
              meetingIndex={index}
              item={item}
              onUpdate={update1on1Meeting}
              onDeleteAttendee={onDeleteAttendee}
              onEditAttendee={onEditAttendee}
              onAddAttendee={onAddAttendee}
              onDeleteMeeting={onDeleteMeeting}
              markComplete={markComplete}
            />
          ) : (
            <GroupMeeting
              item={item}
              onDeleteAttendee={onDeleteAttendee}
              onEditAttendee={onEditAttendee}
              onDeleteMeeting={onDeleteMeeting}
              markComplete={markComplete}
              meetingIndex={index}
              updateGroupMeeting={updateGroupMeeting}
              onAddAttendee={onAddAttendee}
            />
          )}
          {index !== meetings.length - 1 && <hr />}
        </Fragment>
      );
    });
  };

  const emptyModal = () => {
    return (
      <center>
        <h3 className="display-4">No Meetings</h3>
      </center>
    );
  };

  return (
    <div
      className="modal fade"
      id="meetingsModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="meetingsModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content box">
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalLongTitle">
              Sessions / Meetings
            </h4>
          </div>
          <div className="modal-body">
            {errorMessage && (
              <Notification message={errorMessage} type="danger" />
            )}
            {/* render the list */}

            <div className="nav-tabs-custom">
              <ul className="nav nav-tabs">
                <li className="active">
                  <a href="#tab_1"  style={{backgroundColor:'white', borderColor:'white'}} data-toggle="tab">
                    <b>Group Meetings</b>
                  </a>
                </li>
                <li>
                  <a href="#tab_2" style={{backgroundColor:'white', borderColor:'white'}} data-toggle="tab">
                    <b>Individual Meetings</b>
                  </a>
                </li>
              </ul>
              <div className="tab-content"  style={{backgroundColor:'white'}}>
                <div className="tab-pane active" id="tab_1">
                  {meetings && meetings.length > 0
                    ? renderData("group")
                    : emptyModal()}
                </div>
                <div className="tab-pane" id="tab_2">
                  {meetings && meetings.length > 0
                    ? renderData("1on1")
                    : emptyModal()}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-green"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
          {loading && <BoxLoading />}
        </div>
      </div>
    </div>
  );
}

export default MeetingsModal;
