import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getUTCDate, getLocalTime } from "../../helpers";

function EditSessionForm(props) {
  const user_id = useSelector(
    (state) => state.authenticatedUser.loggedInUser.id
  );
  const { item, setEditMode, updateSession } = props;

  const [date, setDate] = useState(getLocalTime(item.from_date, "YYYY-MM-DD"));
  const [time, setTime] = useState(getLocalTime(item.from_date, "hh:mm:ss"));
  const [audience, setAudience] = useState(item.target);
  const [location, setLocation] = useState(item.location);
  const [currency, setCurrency] = useState(item.currency);
  const [compensatedAmount, setCompensatedAmount] = useState(item.cost);
  const [language, setLanguage] = useState(item.language);
  const [notes, setNotes] = useState(item.notes);

  //exists edit mode
  const onCancel = (e) => {
    e.preventDefault();
    setEditMode(false);
  };

  //handles form submit
  const onSubmit = (e) => {
    e.preventDefault();
    let updatedSession = new FormData();
    updatedSession.append("id", item.id);
    updatedSession.append("user_id", user_id);

    let utcDate = getUTCDate(date + " " + time, "YYYY-MM-DD HH:mm:ss");
    let utcToDate = getUTCDate(date + " " + time, "YYYY-MM-DD HH:mm:ss");

    updatedSession.append("from_date", utcDate);
    updatedSession.append("to_date", utcToDate);
    updatedSession.append("target", audience);
    updatedSession.append("location", location);
    updatedSession.append("cost", compensatedAmount);
    updatedSession.append("currency", currency);
    updatedSession.append("language", language);
    updatedSession.append("notes", notes);

    updateSession(updatedSession);
    setEditMode(false);
  };

  return (
    <div className="pl-15">
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label>Date</label>
          <input
            type="date"
            className="form-control"
            value={date}
            required
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Time</label>
          <input
            type="time"
            value={time}
            className="form-control"
            required
            onChange={(e) => setTime(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Location</label>
          <input
            type="text"
            className="form-control"
            value={location}
            required
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Target Audience</label>
          <input
            type="text"
            className="form-control"
            value={audience}
            required
            onChange={(e) => setAudience(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Language</label>
          <input
            type="text"
            className="form-control"
            value={language}
            required
            onChange={(e) => setLanguage(e.target.value)}
            maxLength={150}
          />
        </div>

        <div className="form-group">
          <label>Notes</label>
          <textarea
            type="text"
            className="form-control"
            value={notes}
            cols={20}
            onChange={(e) => setNotes(e.target.value)}
          ></textarea>
        </div>

        <div className="form-group">
          <label>Compensated</label>

          <div className="input-group w100">
            <input
              type="number"
              className="form-control"
              value={compensatedAmount}
              required
              min={0}
              onChange={(e) => setCompensatedAmount(e.target.value)}
            />
            <div className="input-group-btn" style={{ width: "auto" }}>
              <select
                className="form-control p-0"
                onChange={(e) => setCurrency(e.target.value)}
                defaultValue={currency}
              >
                <option>LBP</option>
                <option>$</option>
              </select>
            </div>
          </div>
        </div>
        <div className="alignSelfRight">
          <button className="btn btn-dark-gray" onClick={(e) => onCancel(e)}>
            Cancel
          </button>
          <input type="submit" value="Save" className="btn btn-green mr-10" />
        </div>
      </form>
    </div>
  );
}

export default EditSessionForm;
