import React, { useState } from "react";
import UsersSelect from "../UsersSelect";

function EditShiftUserForm(props) {
  const { onSubmit, userType, setEditMode } = props;
  const [selectedUser, setSelectedUser] = useState(null);
  const [errorMsg, hasError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedUser) hasError(true);
    else {
      onSubmit(selectedUser);
      setSelectedUser(null);
      hasError(false);
    }
  };

  const onCancel = (e) => {
    e.preventDefault();
    setEditMode(false);
    hasError(false)
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="input-group">
        <UsersSelect setSelectedUser={setSelectedUser} userType={userType} />
        <span className="input-group-btn">
          <button
            className="green bg-white btn users-select-submit-btn"
            type="submit"
            title="Save"
          >
            <i className="fa fa-check"></i> Save
          </button>
          <button
            className="red bg-white btn users-select-cancel-btn "
            onClick={(e) => onCancel(e)}
            type="button"
            title="Cancel"
          >
            <i className="fa fa-close"></i> Cancel
          </button>
        </span>
      </div>
      {errorMsg && (
        <small className="text-danger pl-10 ">Please select a user</small>
      )}
    </form>
  );
}

export default EditShiftUserForm;
