import React, { useState } from "react";
import EditSessionAttendeeForm from "./EditSessionAttendee";

function SessionAttendee(props) {
  const [editMode, setEditMode] = useState(false);
  const { session, updateAttendee, onDelete, title, type } = props;

  const actionButtons = () => {
    return (
      <div>
        <button
          className="icon-button-small edit-icon"
          title="Edit"
          onClick={() => setEditMode(true)}
        >
          <i className="fa fa-pencil"></i>
        </button>
        <button
          className="icon-button-small delete-icon"
          title="Delete"
          onClick={() => onDelete(session.id, session.session_id)}
        >
          <i className="fa fa-close"></i>
        </button>
      </div>
    );
  };

  return (
    <div className="session-list">
      <div className="session-list-item flex">
        <p className="title">{title}:</p>
        <div className="flexGrow span-data pb-15 breakWord">
          {editMode ? (
            <EditSessionAttendeeForm
              onSubmit={updateAttendee}
              id={session.id}
              setEditMode={setEditMode}
              userType={type}
            />
          ) : (
            session.user.name
          )}
        </div>
      </div>
      {!editMode && actionButtons()}
    </div>
  );
}

export default SessionAttendee;
