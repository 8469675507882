import React from "react";
import Alert from "../../components/Alert";
import { ExportCSV } from "../../components/ExportCSV";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchSupervisorsData } from "../../redux/actions/dashboardActions";
import { useState } from "react";

const headers = [
  { label: "Supervisor Name", key: "name" },
  { label: "On Call Shifts", key: "total_shifts" },
];

const columns = [
  {
    name: "Supervisor Name",
    selector: "name",
    sortable: true,
  },
  {
    name: "On Call Shifts",
    selector: "total_shifts",
    sortable: true,
  },
];

function SupervisorDuties() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dashboard.supervisors);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const fetchData = () => {
    dispatch(fetchSupervisorsData(fromDate, toDate));
  };

  return (
    <div className="content-wrapper">
      <div className="wrap">
        {/* add user button triggers the add user modal*/}
        <section className="content-header pl-0 page-title flex">
          <h1 className="flexGrow">Dashboard - Supervisor Duties</h1>
          <ExportCSV fileName="supervision" csvData={data} headers={headers} />
        </section>
        <Alert />
        <div style={{ display: "flex", marginBottom: "20px" }}>
          <input
            type="date"
            className="form-control"
            style={{ marginRight: "20px" }}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <input
            type="date"
            className="form-control"
            style={{ marginRight: "20px" }}
            onChange={(e) => setToDate(e.target.value)}
            min={fromDate}
          />
          <button
            disabled={!fromDate || !toDate}
            className="btn btn-primary"
            onClick={fetchData}
          >
            Submit
          </button>
        </div>
          <DataTable title="Supervisor Duties" columns={columns} data={data} />
      </div>
    </div>
  );
}

export default SupervisorDuties;
