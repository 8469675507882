import React, { useState } from "react";
import { getUTCDate, getLocalTime } from "../../helpers";

function GroupMeetingEditForm(props) {
  const [date, setDate] = useState(getLocalTime(props.data.from_date,"YYYY-MM-DD"));
  const [time, setTime] = useState(getLocalTime(props.data.from_date,"hh:mm:ss"));
  const [toTime, setToTime] = useState(getLocalTime(props.data.to_date,"hh:mm:ss"));

  const [location, setLocation] = useState(props.data.location);

  const onSubmit = (e) => {
    e.preventDefault();

    let formdata = new FormData();
    formdata.append("id", props.data.id);
    formdata.append("user_id", props.data.user_id);

    let utcdate = getUTCDate(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss");
    formdata.append("from_date", utcdate);

    let utcToDate = getUTCDate(`${date} ${toTime}`, "YYYY-MM-DD HH:mm:ss");
    formdata.append("to_date", utcToDate);
    formdata.append("location", location);

    props.onSubmit(formdata);
    props.setEditMode(false);
  };

  const onCancel = (e) => {
    e.preventDefault();
    props.setEditMode(false);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="pl-15">
        <div className="flex">
          <div className="form-group flexGrow">
            <label>Date</label>
            <input
              type="date"
              className="form-control"
              required
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <div className="flexGrow form-group ml-10">
            <label>Location</label>
            <input
              type="text"
              className="form-control"
              required
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="pl-15">
        <div className="flex">
          <div className="form-group flexGrow">
            <label>From Time</label>
            <input
              type="time"
              className="form-control"
              required
              value={time}
              onChange={(e) => setTime(e.target.value)}
            />
          </div>
          <div className="flexGrow form-group ml-10">
            <label>To Time</label>
            <input
              type="time"
              className="form-control"
              required
              value={toTime}
              onChange={(e) => setToTime(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="text-right">
        <button type="submit" className="btn btn-green mr-20">
          Save Changes
        </button>
        <button className="btn btn-dark-gray" onClick={(e) => onCancel(e)}>
          Cancel
        </button>
      </div>
    </form>
  );
}

export default GroupMeetingEditForm;
