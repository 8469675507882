import React from "react";
import { CSVLink } from "react-csv";

export const ExportCSV = ({ csvData, fileName, headers }) => {
  return (
    <button className="btn btn-warning text-dark">
      <CSVLink
        data={csvData}
        filename={fileName}
        headers={headers}
        style={{ color: "white" }}
      >
        Export
      </CSVLink>
    </button>
  );
};
