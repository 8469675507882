import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchChiefOperators } from "../redux/actions/chiefsActions";
import { fetchSupervisors } from "../redux/actions/supervisorsActions";
import { loadOperators } from "../redux/actions/operatorsActions";
import { fetchTrainees } from "../redux/actions/traineesActions";
import { fetchUsers } from "../redux/actions/usersActions";
import { sort } from "../helpers";

function UsersSelect(props) {
  const { setSelectedUser, userType } = props;
  const dispatch = useDispatch();
  const [emptyMessage, setEmptyMessage] = useState("");

  const data = useSelector((state) => {
    switch (userType) {
      case "chief":
        return state.chiefs.chiefOperators;

      case "supervisor":
        return state.supervisors.supervisors;

      case "operator":
        return state.operators.operators;

      case "trainee":
        return state.trainees.trainees;
      case "meeting":
        return state.users.users ? sort(state.users.users, "name") : null;
      default:
        break;
    }
  });

  useEffect(() => {
    switch (userType) {
      case "chief":
        setEmptyMessage("No Chief Operators");
        dispatch(fetchChiefOperators());
        break;
      case "supervisor":
        setEmptyMessage("No Supervisors");
        dispatch(fetchSupervisors());
        break;
      case "operator":
        setEmptyMessage("No Operators");
        dispatch(loadOperators());
        break;
      case "trainee":
        setEmptyMessage("No Trainees");
        dispatch(fetchTrainees());
        break;
      case "meeting":
        setEmptyMessage("No Users");
        if (!data) dispatch(fetchUsers());
        break;
      default:
        break;
    }
  }, [userType]);

  const sortData = () => {
    if (userType === "trainee") {
      return data.sort((a, b) =>
        a["user"]["name"].localeCompare(b["user"]["name"])
      );
    } else return sort(data, "name");
  };

  const renderUsers = () => {
    return (
      data &&
      sortData().map((user, index) => {
        return (
          <option
            value={userType === "trainee" ? user.user.id : user.id}
            key={"user-select-" + index}
          >
            {userType === "trainee" ? user.user.name : user.name}
          </option>
        );
      })
    );
  };

  const getMessage = () => {
    return data && data.length === 0 ? emptyMessage : "Loading...";
  };

  return (
    <select
      style={props.style ? props.style : null}
      required
      disabled={data && data.length > 0 ? false : true}
      onChange={(e) => setSelectedUser(e.target.value)}
      className="form-control"
    >
      {data && data.length > 0 ? (
        <>
          <option value={null}>Select a User</option>
          {renderUsers()}
        </>
      ) : (
        <option value={null}>{getMessage()}</option>
      )}
    </select>
  );
}

export default UsersSelect;
