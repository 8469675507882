import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getUTCDate } from "../../helpers";

function CreateSessionForm(props) {
  const userId = useSelector(
    (state) => state.authenticatedUser.loggedInUser.id
  );

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [audience, setAudience] = useState("");
  const [location, setLocation] = useState("");
  const [compensated, setCompensated] = useState(false);
  const [compensatedAmount, setCompensatedAmount] = useState("");
  const [currency, setCurrency] = useState("LBP");
  const [language, setLanguage] = useState("");
  const [notes, setNotes] = useState("");

  const { onSubmit } = props;

  const handleSubmit = (event) => {
    event.preventDefault();

    let newSession = new FormData();
    newSession.append("user_id", userId);

    let utcDate = getUTCDate(date + " " + time, "YYYY-MM-DD HH:mm:ss");
    let utcToDate = getUTCDate(date + " " + time, "YYYY-MM-DD HH:mm:ss");

    newSession.append("from_date", utcDate);
    newSession.append("to_date", utcToDate);
    newSession.append("target", audience);
    newSession.append("location", location);
    newSession.append("cost", compensatedAmount);
    newSession.append("currency", currency);
    newSession.append("language", language);
    newSession.append("notes", notes);
    onSubmit(newSession);

    //close modal & reset form
    document.getElementById("modal-close-btn").click();
  };

  const resetForm = () => {
    setDate("");
    setTime("");
    setAudience("");
    setLocation("");
    setCompensated(false);
    setCompensatedAmount("");
  };

  return (
    <div
      className="modal fade"
      id="createSessionModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="createSessionLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Create Session</h4>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={date}
                    required
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Time</label>
                  <input
                    type="time"
                    value={time}
                    className="form-control"
                    required
                    onChange={(e) => setTime(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Target Audience</label>
                  <input
                    type="text"
                    className="form-control"
                    value={audience}
                    required
                    onChange={(e) => setAudience(e.target.value)}
                    maxLength={150}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Language</label>
                  <input
                    type="text"
                    className="form-control"
                    value={language}
                    required
                    onChange={(e) => setLanguage(e.target.value)}
                    maxLength={150}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Location</label>
                  <input
                    type="text"
                    className="form-control"
                    value={location}
                    required
                    onChange={(e) => setLocation(e.target.value)}
                    maxLength={225}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    className="form-control"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <div className="row remove--horizontal-margin">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Compensated</label>
                    <div className="form-group clearfix row">
                      <div className="icheck-success d-inline col-sm-3">
                        <input
                          type="radio"
                          id="radioPrimary1"
                          name="compensationButtons"
                          checked={!compensated}
                          onClick={() => setCompensated(false)}
                        />
                        <label htmlFor="radioPrimary1"> No</label>
                      </div>
                      <div className="icheck-success d-inline col-sm-3">
                        <input
                          type="radio"
                          id="radioPrimary2"
                          checked={compensated}
                          name="compensationButtons"
                          onClick={() => setCompensated(true)}
                        />
                        <label htmlFor="radioPrimary2"> Yes</label>
                      </div>
                    </div>
                  </div>
                </div>
                {compensated && (
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Compensation Amount</label>

                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          value={compensatedAmount}
                          required={compensated ? true : false}
                          onChange={(e) => setCompensatedAmount(e.target.value)}
                          min={0}
                        />
                        <div className="input-group-btn" style={{ width: 0 }}>
                          <select
                            className="form-control"
                            onChange={(e) => setCurrency(e.target.value)}
                          >
                            <option selected="selected">LBP</option>
                            <option>$</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <input type="submit" value="Submit" className="btn btn-green" />
              <button
                type="button"
                className="btn btn-dark-gray"
                data-dismiss="modal"
                id="modal-close-btn"
                onClick={() => resetForm()}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateSessionForm;
