import moment from "moment";

const daysOfTheWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

//formats the date ex: Thursday 20th 2020
export function formatDay(date) {
  const momentDate = moment(date);
  return (
    daysOfTheWeek[momentDate.day()] + " " + momentDate.format("MMMM Do YYYY")
  );
}

//formats the date, returns array of dates
//the first and last days of the week at index 0
//days of the weeks index 1 -> 7
export function formatWeek(date) {
  const momentDate = moment(date);
  const from_date = moment(momentDate.startOf("isoWeek"));
  const to_date = moment(momentDate.endOf("isoWeek"));
  let header =
    "Week " +
    daysOfTheWeek[from_date.day()] +
    " " +
    from_date.format("Do") +
    " - " +
    daysOfTheWeek[to_date.day()] +
    " " +
    to_date.format("Do");

  var startDate = moment(momentDate.startOf("isoWeek"));
  var stopDate = moment(momentDate.endOf("isoWeek"));
  var dateArray = [header];
  var currentDate = moment(startDate);
  var stopdate = moment(stopDate);
  while (currentDate <= stopdate) {
    let curr = moment(currentDate);
      dateArray.push({
        date: curr.format("YYYY-MM-DD"),
        formattedDate: daysOfTheWeek[curr.day()] + " " + curr.format("Do"),
      });
    currentDate = moment(currentDate).add(1, "days");
  }
  return dateArray;
}
