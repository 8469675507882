import React, { useState, useEffect } from "react";

function PaginationButtons(props) {
  const { data, displayItems } = props;
  const itemsPerPage = props.itemsPerPage ? props.itemsPerPage : 15;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const navigate = (id) => {
    if (id >= 1 && id <= totalPages) {
      setCurrentPage(id);
    }
  };

  useEffect(() => {
    displayItems(data.slice(indexOfFirstUser, indexOfLastUser));
  }, [data, currentPage, indexOfLastUser, indexOfFirstUser, displayItems]);

  return (
    <ul className="pagination pagination-sm no-margin pull-right">
      <li>
        <button onClick={() => navigate(currentPage - 1)} className="anchorButton ">
          <i className="fa fa-arrow-left"></i>
        </button>
      </li>
      <li>
        <button className="anchorButton">{`${currentPage} of ${totalPages}`}</button>
      </li>
      <li>
        <button onClick={() => navigate(currentPage + 1)} className="anchorButton">
          <i className="fa fa-arrow-right"></i>
        </button>
      </li>
    </ul>
  );
}

export default PaginationButtons;
