import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";

const AuthRoute = (props) => {
  const isAuthUser = useSelector((state) => state.authenticatedUser.isAuthUser);
  if (!isAuthUser) return <Redirect to="/" />;

  return <Route {...props} />;
};

export default AuthRoute;
