import { dashboardConstants } from "../constants";

const initialState = {
  operators: [],
  supervision: [],
  supervisors: [],
  trainees: [],
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case dashboardConstants.FETCH_OPERATORS_DATA_SUCCESS:
      return Object.assign({}, state, {
        operators: action.payload,
      });
    case dashboardConstants.FETCH_SUPERVISION_DATA_SUCCESS:
      return Object.assign({}, state, {
        supervision: action.payload,
      });
    case dashboardConstants.FETCH_SUPERVISORS_DATA_SUCCESS:
      return Object.assign({}, state, {
        supervisors: action.payload,
      });
    case dashboardConstants.FETCH_TRAINEES_DATA_SUCCESS:
      return Object.assign({}, state, {
        trainees: action.payload,
      });
    default:
      return state;
  }
};
