import React, { useState, useEffect, Fragment } from "react";
import UsersSelect from "../UsersSelect";

function PlusIconForm(props) {
  const [showForm, toggleShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [errorMsg, hasError] = useState(false);
  const { onSubmit, data, userType } = props;

  //handles form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!selectedUser) hasError(true);
    else {
      onSubmit(selectedUser, data);
      setSelectedUser(null);
      hasError(false);
      toggleShow(false);
    }
  };

  useEffect(() => {
    if (userType !== "trainee") {
      toggleShow(false);
      setSelectedUser(null);
    }
  }, [props, userType]);

  //renders the form to assign a user to the shift
  const renderForm = () => {
    return (
      <Fragment>
        <form onSubmit={handleFormSubmit} className="flex">
          <i
            className="fa fa-minus-circle add-circles text-red"
            onClick={() => {
              toggleShow(false);
              hasError(false);
            }}
          ></i>
          <div className="input-group flexGrow ml-10">
            <UsersSelect
              setSelectedUser={setSelectedUser}
              userType={userType}
            />
            <span className="input-group-btn">
              <button
                className="green bg-white btn users-select-cancel-btn"
                type="submit"
              >
                <i className="fa fa-check"></i> Add
              </button>
            </span>
          </div>
        </form>
        {errorMsg && showForm && (
          <small className="text-danger ml-40">Please select a user</small>
        )}
      </Fragment>
    );
  };

  return (
    <>
      <div className={showForm ? "shift-attendee-container pl-0" : ""}>
        {showForm ? (
          renderForm()
        ) : (
          <i
            className="fa fa-plus-circle add-circles "
            onClick={() => toggleShow(true)}
          ></i>
        )}
      </div>
    </>
  );
}

export default PlusIconForm;
