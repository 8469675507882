import React, { useState } from "react";
import UsersDropdown from "./UsersDropdown";

function EditSessionAttendeeForm(props) {
  const { onSubmit, id, setEditMode, userType } = props;
  const [selectedUser, setSelectedUser] = useState(null);
  const [errorMsg, hasError] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedUser) hasError(true);
    else {
      onSubmit(selectedUser, id, userType);
      setEditMode(false);
      hasError(false)
      setSelectedUser(null);
    }
  };

  const onCancel = (e) => {
    e.preventDefault();
    hasError(false)
    setEditMode(false);
  };
  return (
    <>
    <form onSubmit={handleSubmit}>
      <div className="input-group">
        <UsersDropdown setSelectedUser={setSelectedUser} userType={userType} />
        <span className="input-group-btn">
          <button
            className="green bg-white btn users-select-submit-btn"
            type="submit"
            title="Save"
          >
            <i className="fa fa-check"></i>
          </button>
          <button
            className="red bg-white btn users-select-cancel-btn "
            onClick={(e) => onCancel(e)}
            type="button"
            title="Cancel"
          >
            <i className="fa fa-close"></i>
          </button>
        </span>
      </div>
    </form>
    {errorMsg&& (
        <small className="text-danger pl-10 ">Please select a user</small>
      )} 
    </>
  );
}

export default EditSessionAttendeeForm;
