import { operatorsConstants, API_URL, usersConstants } from "../constants";
import Axios from "axios";
import { errorAlert } from "./alertActions";
import { updatingOperatorsModal, showLoadingOverlay } from "./loadingActions";
import moment from "moment";

const fetchOperatorsSuccess = (users) => ({
  type: operatorsConstants.FETCH_OPERATORS_SUCCESS,
  payload: users,
});

const fetchOperatorsShiftsFailure = () => ({
  type: operatorsConstants.FETCH_OPERATORS_SHIFTS_FAILURE,
});

const fetchOperatorsShiftsSuccess = (shifts) => ({
  type: operatorsConstants.FETCH_OPERATORS_SHIFTS_SUCCESS,
  payload: shifts,
});

const addOperatorShiftSuccess = (shift) => ({
  type: operatorsConstants.ADD_OPERATOR_SHIFT_SUCCESS,
  payload: shift,
});

const updateShiftSuccess = (id, shift) => ({
  type: operatorsConstants.UPDATE_OPERATOR_SHIFT_SUCCESS,
  payload: { id: id, shift: shift },
});

const deleteShiftSuccess = (id) => ({
  type: operatorsConstants.DELETE_OPERATOR_SHIFT,
  payload: id,
});

const operatorShiftErrors = (message) => ({
  type: operatorsConstants.SHOW_ERROR_MESSAGE,
  payload: message,
});

const clearErrorMessage = () => ({
  type: operatorsConstants.CLEAR_ERROR_MESSAGE,
});

export const removeUserFromOperators = (userid, date) => {
  return function (dispatch) {
    dispatch({
      type: usersConstants.REMOVE_DELETED_USER,
      payload: { id: userid, date: date },
    });
  };
};

export const clearMessage = () => {
  return function (dispatch) {
    dispatch(clearErrorMessage());
  };
};

export const fetchOperators = () => {
  return function (dispatch) {
    dispatch(showLoadingOverlay(true));
    return Axios.get(API_URL + "/operator/get_user_list?start=-1&rows=-1")
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(fetchOperatorsSuccess(response.data.users));
        } else if (
          response.data.status === "0" &&
          response.data.msg === "No Users"
        ) {
          dispatch(fetchOperatorsSuccess([]));
        } else {
          dispatch(errorAlert(response.data.msg));
        }
        dispatch(showLoadingOverlay(false));
      })
      .catch((error) => {
        dispatch(showLoadingOverlay(false));
        dispatch(errorAlert("Something went wrong"));
      });
  };
};

export const loadOperators = () => {
  return function (dispatch) {
    return Axios.get(API_URL + "/operator/get_user_list?start=-1&rows=-1")
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(fetchOperatorsSuccess(response.data.users));
        } else if (
          response.data.status === "0" &&
          response.data.msg === "No Users"
        ) {
          dispatch(fetchOperatorsSuccess([]));
        } else {
          dispatch(errorAlert(response.data.msg));
        }
      })
      .catch((error) => {
        dispatch(errorAlert("Something went wrong"));
      });
  };
};

export const getOperatorsShifts = (month, year) => {
  return function (dispatch) {
    dispatch(showLoadingOverlay(true));
    const date = "year=" + year + "&month=" + month;
    return Axios.get(API_URL + "/operator/get_shifts_for_year_month?" + date)
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(fetchOperatorsShiftsSuccess(response.data.shifts));
        } else if (
          response.data.status === "0" &&
          response.data.msg === "No Shifts"
        ) {
          dispatch(fetchOperatorsShiftsSuccess([]));
        } else {
          dispatch(errorAlert(response.data.msg));
        }
        dispatch(showLoadingOverlay(false));
      })
      .catch((error) => {
        dispatch(showLoadingOverlay(false));
        dispatch(fetchOperatorsShiftsFailure());
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const deleteOperatorShift = (shiftid) => {
  return function (dispatch) {
    dispatch(clearErrorMessage())
    dispatch(updatingOperatorsModal(true));
    return Axios.post(API_URL + "/operator/delete_shift", shiftid)
      .then((response) => {
        if (response.data.status === "1")
          dispatch(deleteShiftSuccess(shiftid.get("id")));
        else dispatch(errorAlert(response.data.msg));
        dispatch(updatingOperatorsModal(false));
      })
      .catch((error) => {
        dispatch(updatingOperatorsModal(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const addOperatorShift = (data) => {
  return function (dispatch) {
    dispatch(clearErrorMessage())
    dispatch(updatingOperatorsModal(true));

    return Axios.post(API_URL + "/operator/add_shift", data)
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(addOperatorShiftSuccess(response.data.shift));
        } else {
          console.error(response);
          dispatch(operatorShiftErrors(response.data.msg));
        }
        dispatch(updatingOperatorsModal(false));
      })
      .catch((error) => {
        console.error(error);
        dispatch(updatingOperatorsModal(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const updateOperatorShift = (data) => {
  return function (dispatch) {
    dispatch(clearErrorMessage())
    dispatch(updatingOperatorsModal(true));
    return Axios.post(API_URL + "/operator/edit_shift", data)
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(updateShiftSuccess(data.get("id"), response.data.shift));
        } else dispatch(operatorShiftErrors(response.data.msg));
        dispatch(updatingOperatorsModal(false));
      })
      .catch((error) => {
        dispatch(updatingOperatorsModal(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const deleteOperatorsShiftsAfterCurrentDate = () => {
  return function (dispatch) {
    const currentDate = moment();
    const formatDate = currentDate.format("YYYY-MM-DD");
    dispatch({
      type: operatorsConstants.DELETE_OPERATOR_SHIFTS_BY_DATE,
      payload: formatDate,
    });
  };
};
