import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EditableUserForm from "../../../components/Shifts Components/EditableUserForm";
import { getOperatorShifts } from "../../../helpers";

function ChiefOperatorsShifts(props) {
  const {
    selectedDate,
    onDelete,
    onUpdateOperator,
    renderAddShiftIcons,
  } = props;

  const [availableShitfs, setAvailableShifts] = useState([]);
  const shifts = useSelector((state) => state.chiefs.shifts);

  useEffect(() => {
    const init = () => {
      setAvailableShifts(getOperatorShifts(selectedDate));
    };

    init();
  }, [selectedDate]);

  return availableShitfs.map((shift, index) => {
    let count = 0;
    //count the number of users per shift
    return (
      <div key={"chief-operator-shift-modal-" + index}>
        <h4>{shift.displayDate}</h4>
        {shifts.map((item, i) => {
          if (
            shift.utcFromDate === item.from_date &&
            shift.utcEndDate === item.to_date
          ) {
            count++;
            return (
              <EditableUserForm
                key={"operator-shift-" + i}
                onDelete={onDelete}
                user={item.user}
                shiftId={item.id}
                onUpdate={onUpdateOperator}
                data={item.from_date}
                userType="chief"
              />
            );
          }
        })}

        {count < 1 && renderAddShiftIcons(count, shift.utcFromDate)}
        {index + 1 < availableShitfs.length && <hr />}
      </div>
    );
  });
}

export default ChiefOperatorsShifts;
