import React, { Fragment } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import LoadingOverlay from "../LoadingOverlay";

function Layout(props) {
  const isLoggedIn = useSelector((state) => state.authenticatedUser.isAuthUser);
  const username = useSelector((state) => state.authenticatedUser.loggedInUser);
  const loading = useSelector((state) => state.loading.loadingOverlay);
  document.body.classList.remove("bg-green");

  return (
    <Fragment>
      {isLoggedIn && (
        <>
          <Header name={username.name} />
          <Sidebar />
          {loading && <LoadingOverlay />}
        </>
      )}
    </Fragment>
  );
}

export default Layout;
