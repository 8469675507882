import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import { setAuthorizationToken } from "./redux/actions/authenticatedUserActions";

if (localStorage.getItem("embrace-admin-token"))
  setAuthorizationToken(
    JSON.parse(localStorage.getItem("embrace-admin-token"))
  );

if (sessionStorage.getItem("embrace-admin-token"))
  setAuthorizationToken(
    JSON.parse(sessionStorage.getItem("embrace-admin-token"))
  );

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
