import { sessionsConstants } from "../constants";

const initialState = {
  isFetching: false,
  didInvalidate: false,
  sessions: null,
  sessionUsers: null,
};

const sessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    // sessions are being fetched
    case sessionsConstants.FETCH_SESSIONS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    //   sessions are recieved successfully
    case sessionsConstants.FETCH_SESSIONS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        sessions: action.payload,
      });
    //   something went wrong while fetching
    case sessionsConstants.FETCH_SESSIONS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
      });
    case sessionsConstants.CREATE_SESSION:
      return Object.assign({}, state, {
        sessions: [...state.sessions, action.payload],
      });
    case sessionsConstants.MARK_SESSION_COMPLETE:
      return Object.assign({}, state, {
        sessions: state.sessions.filter((item) => item.id !== action.payload),
      });
    case sessionsConstants.UPDATE_SESSION_SUCCESS:
      return Object.assign({}, state, {
        sessions: state.sessions.map((item, index) => {
          if (item.id === action.payload.id) {
            return { ...action.payload.data };
          }
          return item;
        }),
      });

    /* MANAGE ATTENDEES */
    case sessionsConstants.FETCH_SESSION_USERS:
      return Object.assign({}, state, {
        sessionUsers: action.payload,
      });

    case sessionsConstants.ADD_SESSION_ATTENDEE_SUCCESS:
      return Object.assign({}, state, {
        sessions: state.sessions.map((item, index) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        }),
      });
    case sessionsConstants.UPDATE_SESSION_ATTENDEE_SUCCESS:
      return Object.assign({}, state, {
        sessions: state.sessions.map((item, index) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        }),
      });
    case sessionsConstants.DELETE_SESSION_ATTENDEE_SUCCESS:
      return Object.assign({}, state, {
        sessions: state.sessions.map((item, index) => {
          if (item.id === action.payload.sessionId) {
            let attendees = item.attendees.filter(
              (attendee, index) => attendee.id !== action.payload.userSessionId
            );
            return { ...item, attendees };
          }
          return item;
        }),
      });
    case sessionsConstants.DELETE_SESSION_BY_DATE:
      return Object.assign({}, state, {
        sessions: state.sessions
          ? state.sessions.filter(
              (item) => item.from_date.split(" ")[0] < action.payload
            )
          : null,
      });
    case sessionsConstants.REMOVE_DELETED_USER:
      return Object.assign({}, state, {
        sessions:
          state.sessions &&
          state.sessions.map((session) => {
            if (
              session.from_date.split(" ")[0] > action.payload.date &&
              session.attendees
            ) {
              let attendees = session.attendees.filter(
                (attendee) =>
                  Number(attendee.user.id) !== Number(action.payload.id)
              );
              return { ...session, attendees };
            } else {
              return session;
            }
          }),
      });
    default:
      return state;
  }
};

export default sessionsReducer;
