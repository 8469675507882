import { settingsConstants } from "../constants";

const initialState = {
  isFetching: false,
  didInvalidate: false,
  settings: null,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case settingsConstants.FETCH_SETTINGS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case settingsConstants.FETCH_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        settings: action.payload,
      });
    case settingsConstants.FETCH_SETTINGS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
      });
    case settingsConstants.UPDATE_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        settings: action.payload,
      });
    default:
      return state;
  }
};

export default settingsReducer;
