import React from "react";

function CalendarLegend(props) {
  return (
    <>
    <div className="box box-warning">
      <div className="box-body">
        <div className="mt-10 mb-10">
          <div className="flex">
            <span className="legend-circle bg-yellow"></span>
            <label className="ml-10">Partially Booked</label>
          </div>
        </div>
      </div>
    </div>
    <div className="box box-success">
    <div className="box-body ">
      <div className="mt-10 mb-10">
        <div className="flex">
          <span className="legend-circle bg-green"></span>
          <label className="ml-10">Fully Booked </label>
        </div>
      </div>
    </div>
  </div>
  {props.children}
  </>
  );
}

export default CalendarLegend;
