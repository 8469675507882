import React, { useState } from "react";
import { useSelector } from "react-redux";
import BoxLoading from "../BoxLoading";

function Profile(props) {
  const { user, updateProfile } = props;
  const [name, setName] = useState(user.name);
  const [gender, setGender] = useState(user.gender);
  const [age, setAge] = useState(user.age);
  const [phone, setPhone] = useState(user.phone);

  const loading = useSelector((state) => state.loading.isUpdatingProfile);

  const onSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("phone", phone);
    formdata.append("age", age);
    formdata.append("gender", gender);
    formdata.append("name", name);
    formdata.append("id", user.id);
    updateProfile(formdata);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="box box-success">
        <div className="box-header with-border">
          <h3 className="box-title">Profile Information</h3>

          <div className="box-tools pull-right">
            <button
              type="button"
              className="btn btn-box-tool"
              data-widget="collapse"
            >
              <i className="fa fa-minus"></i>
            </button>
          </div>
        </div>
        <div className="box-body">
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>Phone</label>
            <input
              className="form-control"
              placeholder="Phone Number"
              value={phone}
              required
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Age</label>
            <input
              type="number"
              className="form-control"
              placeholder="Age"
              value={age}
              required
              onChange={(e) => setAge(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Sex</label>
            <select
              className="form-control"
              style={{ width: "100%" }}
              required
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Inter">Intersex</option>
              <option value="Prefer">Prefer not to say</option>
            </select>
          </div>
        </div>
        {loading && <BoxLoading />}
        <div className="box-footer">
          <div className="alignSelfRight">
            <input
              type="submit"
              value="Save Changes"
              className="btn btn-green"
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default Profile;
