import { alertConstants } from "../constants";

const initialState = {
  type: null,
  message: null,
};
const alertReducer = (state = {}, action) => {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: "success",
        message: action.message,
      };
    case alertConstants.ERROR:
      return {
        type: "danger",
        message: action.message,
      };
    case alertConstants.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default alertReducer;
