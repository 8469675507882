import React, { useEffect, useState } from "react";
import { formatWeek } from "../../components/FormattedDate";
import PlusIconForm from "../../components/Shifts Components/PlusIconForm";
import EditableUserForm from "../../components/Shifts Components/EditableUserForm";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteChiefShift,
  addChiefShift,
  updateChiefShift,
} from "../../redux/actions/chiefsActions";
import {
  deleteSupervisorShift,
  addSupervisorShift,
  updateSupervisorShift,
} from "../../redux/actions/supervisorsActions";
import Notification from "../../components/Shifts Components/Notification";
import BoxLoading from "../../components/BoxLoading";

function ShiftsPerWeekModal(props) {
  const dispatch = useDispatch();
  const { userType, selectedDate } = props;
  const shifts = useSelector((state) =>
    userType === "chief" ? state.chiefs.shifts : state.supervisors.shifts
  );

  const errorMessage = useSelector((state) =>
    userType === "chief"
      ? state.chiefs.errorMessage
      : state.supervisors.errorMessage
  );
  const maxPerDay = useSelector((state) =>
    userType === "chief"
      ? state.settings.settings.chief_operators_per_day
      : state.settings.settings.supervisors_per_day
  );
  const loading = useSelector(
    (state) => state.loading.isUpdatingSupervisorsModal
  );
  const [weekDays, setWeekDays] = useState(null);
  const [header, setHeader] = useState(null);

  useEffect(() => {
    let dates = formatWeek(selectedDate);
    //dates [0] --> week range
    //date [1,7] --> week days
    setHeader(dates[0]);
    setWeekDays(dates.slice(1));
  }, [selectedDate]);

  //handles the submit form in the PlusIconForm
  const addShift = (id, date) => {
    let formdata = new FormData();
    formdata.append("user_id", id);
    formdata.append("day", date);
    if (userType === "chief") dispatch(addChiefShift(formdata));
    else dispatch(addSupervisorShift(formdata));
  };

  const updateShift = (userid, shiftId, day) => {
    let formdata = new FormData();
    formdata.append("user_id", userid);
    formdata.append("id", shiftId);
    formdata.append("day", day);
    if (userType === "chief") dispatch(updateChiefShift(formdata));
    else dispatch(updateSupervisorShift(formdata));
  };

  //removes supervisor / chief from the shift
  const onDelete = (shiftId) => {
    if (userType === "chief") dispatch(deleteChiefShift(shiftId));
    else dispatch(deleteSupervisorShift(shiftId));
  };

  const addAttendeeIcons = (date, size) => {
    let empty = new Array(size).fill(null);
    return empty.map((item, index) => {
      return (
        <div className="shift-container mt-10" key={"plus-icon-" + index}>
          <PlusIconForm onSubmit={addShift} data={date} userType={userType} />
        </div>
      );
    });
  };

  //renders shift attendees
  const shiftAttendees = (date) => {
    const shiftUser = shifts.filter((shift) => shift.day === date);
    const max = Number(maxPerDay); //max number of users per shift
    if (shiftUser.length === 0) {
      //empty shift
      return addAttendeeIcons(date, max);
    } else {
      let list = [];
      shiftUser.map((user, index) => {
        list.push(
          <EditableUserForm
            key={index + "-shift"}
            onDelete={onDelete}
            user={user.user}
            shiftId={user.id}
            data={user.day}
            onUpdate={updateShift}
            userType={userType}
          />
        );
      });
      for (let i = 0; i < max - shiftUser.length; i++)
        list.push(
          <div className="shift-container mt-10" key={"plus-icon-button-shift"+i}>
            <PlusIconForm onSubmit={addShift} data={date} userType={userType} />
          </div>
        );
      return list.map((item) => {
        return item;
      });
    }
  };

  //renders list of shifts
  const renderShifts = () => {
    return weekDays.map((item, index) => {
      return (
        <div key={"week-view-item-" + index}>
          <h4>{item.formattedDate}</h4>
          {shiftAttendees(item.date)}
          {index < weekDays.length - 1 && <hr />}
        </div>
      );
    });
  };

  return (
    <div
      className="modal fade"
      id="weekViewModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content box">
          <div className="modal-header">
            <h4 className="modal-title">{header}</h4>
          </div>
          <div className="modal-body">
            {errorMessage && (
              <Notification message={errorMessage} type="danger" />
            )}
            {weekDays && shifts && renderShifts()}
          </div>
          <div className="modal-footer">
            <button className="btn btn-green" data-dismiss="modal">
              Close
            </button>
          </div>
          {loading && <BoxLoading />}
        </div>
      </div>
    </div>
  );
}

export default ShiftsPerWeekModal;
