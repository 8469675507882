import { alertConstants } from "../constants";

 export const successAlert = (message) => {
  return { type: alertConstants.SUCCESS, message };
}

 export const errorAlert = (message) => {
  return { type: alertConstants.ERROR, message };
}

 export const clearAlert = () => {
  return { type: alertConstants.CLEAR };
}

