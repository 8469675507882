import { sessionsConstants, API_URL } from "../constants";
import Axios from "axios";
import { successAlert, errorAlert } from "./alertActions";
import { showLoadingOverlay } from "./loadingActions";
import moment from "moment";

const fetchSessionsSuccess = (sessions) => {
  return { type: sessionsConstants.FETCH_SESSIONS_SUCCESS, payload: sessions };
};

const fetchSessionsFailure = () => {
  return { type: sessionsConstants.FETCH_TRAINEES_FAILURE };
};

const createSessionSuccess = (session) => ({
  type: sessionsConstants.CREATE_SESSION,
  payload: session,
});

const updateSessionSuccess = (id, session) => ({
  type: sessionsConstants.UPDATE_SESSION_SUCCESS,
  payload: { id: id, data: session },
});

const markSessionCompleteSuccess = (id) => ({
  type: sessionsConstants.MARK_SESSION_COMPLETE,
  payload: id,
});

const addSessionAttendeeSuccess = (data) => ({
  type: sessionsConstants.ADD_SESSION_ATTENDEE_SUCCESS,
  payload: data,
});

const updateSessionAttendeeSuccess = (data) => ({
  type: sessionsConstants.UPDATE_SESSION_ATTENDEE_SUCCESS,
  payload: data,
});

const deleteSessionAttendeeSuccess = (id, sessionId) => ({
  type: sessionsConstants.DELETE_SESSION_ATTENDEE_SUCCESS,
  payload: { userSessionId: id, sessionId: sessionId },
});

const fetchSessionUsersSuccess = (users) => ({
  type: sessionsConstants.FETCH_SESSION_USERS,
  payload: users,
});

export const removeUserFromSessions = (userid, date) => {
  return function (dispatch) {
    dispatch({
      type: sessionsConstants.REMOVE_DELETED_USER,
      payload: { id: userid, date: date },
    });
  };
};

export const fetchSessionsByMonth = (month, year) => {
  const date = "year=" + year + "&month=" + month;
  return function (dispatch) {
    dispatch(showLoadingOverlay(true));
    return Axios.get(API_URL + "/api/get_sessions_for_year_month?" + date)
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(fetchSessionsSuccess(response.data.sessions));
        } else if (
          response.data.status === "0" &&
          response.data.msg === "No Sessions"
        )
          dispatch(fetchSessionsSuccess([]));
        dispatch(showLoadingOverlay(false));
      })
      .catch((error) => {
        dispatch(showLoadingOverlay(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const fetchSessions = () => {
  return function (dispatch) {
    dispatch(showLoadingOverlay(true));
    return Axios.get(`${API_URL}/api/get_sessions_for_all`)
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(fetchSessionsSuccess(response.data.sessions));
        } else if (
          response.data.status === "0" &&
          response.data.msg === "No Sessions"
        )
          dispatch(fetchSessionsSuccess([]));
        dispatch(showLoadingOverlay(false));
      })
      .catch((error) => {
        dispatch(showLoadingOverlay(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const getAwarenessOfficersAndTrainees = () => {
  return function (dispatch) {
    return Axios.get(API_URL + "/awareness/get_user_list?start=-1&rows=-1")
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(fetchSessionUsersSuccess(response.data.users));
        } else if (
          response.data.status === "0" &&
          response.data.msg === "No Users"
        )
          dispatch(fetchSessionUsersSuccess([]));
        else {
          dispatch(errorAlert(response.data.msg));
        }
      })
      .catch((error) => {
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const createSession = (session) => {
  return function (dispatch) {
    dispatch(showLoadingOverlay(true));
    return Axios.post(API_URL + "/awareness/add_session", session)
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(createSessionSuccess(response.data.session));
          dispatch(successAlert("Session created successfully!"));
        } else {
          dispatch(errorAlert(response.data.msg));
        }
        dispatch(showLoadingOverlay(false));
      })
      .catch((error) => {
        dispatch(showLoadingOverlay(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const completeSession = (sessionId) => {
  return function (dispatch) {
    return Axios.post(API_URL + "/awareness/complete_session", sessionId)
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(markSessionCompleteSuccess(sessionId.get("id")));
        } else {
          dispatch(errorAlert(response.data.msg));
        }
      })
      .catch((error) => dispatch(errorAlert("Something went wrong..")));
  };
};

export const updateSession = (updatedSession) => {
  return function (dispatch) {
    dispatch(showLoadingOverlay(true));
    return Axios.post(API_URL + "/awareness/edit_session", updatedSession)
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(
            updateSessionSuccess(
              updatedSession.get("id"),
              response.data.session
            )
          );
          dispatch(successAlert("Session updated successfully"));
        } else {
          dispatch(errorAlert(response.data.msg));
        }
        dispatch(showLoadingOverlay(false));
      })
      .catch((error) => {
        dispatch(showLoadingOverlay(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const addSessionAttendee = (data) => {
  return function (dispatch) {
    dispatch(showLoadingOverlay(true));
    return Axios.post(API_URL + "/awareness/add_session_attendees", data)
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(addSessionAttendeeSuccess(response.data.session));
          dispatch(successAlert("Attendee added successfully"));
        } else dispatch(errorAlert(response.data.msg));
        dispatch(showLoadingOverlay(false));
      })
      .catch((error) => {
        dispatch(fetchSessionsFailure());
        dispatch(showLoadingOverlay(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const updateSessionAttendee = (data) => {
  return function (dispatch) {
    dispatch(showLoadingOverlay(true));
    return Axios.post(API_URL + "/awareness/edit_session_attendees", data)
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(updateSessionAttendeeSuccess(response.data.session));
          dispatch(successAlert("Attendee updated successfully"));
        } else dispatch(errorAlert(response.data.msg));
        dispatch(showLoadingOverlay(false));
      })
      .catch((error) => {
        dispatch(showLoadingOverlay(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const deleteSessionAttendee = (sessionUserId, sessionId) => {
  return function (dispatch) {
    dispatch(showLoadingOverlay(true));
    return Axios.post(
      API_URL + "/awareness/delete_session_attendees",
      sessionUserId
    )
      .then((response) => {
        if (response.data.status === "1")
          dispatch(
            deleteSessionAttendeeSuccess(sessionUserId.get("id"), sessionId)
          );
        else dispatch(errorAlert(response.data.msg));
        dispatch(showLoadingOverlay(false));
      })
      .catch((error) => {
        dispatch(showLoadingOverlay(false));
        dispatch(errorAlert("Something went wrong"));
      });
  };
};

export const deleteSessionsAfterCurrentDate = () => {
  return function (dispatch) {
    const currentDate = moment();
    const formatDate = currentDate.format("YYYY-MM-DD");
    dispatch({
      type: sessionsConstants.DELETE_SESSION_BY_DATE,
      payload: formatDate,
    });
  };
};
