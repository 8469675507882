import React, { useState, useEffect } from "react";
import "../../css/stylesheet.css";
import SupervisorSettings from "./SupervisorSettings";
import ChiefSettings from "./ChiefSettings";
import TraineeSettings from "./TraineeSettings";
import ShiftsSettings from "./ShiftsSettings";
import PushAnnouncement from "./PushAnnouncement";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  fetchSettings,
  updateSettings,
  sendPushAnnouncement,
} from "../../redux/actions/settingsActions";
import Alert from "../../components/Alert";
import moment from "moment";

function Settings(props) {
  const settings = useSelector((state) => state.settings.settings);
  const userId = useSelector(
    (state) => state.authenticatedUser.loggedInUser.id
  );
  const currentdate = moment().format("YYYY-MM-DD");

  const dispatch = useDispatch();
  const [dayStartTime, setDayStartTime] = useState();
  const [shiftsPerDay, setShiftsPerDay] = useState();
  const [hoursPerShift, setHoursPerShift] = useState();
  const [operatorsPerShift, setOperatorsPerShift] = useState();
  const [attendeesPerMeeting, setAttendeesPerMeeting] = useState();
  const [chiefPerDay, setChiefPerDay] = useState();
  const [supervisorsPerDay, setSupervisorsPerDay] = useState();
  const [hoursRequired, setHoursRequired] = useState();

  //Send push annoncement
  const sendAnnouncement = (msg) => {
    let formdata = new FormData();
    formdata.append("id", userId);
    formdata.append("msg", msg);
    dispatch(sendPushAnnouncement(formdata));
  };

  useEffect(() => {
    const initSettings = () => {
      dispatch(fetchSettings());
    };

    if (!settings) initSettings();
  }, [settings, dispatch]);

  useEffect(() => {
    if (settings) {
      setDayStartTime(settings.starttime);
      setShiftsPerDay(settings.shits_per_day);
      setHoursPerShift(settings.hours_per_shift);
      setOperatorsPerShift(settings.operators_per_shift);
      setSupervisorsPerDay(settings.supervisors_per_day);
      setAttendeesPerMeeting(settings.meeting_attendees);
      setChiefPerDay(settings.chief_operators_per_day);
      setSupervisorsPerDay(settings.supervisors_per_day);
      setAttendeesPerMeeting(settings.meeting_attendees);
      setHoursRequired(settings.trainee_hours);
    }
  }, [settings, currentdate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let updatedSettings = new FormData();
    updatedSettings.append("user_id", userId);
    updatedSettings.append("shits_per_day", shiftsPerDay);
    updatedSettings.append("hours_per_shift", hoursPerShift);
    updatedSettings.append("starttime", dayStartTime);
    updatedSettings.append("operators_per_shift", operatorsPerShift);
    updatedSettings.append("trainee_hours", hoursRequired);
    updatedSettings.append("chief_operators_per_day", chiefPerDay);
    updatedSettings.append("supervisors_per_day", supervisorsPerDay);
    updatedSettings.append("meeting_attendees", attendeesPerMeeting);
    dispatch(updateSettings(updatedSettings));
  };

  return (
    <div className="content-wrapper">
      {/* <LoadingOverlay /> */}
      <section className="content-header page-title">
        <h1>Settings</h1>
      </section>

      <Alert />
      <div className="box settings-container">
        <form onSubmit={handleSubmit} id="settings-form">
          <div className="row">
            <div className="col-md-6">
              <ShiftsSettings
                dayStartTime={dayStartTime}
                setDayStartTime={setDayStartTime}
                shiftsPerDay={shiftsPerDay}
                setShiftsPerDay={setShiftsPerDay}
                hoursPerShift={hoursPerShift}
                setHoursPerShift={setHoursPerShift}
                operatorsPerShift={operatorsPerShift}
                setOperatorsPerShift={setOperatorsPerShift}
              />

              <TraineeSettings
                hoursRequired={hoursRequired}
                setHoursRequired={setHoursRequired}
              />
            </div>
            <div className="col-md-6">
              <SupervisorSettings
                supervisorsPerDay={supervisorsPerDay}
                setSupervisorsPerDay={setSupervisorsPerDay}
                attendeesPerMeeting={attendeesPerMeeting}
                setAttendeesPerMeeting={setAttendeesPerMeeting}
              />

              <ChiefSettings
                chiefPerDay={chiefPerDay}
                setChiefPerDay={setChiefPerDay}
              />

              <PushAnnouncement sendAnnouncement={sendAnnouncement} />
            </div>
          </div>
          <div className="text-center">
            <input
              className="btn btn-green"
              type="submit"
              value="Save Settings"
              form="settings-form"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect()(Settings);
