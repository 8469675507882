import React, { useState, useEffect, useRef } from "react";
import EditShiftUserForm from "./EditShitfUserForm";
import UserActionButtons from "./UserActionButtons";

function EditableUserForm(props) {
  const [editMode, setEditMode] = useState(false);
  const { user, shiftId, onDelete, onUpdate, data, userType } = props;

  const source = user.avatar
    ? "https://embrace.website/api/" + user.avatar
    : "https://embrace.website/api/uploads/avatar/default.png";

  const imageRef = useRef();

  //handles form submit
  const onEdit = (userid) => {
    onUpdate(userid, shiftId, data);
    setEditMode(false);
  };

  useEffect(() => {
    if (userType !== "trainee") setEditMode(false);

    document.addEventListener("click", function (e) {
      var node = e.target;
      var inside = false;
      while (node) {
        if (node.classList.contains("user_thumbnail")) {
          inside = true;
          break;
        }
        node = node.parentElement;
      }
      if (!inside) {
        let zoomedImage = document.getElementsByClassName(
          "user_thumbnail_active"
        );
        if (zoomedImage.length > 0)
          zoomedImage[0].classList.remove("user_thumbnail_active");
      }
    });
  }, [props, userType]);

  const zoomImage = () => {
    let zoomedImage = document.getElementsByClassName("user_thumbnail_active");
    if (zoomedImage.length > 0)
      zoomedImage[0].classList.remove("user_thumbnail_active");
    imageRef.current.classList.add("user_thumbnail_active");
  };
  return (
    <div className="shift-container">
      {/* Show the from when editMode is true */}
      {/* Show the user's name when editMode is false */}
      <div className="shift-attendee-container">
        {editMode ? (
          <EditShiftUserForm
            onSubmit={onEdit}
            setEditMode={setEditMode}
            userType={userType}
          />
        ) : (
          <div className="d-flex flex-row align-items-center">
            <img
              onClick={zoomImage}
              ref={imageRef}
              className="user_thumbnail"
              src={source}
              alt=""
            />
            <h5 className="breakWord"> {user.name}</h5>
          </div>
        )}
      </div>

      {/* Edit and Delete Icons */}
      {/* Show the action buttons when editMode is false */}
      <div className="shift-buttons-container">
        {!editMode && (
          <UserActionButtons
            setEditMode={setEditMode}
            onDelete={onDelete}
            shiftId={shiftId}
          />
        )}
      </div>
    </div>
  );
}

export default EditableUserForm;
