import React, { useEffect, useState } from "react";
import EditableUserForm from "../../../components/Shifts Components/EditableUserForm";
import Jumbotron from "../../../components/Jumbotron";
import { useSelector } from "react-redux";
import { getOperatorShifts } from "../../../helpers";

function TraineesShifts(props) {
  const {
    shifts,
    renderAddShiftIcons,
    onDelete,
    onUpdateOperator,
    selectedDate,
  } = props;

  const [dailyShifts, setDailyShifts] = useState([]);
  const traineeShifts = useSelector((state) => state.trainees.shifts);
  const [availableShifts, setAvailableShifts] = useState([]);
  const dayStartTime = useSelector(
    (state) => state.settings.settings.starttime
  );
  const shiftsPerDay = useSelector(
    (state) => state.settings.settings.shits_per_day
  );
  const hoursPerShift = useSelector(
    (state) => state.settings.settings.hours_per_shift
  );

  useEffect(() => {
    let array = shifts.filter(
      (item) => item.from_date.split(" ")[0] === selectedDate
    );

    setDailyShifts(array);
  }, [shifts, selectedDate]);

  useEffect(() => {
    const init = () => {
      let shiftsPerDayArray = getOperatorShifts(selectedDate);

      //group shifts by date
      let traineeShifts = [
        ...new Map(
          dailyShifts.map((item) => [item["from_date"], item])
        ).values(),
      ];
      //display shifts booked by at least 1 operator
      let filteredShifts = [];
      shiftsPerDayArray.forEach((shift) => {
        let items = traineeShifts.filter((item) => {
          return item.from_date === shift.utcFromDate;
        });
        if (items.length > 0)
          filteredShifts.push({ ...shift, id: items[0].id });
      });
      setAvailableShifts(filteredShifts);
    };
    init();
  }, [dailyShifts, dayStartTime, hoursPerShift, selectedDate, shiftsPerDay]);

  const renderShifts = () => {
    return availableShifts.map((shift, index) => {
      const shiftFromDate = shift.utcFromDate;
      const shiftToDate = shift.utcEndDate;

      const shiftId = shift.id;
      const title = shift.displayDate;

      //count number of users per shift
      let count = 0;

      return (
        <div key={"trainee-shifts-modal-" + index}>
          <h4>{title}</h4>
          {traineeShifts &&
            traineeShifts.map((traineeShifts, index) => {
              if (
                traineeShifts.from_date === shiftFromDate &&
                traineeShifts.to_date === shiftToDate
              ) {
                count++;
                return (
                  <EditableUserForm
                    key={"trainee-shift-" + index}
                    onDelete={onDelete}
                    user={traineeShifts.trainee_user}
                    shiftId={traineeShifts.shift_trainees_id}
                    onUpdate={onUpdateOperator}
                    data={traineeShifts.id}
                    userType="trainee"
                  />
                );
              }
            })}
          {renderAddShiftIcons(count, shiftId)}
          {index + 1 < availableShifts.length && <hr />}
        </div>
      );
    });
  };

  return dailyShifts.length > 0 ? (
    renderShifts()
  ) : (
    <Jumbotron message="No Shifts" />
  );
}

export default TraineesShifts;
