import { combineReducers } from "redux";
import usersReducer from "./usersReducer";
import permissionsReducer from "./permissionsReducer";
import settingsReducer from "./settingsReducer";
import traineesReducer from "./traineesReducer";
import operatorsReducer from "./operatorsReducer";
import chiefsReducer from "./chiefsReducer";
import supervisorsReducer from "./supervisorsReducer";
import sessionsReducer from "./sessionsReducer";
import authenticatedUserReducer from "./authenticatedUserReducer";
import alertsReducer from "./alertsReducer";
import { loadingReducer } from "./loadingReducer";
import { dashboardReducer } from "./dashboardReducer";

const rootReducer = combineReducers({
  users: usersReducer,
  dashboard: dashboardReducer,
  permissions: permissionsReducer,
  settings: settingsReducer,
  trainees: traineesReducer,
  operators: operatorsReducer,
  chiefs: chiefsReducer,
  supervisors: supervisorsReducer,
  sessions: sessionsReducer,
  authenticatedUser: authenticatedUserReducer,
  alerts: alertsReducer,
  loading: loadingReducer,
});

export default rootReducer;
