import { permissionsConstants, API_URL } from "../constants";
import axios from "axios";
import { errorAlert } from "./alertActions";

const isFetching = () => {
  return { type: permissionsConstants.FETCH_PERMISSIONS };
};

const fetchPermissionsSuccess = (permissions) => {
  return {
    type: permissionsConstants.FETCH_PERMISSIONS_SUCCESS,
    payload: permissions,
  };
};

const fetchPermissionsFailure = () => {
  return { type: permissionsConstants.FETCH_PERMISSIONS_FAILURE };
};

export const fetchPermissions = () => {
  return function (dispatch) {
    dispatch(isFetching());

    return axios
      .get(API_URL + "/admin/get_role_list")
      .then((response) => {
        if (response.data.status === "1") {
          const permissions = response.data.roles;
          dispatch(fetchPermissionsSuccess(permissions));
        }
      })
      .catch((error) => {
        dispatch(fetchPermissionsFailure());
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};
