import React, { useState, useEffect } from "react";
import CalendarComponent from "../components/Calendar";
import "../css/stylesheet.css";
import MeetingsModal from "./Modals/MeetingsModal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchSettings } from "../redux/actions/settingsActions";
import {
  getSupervisorsShifts,
  fetchMeetings,
} from "../redux/actions/supervisorsActions";
import ShiftsPerWeekModal from "./Modals/ShiftsPerWeekModal";
import Alert from "../components/Alert";
import { clearMessage } from "../redux/actions/supervisorsActions";

function Supervisor() {
  const dispatch = useDispatch();
  const [date, setDate] = useState();
  const shifts = useSelector((state) => state.supervisors.shifts);
  const settings = useSelector((state) => state.settings.settings);

  //handles click on calendar
  const onDateClick = (date) => {
    let month = date.split("-")[1];
    let year = date.split("-")[0];
    dispatch(getSupervisorsShifts(month, year));
    
    //show modal
    setDate(date);
    dispatch(clearMessage());
    document.getElementById("supervisorsModalButton").click();
  };

  const onMonthChange = (month, year) => {
    dispatch(getSupervisorsShifts(month, year));
  };

  useEffect(() => {
    const curr = moment();
    const currentMonth = curr.format("MM");
    const currentYear = curr.format("YYYY");
    if (!shifts) dispatch(getSupervisorsShifts(currentMonth, currentYear)); //send month + year
    if (!settings) dispatch(fetchSettings());
  }, []);

  const getMeetings = () => dispatch(fetchMeetings());

  return (
    <div className="content-wrapper">
      <section className="content-header page-title flex">
        <h1 className="flexGrow">Sessions & Meetings</h1>
        <button
          data-toggle="modal"
          data-target="#meetingsModal"
          className="btn btn-green"
          id="meetings-modal-button"
          onClick={() => {
            getMeetings();
            dispatch(clearMessage());
          }}
        >
          View Meetings
        </button>
      </section>

      <Alert />
      {settings && shifts && (
        <center>
          <div className="calendar">
            <CalendarComponent
              onDateClick={onDateClick}
              maxPerDay={settings.supervisors_per_day}
              shifts={shifts}
              type="supervisor"
              onMonthChange={onMonthChange}
            />
          </div>
        </center>
      )}

      {/* Button to launch the operators per week modal*/}
      <button
        id="supervisorsModalButton"
        className="btn btn-primary hidden"
        data-toggle="modal"
        data-target="#weekViewModal"
      >
        Launch Modal
      </button>

      {/* list of operators per week modal */}
      {settings && shifts && (
        <ShiftsPerWeekModal selectedDate={date} userType="supervisor" />
      )}

      {/* Meetings Modal */}
      {settings && <MeetingsModal />}
    </div>
  );
}

export default Supervisor;
