import React from "react";

function Jumbotron(props) {
  const { message } = props;
  return (
    <div className="jumbotron jumbotron-fluid">
      <div className="container">
        <h1 className="display-4 text-center">{message}</h1>
      </div>
    </div>
  );
}

export default Jumbotron;
