import { loadingConstants } from "../constants";

const initialState = {
  isUpdatingEmail: false,
  isUpdatingPassword: false,
  isUpdatingProfile: false,
  isUpdatingOperatorsModal: false,
  isUpdatingSupervisorsModal: false,
  isUpdatingUser: false,
  loadingOverlay: false,
  isUpdatingPermissions: false,
  isFetchingTrainees: false,
  loadingMeetings: false,
  loadingDashboardOperators: false,
};

export const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case loadingConstants.UPDATING_EMAIL:
      return Object.assign({}, state, {
        isUpdatingEmail: action.payload,
      });
    case loadingConstants.UPDATING_PASSWORD:
      return Object.assign({}, state, {
        isUpdatingPassword: action.payload,
      });
    case loadingConstants.UPDATING_PROFILE:
      return Object.assign({}, state, {
        isUpdatingProfile: action.payload,
      });
    case loadingConstants.OPERATORS_MODAL:
      return Object.assign({}, state, {
        isUpdatingOperatorsModal: action.payload,
      });
    case loadingConstants.SUPERVISORS_MODAL:
      return Object.assign({}, state, {
        isUpdatingSupervisorsModal: action.payload,
      });
    case loadingConstants.ADD_USER_MODAL:
      return Object.assign({}, state, {
        isUpdatingUser: action.payload,
      });
    case loadingConstants.LOADING_OVERLAY:
      return Object.assign({}, state, {
        loadingOverlay: action.payload,
      });
    case loadingConstants.PERMISSIONS_MODAL:
      return Object.assign({}, state, {
        isUpdatingPermissions: action.payload,
      });
    case loadingConstants.FETCH_TRAINEES_LOADING:
      return Object.assign({}, state, {
        isFetchingTrainees: action.payload,
      });
    case loadingConstants.MEETINGS_MODAL_LOADING:
      return Object.assign({}, state, {
        loadingMeetings: action.payload,
      });
    case loadingConstants.DASHBOARD_OPERATORS_TABLE:
      return Object.assign({}, state, {
        loadingDashboardOperators: action.payload,
      });
    default:
      return state;
  }
};
