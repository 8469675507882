import React, { useState, useEffect } from "react";
import CalendarComponent from "../components/Calendar";
import ShiftsModal from "./Modals/Operators And Trainees/ShiftsModal";
import { useSelector, useDispatch } from "react-redux";
import { getOperatorsShifts } from "../redux/actions/operatorsActions";
import moment from "moment";
import { fetchSettings } from "../redux/actions/settingsActions";
import CalendarLegend from "../components/CalendarLegend";
import Alert from "../components/Alert";
import { clearMessage } from "../redux/actions/operatorsActions";

function Operators(props) {
  const dispatch = useDispatch();
  const [date, setDate] = useState();
  const shifts = useSelector((state) => state.operators.shifts);
  const settings = useSelector((state) => state.settings.settings);

  const onDateClick = (date) => {
    //show modal
    let month = date.split("-")[1];
    let year = date.split("-")[0];
    setDate();
    setDate(date);
    dispatch(clearMessage());
    document.getElementById("operatorsButton").click();
    dispatch(getOperatorsShifts(month, year));
  };

  const onMonthChange = (month, year) => {
    dispatch(getOperatorsShifts(month, year));
  };

  useEffect(() => {
    const curr = moment();
    const currentMonth = curr.format("MM");
    const currentYear = curr.format("YYYY");
     if(!shifts) dispatch(getOperatorsShifts(currentMonth, currentYear));

    if (!settings) dispatch(fetchSettings());
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header page-title">
        <h1>Operators</h1>
      </section>
      <Alert />
      {settings && shifts && (
        <CalendarComponent
          onDateClick={onDateClick}
          shifts={shifts}
          onMonthChange={onMonthChange}
          maxPerDay={settings.operators_per_shift * settings.shits_per_day}
        >
          <CalendarLegend />
        </CalendarComponent>
      )}

      {/* Launch Modal button */}
      <button
        id="operatorsButton"
        className="btn btn-primary hidden"
        data-toggle="modal"
        data-target="#shiftsModal"
      >
        Launch Modal
      </button>
      {/* Shifts Modal */}
      {date && shifts && (
        <ShiftsModal selectedDate={date} userType="operator" />
      )}
    </div>
  );
}

export default Operators;
