import { settingsConstants, API_URL } from "../constants";
import { successAlert, errorAlert } from "./alertActions";
import { showLoadingOverlay } from "./loadingActions";
import Axios from "axios";

const fetchSettingsSuccess = (settings) => ({
  type: settingsConstants.FETCH_SETTINGS_SUCCESS,
  payload: settings,
});

const fetchSettingsFailure = () => ({
  type: settingsConstants.FETCH_SETTINGS_FAILURE,
});

const updateSettingsSuccess = (settings) => ({
  type: settingsConstants.UPDATE_SETTINGS_SUCCESS,
  payload: settings,
});

export const fetchSettings = () => {
  return function (dispatch) {
    dispatch(showLoadingOverlay(true));
    return Axios.get(API_URL + "/admin/get_setting")
      .then((response) => {
        if (response.data.status === "1") {
          let settings = response.data.setting[0]
          dispatch(fetchSettingsSuccess(settings));
        } else {
          dispatch(errorAlert(response.data.msg));
        }
        dispatch(showLoadingOverlay(false));
      })
      .catch((error) => {
        dispatch(showLoadingOverlay(false));
        dispatch(fetchSettingsFailure());
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const updateSettings = (updatedSettings) => {
  return function (dispatch) {
    dispatch(showLoadingOverlay(true));
    return Axios.post(API_URL + "/admin/update_setting", updatedSettings)
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(updateSettingsSuccess(response.data.setting));
          
          dispatch(successAlert("Settings updated successfully!"));
          // dispatch(deleteSessionsAfterCurrentDate())
          // dispatch(deleteSupervisorSessionsAfterCurrentDate())
        } else {
          dispatch(errorAlert(response.data.msg));
        }
        dispatch(showLoadingOverlay(false));
      })
      .catch((error) => {
        dispatch(showLoadingOverlay(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const sendPushAnnouncement = (data) => {
  return function (dispatch) {
    return Axios.post(API_URL + "/api/send_push", data)
      .then((response) => {
        dispatch(successAlert("Announcement sent successfully!"));
      })
      .catch((error) => dispatch(errorAlert("Something went wrong..")));
  };
};
