import React, { useState } from "react";
import { useSelector } from "react-redux";
import BoxLoading from "../BoxLoading";

function ChangePasswordForm(props) {
  const { updatePassword } = props;

  const loading = useSelector((state) => state.loading.isUpdatingPassword);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    let formdata = new FormData();
    formdata.append("old_password", oldPassword);
    formdata.append("new_password", newPassword);
    updatePassword(formdata);
    clear();
  };

  const clear = () => {
    setOldPassword("");
    setNewPassword("");
  };

  return (
    <div className="box box-success">
      <div className="box-header with-border">
        <h3 className="box-title">Change Password</h3>

        <div className="box-tools pull-right">
          <button
            type="button"
            className="btn btn-box-tool"
            data-widget="collapse"
          >
            <i className="fa fa-minus"></i>
          </button>
        </div>
      </div>
      <div className="box-body">
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label>Old Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Old Password"
              value={oldPassword}
              required
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>New Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="New Password"
              value={newPassword}
              required
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>

          <div className="box-footer">
            <div className="alignSelfRight">
              <input
                className="btn btn-dark-gray"
                value="Cancel"
                type="button"
                onClick={() => clear()}
              />
              <input
                className="btn btn-green mr-10"
                value="Save Password"
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
      {loading && <BoxLoading />}
    </div>
  );
}

export default ChangePasswordForm;
