export const API_URL = process.env.REACT_APP_API_URL;
//export const API_URL = 'http://embrace.local/index.php';

export const alertConstants = {
  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  CLEAR: "ALERT_CLEAR",
};

export const authUserConstants = {
  LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USER_LOGIN_FAILURE",
  LOGOUT: "USER_LOGOUT",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  FETCH_NOTIFICATIONS_SUCCESS: "FETCH_NOTIFICATIONS_SUCCESS",
};

export const dashboardConstants = {
  FETCH_OPERATORS_DATA_SUCCESS: "FETCH_OPERATORS_DATA_SUCCESS",
  FETCH_SUPERVISION_DATA_SUCCESS: "FETCH_SUPERVISION_DATA_SUCCESS",
  FETCH_SUPERVISORS_DATA_SUCCESS: "FETCH_SUPERVISORS_DATA_SUCCESS",
  FETCH_TRAINEES_DATA_SUCCESS: "FETCH_TRAINEES_DATA_SUCCESS",
};

export const usersConstants = {
  FETCH_USERS: "FETCH_USERS",
  FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
  FETCH_USERS_FAILURE: "FETCH_USERS_FAILURE",
  ADD_USER: "ADD_USER",
  DELETE_USER: "DELETE_USER",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_PERMISSIONS_SUCCESS: "UPDATE_USER_PERMISSIONS_SUCCESS",
  SHOW_ERROR_MESSAGE: "SHOW_ERROR_MESSAGE",
  CLEAR_ERROR_MESSAGE: "CLEAR_ERROR_MESSAGE",
  REMOVE_DELETED_USER: "REMOVE_DELETED_USER",
};

export const permissionsConstants = {
  FETCH_PERMISSIONS: "FETCH_PERMISSIONS",
  FETCH_PERMISSIONS_SUCCESS: "FETCH_PERMISSIONS_SUCCESS",
  FETCH_PERMISSIONS_FAILURE: "FETCH_PERMISSIONS_FAILURE",
};

export const settingsConstants = {
  FETCH_SETTINGS: "FETCH_SETTINGS",
  FETCH_SETTINGS_SUCCESS: "FETCH_SETTINGS_SUCCESS",
  FETCH_SETTINGS_FAILURE: "FETCH_SETTINGS_FAILURE",
  UPDATE_SETTINGS_SUCCESS: "UPDATE_SETTINGS_SUCCESS",
  SEND_PUSH_ANNOUNCEMENT_SUCCESS: "SEND_PUSH_ANNOUNCEMENT_SUCCESS",
};

export const traineeConstants = {
  FETCH_TRAINEES: "FETCH_TRAINEES",
  FETCH_TRAINEES_SUCCESS: "FETCH_TRAINEES_SUCCESS",
  FETCH_TRAINEES_FAILURE: "FETCH_TRAINEES_FAILURE",
  UPDATE_TRAINEE_APPROVAL: "UPDATE_TRAINEE_APPROVAL",
  FETCH_TRAINEE_SHIFTS_SUCCESS: "FETCH_TRAINEE_SHIFTS_SUCCESS",
  ADD_TRAINEE_SHIFT_SUCCESS: "ADD_SHIFT_TRAINEE_SUCCESS", //adds trainee
  DELETE_TRAINEE_SHIFT_SUCCESS: "DELETE_SHIFT_TRAINEE_SUCCESS", //remove trainee
  UPDATE_TRAINEE_SHIFT_SUCCESS: "UPDATE_SHIFT_TRAINEE_SUCCESS", //edit the trainee
  SHOW_ERROR_MESSAGE: "SHOW_ERROR_MESSAGE",
  CLEAR_ERROR_MESSAGE: "CLEAR_ERROR_MESSAGE",
};

export const operatorsConstants = {
  FETCH_OPERATORS: "FETCH_OPERATORS",
  FETCH_OPERATORS_SUCCESS: "FETCH_OPERATORS_SUCCESS",
  FETCH_OPERATORS_FAILURE: "FETCH_OPERATORS_FAILURE",
  FETCH_OPERATORS_SHIFTS_FAILURE: "FETCH_OPERATORS_SHIFTS_FAILURE",
  FETCH_OPERATORS_SHIFTS_SUCCESS: "FETCH_OPERATORS_SHIFTS_SUCCESS",
  DELETE_OPERATOR_SHIFT: "DELETE_OPERATOR_SHIFT",
  UPDATE_OPERATOR_SHIFT_SUCCESS: "UPDATE_OPERATOR_SHIFT_SUCCESS",
  ADD_OPERATOR_SHIFT_SUCCESS: "ADD_OPERATOR_SHIFT_SUCCESS",
  SHOW_ERROR_MESSAGE: "SHOW_ERROR_MESSAGE",
  CLEAR_ERROR_MESSAGE: "CLEAR_ERROR_MESSAGE",
  DELETE_OPERATOR_SHIFTS_BY_DATE: "DELETE_OPERATOR_SHIFTS_BY_DATE",
};

export const chiefConstants = {
  FETCH_CHIEF_OPERATOR: "FETCH_CHIEF_OPERATOR",
  FETCH_CHIEF_OPERATORS_SUCCESS: "FETCH_CHIEF_OPERATORS_SUCCESS",
  FETCH_CHIEF_OPERATORS_FAILURE: "FETCH_CHIEF_OPERATORS_FAILURE",
  FETCH_CHIEF_SHITFS_FAILURE: "FETCH_CHIEF_SHIFTS_FAILURE",
  FETCH_CHIEF_SHIFTS_SUCCESS: "FETCH_CHIEF_SHIFTS_SUCCESS",
  DELETE_CHIEF_SHIFT: "DELETE_CHIEF_SHIFT",
  ADD_CHIEF_SHIFT_SUCCESS: "ADD_CHIEF_SHIFT_SUCCESS",
  UPDATE_CHIEF_SHIFT_SUCCESS: "UPDATE_CHIEF_SHIFT_SUCCESS",
  SHOW_ERROR_MESSAGE: "SHOW_ERROR_MESSAGE",
  CLEAR_ERROR_MESSAGE: "CLEAR_ERROR_MESSAGE",
  DELETE_CHIEF_SHIFTS_BY_DATE: "DELETE_CHIEF_SHIFTS_BY_DATE",
};

export const supervisorsConstants = {
  FETCH_SUPERVISORS_SUCCESS: "FETCH_SUPERVISORS_SUCCESS",
  FETCH_SUPERVISORS_FAILURE: "FETCH_SUPERVISORS_FAILURE",
  FETCH_MEETINGS: "FETCH_MEETINGS",
  FETCH_MEETINGS_FAILURE: "FETCH_MEETINGS_FAILURE",
  FETCH_MEETINGS_SUCCESS: "FETCH_MEETINGS_SUCCESS",
  DELETE_MEETING: "DELETE_MEETING_SUCCESS",
  UPDATE_MEETING_SUCCESS: "UPDATE_MEETING_SUCCESS",
  MARK_MEETING_COMPLETE: "MARK_MEETING_COMPLETE",
  FETCH_SUPERVISOR_SHIFTS: "FETCH_SUPERVISOR_SHIFTS",
  DELETE_SUPERVISOR_SHIFT_SUCCESS: "DELETE_SUPERVISOR_SHIFT_SUCCESS",
  ADD_SUPERVISOR_SHIFT_SUCCESS: "ADD_SUPERVISOR_SHIFT_SUCCESS",
  UPDATE_SUPERVISOR_SHIFT_SUCCESS: "UPDATE_SUPERVISOR_SHIFT_SUCCESS",
  ADD_MEETING_ATTENDEE_SUCCESS: "ADD_MEETING_ATTENDEE_SUCCESS",
  UPDATE_MEETING_ATTENDEE_SUCCESS: "UPDATE_MEETING_ATTENDEE_SUCCESS",
  DELETE_MEETING_ATTENDEE: "DELETE_MEETING_ATTENDEE_SUCCESS",
  SHOW_ERROR_MESSAGE: "SHOW_ERROR_MESSAGE",
  CLEAR_ERROR_MESSAGE: "CLEAR_ERROR_MESSAGE",
  DELETE_SHIFTS_AND_MEETINGS_BY_DATE: "DELETE_SHIFTS_AND_MEETINGS_BY_DATE",
  REMOVE_DELETED_USER: "REMOVE_DELETED_USER_FROM_MEETINGS",
};

export const sessionsConstants = {
  FETCH_SESSIONS: "FETCH_SESSIONS",
  FETCH_SESSIONS_SUCCESS: "FETCH_SESSIONS_SUCCESS",
  FETCH_SESSIONS_FAILURE: "FETCH_SESSIONS_FAILURE",
  MARK_SESSION_COMPLETE: "MARK_SESSION_COMPLETE",
  UPDATE_SESSION_SUCCESS: "UPDATE_SESSION_SUCCESS",
  CREATE_SESSION: "CREATE_SESSION_SUCCESS",
  ADD_SESSION_ATTENDEE_SUCCESS: "ADD_SESSION_ATTENDEE_SUCCESS",
  UPDATE_SESSION_ATTENDEE_SUCCESS: "UPDATE_SESSION_ATTENDEE_SUCCESS",
  DELETE_SESSION_ATTENDEE_SUCCESS: "DELETE_SESSION_ATTENDEE_SUCCESS",
  DELETE_SESSION_BY_DATE: "DELETE_SESSION_BY_DATE",
  FETCH_SESSION_USERS: "FETCH_SESSION_USERS",
  REMOVE_DELETED_USER: "REMOVE_DELETED_USER_FROM_SESSIONS",
};

export const loadingConstants = {
  UPDATING_PROFILE: "UPDATING_PROFILE",
  UPDATING_EMAIL: "UPDATING_EMAIL",
  UPDATING_PASSWORD: "UPDATING_PASSWORD",
  OPERATORS_MODAL: "OPERATORS_MODAL",
  SUPERVISORS_MODAL: "SUPERVISORS_MODAL",
  ADD_USER_MODAL: "ADD_USER_MODAL",
  LOADING_OVERLAY: "LOADING_OVERLAY",
  FETCH_TRAINEES_LOADING: "FETCH_TRAINEES_LOADING",
  PERMISSIONS_MODAL: "PERMISSIONS_MODAL",
  MEETINGS_MODAL_LOADING: "MEETINGS_MODAL_LOADING",
  DASHBOARD_OPERATORS_TABLE: "DASHBOARD_OPERATORS_TABLE",
};
