import { supervisorsConstants } from "../constants";

const intialState = {
  isFetching: false,
  didInvalidate: false,
  meetings: null,
  shifts: null,
  errorMessage: null,
  supervisors: null,
  meetingsError: null,
};

const supervisorsReducer = (state = intialState, action) => {
  switch (action.type) {
    case supervisorsConstants.FETCH_SUPERVISORS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        supervisors: action.payload,
      });
    // MEETINGS
    case supervisorsConstants.FETCH_MEETINGS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case supervisorsConstants.FETCH_MEETINGS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
      });
    case supervisorsConstants.FETCH_MEETINGS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        meetings: action.payload,
      });
    case supervisorsConstants.MARK_MEETING_COMPLETE:
      return Object.assign({}, state, {
        meetings: state.meetings.map((item, index) => {
          if (item.id === action.payload) {
            return { ...item, status: 1 };
          } else {
            return item;
          }
        }),
      });
    case supervisorsConstants.UPDATE_MEETING_SUCCESS:
      console.log(action.payload);
      return Object.assign({}, state, {
        meetings: state.meetings.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          } else {
            return item;
          }
        }),
      });
    case supervisorsConstants.DELETE_MEETING:
      return Object.assign({}, state, {
        meetings: state.meetings.filter(
          (item, index) => item.id !== action.payload
        ),
      });
    /* MEETING ATTENDEES */
    case supervisorsConstants.ADD_MEETING_ATTENDEE_SUCCESS:
      return Object.assign({}, state, {
        meetings: state.meetings.map((item, index) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        }),
      });
    case supervisorsConstants.UPDATE_MEETING_ATTENDEE_SUCCESS:
      return Object.assign({}, state, {
        meetings: state.meetings.map((item, index) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        }),
      });
    case supervisorsConstants.DELETE_MEETING_ATTENDEE:
      return Object.assign({}, state, {
        meetings: state.meetings.map((item, index) => {
          if (item.id === action.payload.meetingId) {
            let attendees = item.attendees.filter(
              (attendee, index) => attendee.id !== action.payload.userMeetingId
            );
            return { ...item, attendees };
          }
          return item;
        }),
      });

    // SHIFTS
    case supervisorsConstants.FETCH_SUPERVISOR_SHIFTS:
      return Object.assign({}, state, {
        shifts: action.payload,
      });
    case supervisorsConstants.ADD_SUPERVISOR_SHIFT_SUCCESS:
      return Object.assign({}, state, {
        shifts: [...state.shifts, action.payload],
      });
    case supervisorsConstants.DELETE_SUPERVISOR_SHIFT_SUCCESS:
      return Object.assign({}, state, {
        shifts: state.shifts.filter(
          (item, index) => item.id !== action.payload
        ),
      });
    case supervisorsConstants.UPDATE_SUPERVISOR_SHIFT_SUCCESS:
      return Object.assign({}, state, {
        shifts: state.shifts.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload.data;
          } else {
            return item;
          }
        }),
      });
    case supervisorsConstants.DELETE_SHIFTS_AND_MEETINGS_BY_DATE:
      return Object.assign({}, state, {
        meetings: state.meetings
          ? state.meetings.filter(
              (item) => item.from_date.split(" ")[0] < action.payload
            )
          : null,
        shifts: state.shifts
          ? state.shifts.filter((item) => item.day < action.payload)
          : null,
      });
    case supervisorsConstants.SHOW_ERROR_MESSAGE:
      return Object.assign({}, state, {
        errorMessage: action.payload,
      });
    case supervisorsConstants.CLEAR_ERROR_MESSAGE:
      return Object.assign({}, state, {
        errorMessage: null,
      });
    case supervisorsConstants.REMOVE_DELETED_USER:
      return Object.assign({}, state, {
        supervisors: state.supervisors
          ? state.supervisors.filter((user) => user.id !== action.payload.id)
          : null,
        shifts: state.shifts
          ? state.shifts.filter(
              (shift) =>
                !(
                  shift.day > action.payload.date &&
                  shift.user.id === action.payload.id
                )
            )
          : null,
        meetings:
          state.meetings &&
          state.meetings.map((meeting) => {
            if (
              meeting.from_date.split(" ")[0] > action.payload.date &&
              meeting.attendees
            ) {
              let attendees = meeting.attendees.filter(
                (attendee) =>
                  Number(attendee.user_id) !== Number(action.payload.id)
              );
              return { ...meeting, attendees };
            } else {
              return meeting;
            }
          }),
      });
    default:
      return state;
  }
};

export default supervisorsReducer;
