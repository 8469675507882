import React from 'react'

function BoxLoading(props){
    return (
        <div className="overlay">
        <i className="fa fa-refresh fa-spin"></i>
      </div>
    )
}

export default BoxLoading