import React, { useEffect, useState } from "react";
import { formatDay } from "../../../components/FormattedDate";
import PlusIconForm from "../../../components/Shifts Components/PlusIconForm";
import { isEmpty } from "../../../helpers";
import {
  getSupervisorsShifts,
} from "../../../redux/actions/supervisorsActions";
import { useSelector, useDispatch } from "react-redux";
import {
  addOperatorShift,
  deleteOperatorShift,
  updateOperatorShift,
} from "../../../redux/actions/operatorsActions";
import {
  deleteTraineeShift,
  addTraineeShift,
  updateTraineeShift,
} from "../../../redux/actions/traineesActions";
import Notification from "../../../components/Shifts Components/Notification";
import BoxLoading from "../../../components/BoxLoading";
import OperatorsShifts from "./OperatorsShifts";
import TraineesShifts from "./TraineeShift";
import ChiefOperatorShifts from "./ChiefOperatorShifts";
import {
  addChiefShift,
  deleteChiefShift,
  updateChiefShift,
} from "../../../redux/actions/chiefsActions";
import moment from "moment";

function ShiftsModal(props) {
  const dispatch = useDispatch();
  const [day, setDay] = useState(null);
  const { selectedDate, userType } = props;
  const supervisors = useSelector((state) => state.supervisors.shifts);

  //get values from the state
  const setShifts = useSelector((state) => state.operators.shifts);
  const errorMessage = useSelector((state) =>
    userType === "operator"
      ? state.operators.errorMessage
      : state.trainees.errorMessage
  );

  const loading = useSelector(
    (state) => state.loading.isUpdatingOperatorsModal
  );

  const numOfOperatorsPerShift = useSelector(
    (state) => state.settings.settings.operators_per_shift
  );

  const [supervisor, setSupervisor] = useState('');

  useEffect(() => {
    if (!isEmpty(selectedDate)) {
      let month = selectedDate.split("-")[1];
      let year = selectedDate.split("-")[0];
      dispatch(getSupervisorsShifts(month, year));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(supervisors)) {
      for (let i = 0; i < supervisors.length; i++) {
        if (supervisors[i].day === selectedDate) {
          setSupervisor(supervisors[i].user.name);
        }
      }
    }
  }, [supervisors]);

  //format displayed date
  useEffect(() => {
    let day = formatDay(selectedDate);
    setDay(day);
  }, [selectedDate]);

  //update operator
  const onUpdateOperator = (userid, id, data) => {
    let formdata = new FormData();
    formdata.append("user_id", userid);
    formdata.append("id", id);

    if (userType === "operator") {
      formdata.append("from_date", data);
      dispatch(updateOperatorShift(formdata));
    } else if (userType === "chief") {
      formdata.append("from_date", data);
      formdata.append("day", moment(data).format('YYYY-MM-DD'));
      dispatch(updateChiefShift(formdata));
    } else {
      formdata.append("shift_id", data);
      dispatch(updateTraineeShift(formdata));
    }
  };

  //removes operator  from the shift
  const onDelete = (id) => {
    if (userType === "operator") dispatch(deleteOperatorShift(id));
    else if (userType === "chief") dispatch(deleteChiefShift(id));
    else dispatch(deleteTraineeShift(id));
  };

  //handles form submit in the PlusIconForm
  //add user to shift
  const addShift = (user, data) => {
    let formdata = new FormData();
    formdata.append("user_id", user);
    if (userType === "operator") {
      formdata.append("from_date", data);
      dispatch(addOperatorShift(formdata));
    } else if (userType === "chief") {
      formdata.append("from_date", data);
      formdata.append("day", moment(data).format("YYYY-MM-DD"));
      dispatch(addChiefShift(formdata));
    } else {
      formdata.append("shift_id", data);
      dispatch(addTraineeShift(formdata));
    }
  };

  //add user to shfit icon
  const renderAddShiftIcons = (length, data) => {
    if (userType === "chief") {
      return (
        <div className="shift-container mt-10" key={"plus-icon"}>
          <PlusIconForm onSubmit={addShift} data={data} userType={userType} />
        </div>
      );
    } else {
      let numberOfIcons = new Array(
        Number(numOfOperatorsPerShift) - length
      ).fill(null);

      return numberOfIcons.map((item, index) => {
        return (
          <div className="shift-container mt-10" key={"plus-icon-" + index}>
            <PlusIconForm onSubmit={addShift} data={data} userType={userType} />
          </div>
        );
      });
    }
  };

  const renderContent = () => {
    switch (userType) {
      case "chief":
        return (
          <ChiefOperatorShifts
            shifts={setShifts}
            renderAddShiftIcons={renderAddShiftIcons}
            onDelete={onDelete}
            selectedDate={selectedDate}
            onUpdateOperator={onUpdateOperator}
          />
        );
      case "operator":
        return (
          <OperatorsShifts
            shifts={setShifts}
            renderAddShiftIcons={renderAddShiftIcons}
            onDelete={onDelete}
            selectedDate={selectedDate}
            onUpdateOperator={onUpdateOperator}
          />
        );

      default:
        return (
          <TraineesShifts
            shifts={setShifts}
            renderAddShiftIcons={renderAddShiftIcons}
            onDelete={onDelete}
            onUpdateOperator={onUpdateOperator}
            selectedDate={selectedDate}
          />
        );
    }
  };

  return (
    <div
      className="modal fade"
      id="shiftsModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content box">
          <div className="modal-header">
            <h4 className="modal-title">{day}</h4>
            <h5>{supervisor}</h5>
          </div>
          <div className="modal-body">
            {errorMessage && (
              <Notification message={errorMessage} type="danger" />
            )}

            {(setShifts || userType === "chief") && renderContent()}
          </div>
          <div className="modal-footer">
            <button className="btn btn-green" data-dismiss="modal">
              Close
            </button>
          </div>
          {loading && <BoxLoading />}
        </div>
      </div>
    </div>
  );
}

export default ShiftsModal;
