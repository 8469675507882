import React, { useEffect, useState } from "react";
import BoxLoading from "../BoxLoading";

function TraineesList(props) {
  const { trainees, handleTraineeState, isLoading } = props;
  const [finishedTraining, setFinishedTraining] = useState(null);
  const [traineesInProgress, setTraineesInProgress] = useState(null);

  //handles approve/decline trainee button
  const handleTrainee = (id, status) => {
    const userId = new FormData();
    userId.append("id", id);
    handleTraineeState(userId, status);
  };

  useEffect(() => {
    let finishedTraining = [];
    let pendingTraining = [];
    if (trainees) {
      trainees.forEach((item) => {
        if (
          Number(item.train.completed_hours) >= Number(item.user.hours_required)
        ) {
          //training is complete
          finishedTraining.push(item);
        } else {
          pendingTraining.push(item);
        }
      });
      //update the state
      setFinishedTraining(finishedTraining);
      setTraineesInProgress(pendingTraining);
    }
  }, [trainees]);

  const renderFinishedTraining = () => {
    return finishedTraining.map((item, index) => {
      return (
        <tr key={"complete-training-" + index}>
          <td>{item.user.name}</td>
          <td>
            {item.train.completed_hours} / {item.user.hours_required}
          </td>
          <td>
            <button
              className="btn icon-button complete-icon  mr-1"
              onClick={() => handleTrainee(item.user.id, true)}
            >
              <i className="fa fa-check"></i> Approve
            </button>
          </td>
          <td>
            <button
              className="btn icon-button delete-icon"
              onClick={() => handleTrainee(item.user.id, false)}
            >
              <i className="fa fa-close"></i> Decline
            </button>
          </td>
        </tr>
      );
    });
  };

  const renderTrainingInProgress = () => {
    return traineesInProgress.map((item, index) => {
      return (
        <tr key={"pending-training-" + index}>
          <td>{item.user.name}</td>
          <td>
            {item.train.completed_hours} / {item.user.hours_required}
          </td>
        </tr>
      );
    });
  };

  const emptyTable = (colspan) => {
    return (
      <tr>
        <td colSpan={colspan}>
          <center>No Trainees</center>
        </td>
      </tr>
    );
  };

  return (
    <div
      className="modal fade"
      id="operatorTraineeModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="operatorTraineeTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content box">
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalLongTitle">
              List of Trainees
            </h4>
          </div>
          <div className="modal-body">
            <div className="nav-tabs-custom">
              <ul className="nav nav-tabs">
                <li className="active">
                  <a href="#tab_1" data-toggle="tab">
                    <b>Complete Training</b>
                  </a>
                </li>
                <li>
                  <a href="#tab_2" data-toggle="tab">
                    <b>Training in Progress</b>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane active" id="tab_1">
                  <table className="table bg-white users-table">
                    <tbody>
                      <tr>
                        <th>Trainee</th>
                        <th>Hours Completed</th>
                        <th></th>
                        <th></th>
                      </tr>
                      {finishedTraining && finishedTraining.length > 0
                        ? renderFinishedTraining()
                        : emptyTable(4)}
                    </tbody>
                  </table>
                </div>
                <div className="tab-pane" id="tab_2">
                  <table className="table bg-white users-table">
                    <tbody>
                      <tr>
                        <th>Trainee</th>
                        <th>Hours Completed</th>
                      </tr>
                      {traineesInProgress && traineesInProgress.length > 0
                        ? renderTrainingInProgress()
                        : emptyTable(2)}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-green"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
          {isLoading && <BoxLoading />}
        </div>
      </div>
    </div>
  );
}

export default TraineesList;
