import React, { useState, useEffect, Fragment } from "react";
import "../../css/stylesheet.css";
import * as $ from "jquery";
import select2 from "select2";
import BoxLoading from "../BoxLoading";
import Notification from "../Shifts Components/Notification";
import { getBase64, sort } from "../../helpers";

const selectUserPermissions = (userRoles) => {
  let idsArray = [];
  for (let index = 0; index < userRoles.length; index++) {
    idsArray.push(userRoles[index].id);
  }
  $("#permissions-select").val(idsArray);
};

const renderPermissions = (permissions) => {
  let sortedPermissions = sort(permissions, "name");

  return sortedPermissions.map((item, index) => {
    return (
      <option key={"permission-" + item.id} value={item.id}>
        {item.name}
      </option>
    );
  });
};

function AddUserForm(props) {
  const {
    permissions,
    userInfo,
    title,
    onSubmit,
    loading,
    error,
    resetPassword,
    removePicture,
  } = props;
  const [errorMessage, hasError] = useState("");
  const message1 =
    "A user can't be an operator and an operator trianee at the same time.";
  const message2 =
    "A user can't be an awareness officer and awareness trianee at the same time.";
  const [gender, setGender] = useState("Male");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [date, setDate] = useState("");
  const [password, setPassword] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [hoursRequired, setHoursRequired] = useState(0);
  const [monthlyShiftsRequired, setMonthlyShiftsRequired] = useState(0);
  const [isTrainee, setIsTrainee] = useState(false);
  const [isOperator, setIsOperator] = useState(false);

  useEffect(() => {
    //clear the form when the modal opens
    document.getElementById("modalButton").addEventListener("click", reset);
    document
      .getElementById("editModalButton")
      .addEventListener("click", function () {
        hasError("");
      });

    //fill the form with the user data if userInfo is set => edit mode
    if (userInfo) {
      setGender(userInfo.gender);
      setId(userInfo.sub_id);
      setName(userInfo.name);
      setEmail(userInfo.email);
      setPhone(userInfo.phone);
      setAge(userInfo.age);
      setDate(userInfo.start_date.split(" ")[0]); //get date without time
      selectUserPermissions(userInfo.roles);
      setHoursRequired(userInfo.hours_required);
      setMonthlyShiftsRequired(userInfo.hours_required_per_month);

      userInfo.roles.map((role) => {
        if (role.id === "3") setIsTrainee(true);
        if (role.id === "2") setIsOperator(true);
      });
    }

    //Initialize Select2 Elements
    $(".select2").select2();
    $(".select2bs4").select2({
      theme: "bootstrap4",
    });

    return function cleanup() {
      reset();
    };
  }, [userInfo]);

  //handles form submit
  const handleSubmit = (event) => {
    event.preventDefault();

    //get selected permissions
    const selectedPermissions = $("#permissions-select").select2("data");

    let operatorError = selectedPermissions.filter(
      (shift) => shift.id === "2" || shift.id === "3"
    );

    let officerError = selectedPermissions.filter(
      (shift) => shift.id === "5" || shift.id === "6"
    );

    if (operatorError.length >= 2) {
      hasError(message1);
    } else if (officerError.length >= 2) {
      hasError(message2);
    } else {
      //format the permissions for the backend
      let permissions_string = "";
      for (let index = 0; index < selectedPermissions.length; index++) {
        let current = selectedPermissions[index];
        index === selectedPermissions.length - 1
          ? (permissions_string += current.id)
          : (permissions_string += current.id + ",");
      }

      var userData = new FormData();
      if (userInfo) userData.append("id", userInfo.id);
      userData.append("email", email);
      userData.append("password", password);
      userData.append("sub_id", id);
      userData.append("name", name);
      userData.append("phone", phone);
      userData.append("age", age);
      userData.append("gender", gender);
      userData.append("start_date", date);
      userData.append("roles", permissions_string);
      userData.append("hours_required", hoursRequired);
      userData.append("shifts_required_per_month", monthlyShiftsRequired);

      getBase64(profilePicture)
        .then((file) => {
          userData.append("avatar", file);
          onSubmit(userData);
        })
        .catch((err) => {
          userData.append("avatar", null);
          onSubmit(userData);
        });
    }
  };

  //reset form
  const reset = () => {
    setId("");
    setName("");
    setEmail("");
    setPhone("");
    setPassword("");
    setAge("");
    setDate("");
    setGender("Male");
    $("#permissions-select").val([]).change();
    hasError("");
    setProfilePicture(null);
    setMonthlyShiftsRequired(0);
    setHoursRequired(0);
  };

  useEffect(() => {
    $("#permissions-select").on("change", function (e) {
      // Do something
      const selectedPermissions = $("#permissions-select").select2("data");

      let check = selectedPermissions.filter((shift) => shift.id === "3");
      if (check.length > 0) setIsTrainee(true);
      else {
        setIsTrainee(false);
        setHoursRequired(0);
      }

      //check operator
      check = selectedPermissions.filter((shift) => shift.id === "2");
      if (check.length > 0) setIsOperator(true);
      else {
        setIsOperator(false);
        setMonthlyShiftsRequired(0);
      }
    });
  }, []);

  return (
    <div className="modal fade" id="FormModal" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content box">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
          </div>

          {/* user form  */}
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              {error && <Notification message={error} type="danger" />}
              {errorMessage.length > 0 && (
                <Notification message={errorMessage} type="danger" />
              )}
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Id</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Id"
                    value={id}
                    required
                    min={1}
                    onChange={(e) => setId(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                    maxLength={40}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    maxLength={100}
                  />
                </div>
              </div>
              {!userInfo && (
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      required
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
              )}
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Number"
                    value={phone}
                    required
                    onChange={(e) => setPhone(e.target.value)}
                    min={0}
                    maxLength={15}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Permissions</label>
                  <select
                    className="select2"
                    id="permissions-select"
                    multiple="multiple"
                    style={{ width: "100%" }}
                    required
                  >
                    {renderPermissions(permissions)}
                  </select>
                </div>
              </div>

              {isTrainee && (
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Hours Required</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="# of Hours"
                      value={hoursRequired}
                      required
                      onChange={(e) => setHoursRequired(e.target.value)}
                      min={0}
                      maxLength={15}
                    />
                  </div>
                </div>
              )}
              {isOperator && (
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Monthly Shifts Required</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="# of Shifts"
                      value={monthlyShiftsRequired}
                      required
                      onChange={(e) => setMonthlyShiftsRequired(e.target.value)}
                      min={0}
                      maxLength={15}
                    />
                  </div>
                </div>
              )}
              <div className="col-sm-12">
                <div className="form-group">
                  <label>
                    {userInfo ? "Edit Profile Picture" : "Profile Picture"}
                  </label>
                  <input
                    type="file"
                    className="custom-file-input"
                    id="profilePictureInput"
                    style={{ display: "none " }}
                    onChange={(e) => setProfilePicture(e.target.files[0])}
                  />
                  <div
                    className="input-group input-group"
                    onClick={() => {
                      document.getElementById("profilePictureInput").click();
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={
                        profilePicture
                          ? profilePicture.name
                          : userInfo
                          ? userInfo.avatar
                          : ""
                      }
                    />
                    <span className="input-group-btn">
                      <button type="button" className="btn uploadBtn">
                        Upload File
                      </button>
                    </span>
                  </div>
                  {userInfo && userInfo.avatar && (
                    <small
                      onClick={() => removePicture(userInfo.id)}
                      className="text-danger mt-1 mb-2"
                      style={{ cursor: "pointer" }}
                    >
                      Remove Picture
                    </small>
                  )}
                </div>
              </div>
              <div className="row remove--horizontal-margin">
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Sex</label>
                    <select
                      className="form-control"
                      style={{ width: "100%" }}
                      required
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="Female">Female</option>
                      <option value="Inter">Intersex</option>
                      <option value="Male">Male</option>
                      <option value="Prefer">Prefer not to say</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-4 p-0">
                  <div className="form-group">
                    <label>Age</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Age"
                      value={age}
                      min={0}
                      max={90}
                      required
                      onChange={(e) => setAge(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Date"
                      value={date}
                      required
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {userInfo && (
                <Fragment>
                  <div className="text-right ptr-15">
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={(e) => resetPassword(userInfo.id)}
                    >
                      Reset Password
                    </button>
                  </div>
                  <div className="text-right pr-15">
                    <small>Reset the user's password to 123456</small>
                  </div>
                </Fragment>
              )}
            </div>
            <div className="modal-footer">
              <input type="submit" className="btn btn-green" value="Submit" />
              <button
                type="button"
                className="btn btn-dark-gray"
                data-dismiss="modal"
                id="user-modal-close-btn"
              >
                Cancel
              </button>
            </div>
          </form>
          {/* end of form  */}
          {loading && <BoxLoading />}
        </div>
      </div>
    </div>
  );
}

export default AddUserForm;
