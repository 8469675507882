import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearMessage } from "../../redux/actions/operatorsActions";

function Notification(props) {
  const { message, type } = props;
  const dispatch = useDispatch()

  useEffect(() => {
    if (message)
      setTimeout(() => {
        dispatch(clearMessage());
      }, 5000);
  }, [message, dispatch]);

  return (
    <div className={"alert alert-" + type} role="alert">
      {message}
    </div>
  );
}

export default Notification;
