import { traineeConstants, API_URL, usersConstants } from "../constants";
import Axios from "axios";
import { errorAlert } from "./alertActions";
import {
  updatingOperatorsModal,
  fetchTraineesLoading,
  showLoadingOverlay,
} from "./loadingActions";
import { deleteUserSuccess, getUserById } from "./usersActions";
import { fetchOperators } from "./operatorsActions";
import moment from "moment";

const fetchTraineesSuccess = (trainees) => ({
  type: traineeConstants.FETCH_TRAINEES_SUCCESS,
  payload: trainees,
});

const fetchTraineesFailure = () => ({
  type: traineeConstants.FETCH_TRAINEES_FAILURE,
});

const handleTraineeApproval = (date, traineeId) => ({
  type: traineeConstants.UPDATE_TRAINEE_APPROVAL,
  payload: { id: traineeId, date: date },
});

const fetchTraineeShiftsSuccess = (shifts) => ({
  type: traineeConstants.FETCH_TRAINEE_SHIFTS_SUCCESS,
  payload: shifts,
});

const deleteTraineeShiftSuccess = (shiftId) => ({
  type: traineeConstants.DELETE_TRAINEE_SHIFT_SUCCESS,
  payload: shiftId,
});

const addTraineeShiftSuccess = (shiftId) => ({
  type: traineeConstants.ADD_TRAINEE_SHIFT_SUCCESS,
  payload: shiftId,
});

const updateShiftSuccess = (id, shift) => ({
  type: traineeConstants.UPDATE_TRAINEE_SHIFT_SUCCESS,
  payload: { id: id, shift: shift },
});

const traineeShiftError = (message) => ({
  type: traineeConstants.SHOW_ERROR_MESSAGE,
  payload: message,
});

const clearErrorMessage = () => ({
  type: traineeConstants.CLEAR_ERROR_MESSAGE,
});

export const removeUserFromTrainees = (userid, date) => {
  return function (dispatch) {
    dispatch({
      type: usersConstants.REMOVE_DELETED_USER,
      payload: { id: userid, date: date },
    });
  };
};

export const clearMessage = () => {
  return function (dispatch) {
    dispatch(clearErrorMessage());
  };
};

export const fetchTrainees = () => {
  return function (dispatch) {
    dispatch(fetchTraineesLoading(true));
    return Axios.get(
      API_URL +
        "/operator_trainee/get_user_list_with_train_data?start=-1&rows=-1"
    )
      .then((response) => {
        if (response.data.status === "1")
          dispatch(fetchTraineesSuccess(response.data.data));
        else if (
          response.data.status === "0" &&
          response.data.msg === "No Users"
        )
          dispatch(fetchTraineesSuccess([]));
        dispatch(fetchTraineesLoading(false));
      })
      .catch((error) => {
        dispatch(fetchTraineesLoading(false));
        dispatch(fetchTraineesFailure());
        dispatch(errorAlert("Something went wrong..."));
      });
  };
};

export const approveTrainee = (userId) => {
  return function (dispatch) {
    return Axios.post(API_URL + "/operator_trainee/approve_trainee", userId)
      .then((response) => {
        const currentDate = moment();
        const formatDate = currentDate.format("YYYY-MM-DD");
        if (response.data.status === "1") {
          dispatch(handleTraineeApproval(formatDate, userId.get("id")));
          dispatch(fetchOperators());
          dispatch(getUserById(userId.get("id")));
        } else dispatch(errorAlert("Something went wrong..."));
      })
      .catch((error) => dispatch(errorAlert("Something went wrong...")));
  };
};

export const rejectTrainee = (userId) => {
  return function (dispatch) {
    return Axios.post(API_URL + "/operator_trainee/reject_trainee", userId)
      .then((response) => {
        const currentDate = moment();
        const formatDate = currentDate.format("YYYY-MM-DD");
        if (response.data.status === "1") {
          dispatch(handleTraineeApproval(formatDate, userId.get("id")));
          dispatch(deleteUserSuccess(userId.get("id")));
        } else {
          dispatch(errorAlert("Something went wrong.."));
        }
      })
      .catch((error) => dispatch(errorAlert("Something went wrong...")));
  };
};

export const getTraineeShifts = (month, year) => {
  return function (dispatch) {
    dispatch(showLoadingOverlay(true));
    const date = "year=" + year + "&month=" + month;

    return Axios.get(
      API_URL + "/operator_trainee/get_shifts_for_year_month?" + date
    )
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(fetchTraineeShiftsSuccess(response.data.shifts));
        } else if (
          response.data.status === "0" &&
          response.data.msg === "No Shifts"
        ) {
          dispatch(fetchTraineeShiftsSuccess([]));
        } else dispatch(traineeShiftError(response.data.msg));
        dispatch(showLoadingOverlay(false));
      })
      .catch((error) => {
        dispatch(showLoadingOverlay(false));
        dispatch(errorAlert("Something went wrong..."));
      });
  };
};

export const addTraineeShift = (data) => {
  return function (dispatch) {
    dispatch(clearErrorMessage())
    dispatch(updatingOperatorsModal(true));
    return Axios.post(API_URL + "/operator_trainee/add_shift", data)
      .then((response) => {
        if (response.data.status === "1")
          dispatch(addTraineeShiftSuccess(response.data.shift));
        else dispatch(traineeShiftError(response.data.msg));
        dispatch(updatingOperatorsModal(false));
      })
      .catch((error) => {
        dispatch(updatingOperatorsModal(false));
        dispatch(errorAlert("Something went wrong..."));
      });
  };
};

export const updateTraineeShift = (data) => {
  return function (dispatch) {
    dispatch(clearErrorMessage())
    dispatch(updatingOperatorsModal(true));
    return Axios.post(API_URL + "/operator_trainee/edit_shift", data)
      .then((response) => {
        if (response.data.status === "1") {
          dispatch(updateShiftSuccess(data.get("id"), response.data.shift));
        } else dispatch(traineeShiftError(response.data.msg));
        dispatch(updatingOperatorsModal(false));
      })
      .catch((error) => {
        dispatch(updatingOperatorsModal(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};
export const deleteTraineeShift = (shiftId) => {
  return function (dispatch) {
    dispatch(clearErrorMessage())
    dispatch(updatingOperatorsModal(true));
    return Axios.post(API_URL + "/operator_trainee/delete_shift", shiftId)
      .then((response) => {
        if (response.data.status === "1")
          dispatch(deleteTraineeShiftSuccess(shiftId.get("id")));
        else dispatch(traineeShiftError(response.data.msg));
        dispatch(updatingOperatorsModal(false));
      })
      .catch((error) => {
        dispatch(updatingOperatorsModal(false));
        dispatch(errorAlert("Something went wrong..."));
      });
  };
};
