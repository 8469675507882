import React from "react";

function ChiefSettings(props) {
  const { chiefPerDay, setChiefPerDay } = props;
  return (
    <div className="box box-success">
      <div className="box-header with-border">
        <h3 className="box-title">Chief Operator</h3>

        <div className="box-tools pull-right">
          <button type="button" className="btn btn-box-tool" data-widget="collapse">
            <i className="fa fa-minus"></i>
          </button>
        </div>
      </div>
      <div className="box-body">
        <div className="form-horizontal form-group ml-20">
          <label># of Chief Operators / Day</label>
          <input
            type="number"
            className="form-control"
            defaultValue={chiefPerDay}
            onChange={(e) => setChiefPerDay(e.target.value)}
            required
            min={0}
          />
        </div>
      </div>
    </div>
  );
}

export default ChiefSettings;
