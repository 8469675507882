import React, { useState, Fragment, useEffect } from "react";
import "../../css/stylesheet.css";
import ActionButtons from "./ActionButtons";
import MeetingAttendee from "./MeetingAttendee";
import PlusIconForm from "../Shifts Components/PlusIconForm";
import moment from "moment";
import { getUTCDate, getLocalTime } from "../../helpers";

function OneOnOneMeeting(props) {
  const {
    item,
    meetingIndex,
    onDeleteAttendee,
    onEditAttendee,
    onDeleteMeeting,
    markComplete,
    onUpdate,
    onAddAttendee,
  } = props;
  const [editMode, toggleEditMode] = useState(false);
  const [location, setLocation] = useState(item.location);

  useEffect(() => {
    //close operators
    document
      .getElementById("meetings-modal-button")
      .addEventListener("click", () => {
        let elements = document.getElementsByClassName("removeBox");
        for (let i = 0; i < elements.length; i++) {
          if (!elements[i].classList.contains("collapsed-box"))
            elements[i].classList.add("collapsed-box");
        }

        let boxBody = document.getElementsByClassName("attendeesBody");
        for (let i = 0; i < elements.length; i++) {
          if (!(boxBody[i].style.display === "none"))
            boxBody[i].style.display = "none";
        }
      });
  }, []);

  const updateMeeting = (e) => {
    e.preventDefault();
    let updatedMeeting = new FormData();
    updatedMeeting.append("id", item.id);
    updatedMeeting.append("user_id", item.user_id);
    updatedMeeting.append("location", location);

    onUpdate(updatedMeeting);
    toggleEditMode(false);
  };

  const onCancel = (e) => {
    e.preventDefault();
    setLocation(item.location);
    toggleEditMode(false);
  };

  const handleAddAttendee = (user, date) => {
    const data = new FormData();
    data.append("meeting_id", item.id);
    let utcdate = moment(date).utc().format("YYYY-MM-DD HH:mm:ss");
    data.append("date", utcdate);
    data.append("user_id", user);

    onAddAttendee(data);
  };

  const getAttendee = (date) => {
    if (item.attendees) {
      let utcDate = getUTCDate(date, "YYYY-MM-DD HH:mm:ss");

      let hasAttendee = item.attendees.filter(
        (attendee, index) => attendee.date === utcDate
      );

      if (hasAttendee.length > 0) {
        let attendee = hasAttendee[0];
        return (
          <div className="ml-10">
            <MeetingAttendee
              attendee={attendee}
              meetingId={attendee.meeting_id}
              user={item.user}
              onDeleteAttendee={onDeleteAttendee}
              onEditAttendee={onEditAttendee}
            />
          </div>
        );
      } else
        return (
          <PlusIconForm
            onSubmit={handleAddAttendee}
            data={date}
            userType="meeting"
          />
        );
    } else {
      return (
        <PlusIconForm
          onSubmit={handleAddAttendee}
          data={date}
          userType="meeting"
        />
      );
    }
  };

  const editMeetingForm = () => {
    return (
      <form onSubmit={updateMeeting}>
        <div className="flex">
          <div className=" form-group pl-15 flexGrow">
            <label>Location</label>
            <input
              type="text"
              className="form-control"
              id={"1on1-meeting-location-" + meetingIndex}
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
            <div className="text-right">
              <button
                type="submit"
                className="btn btn-green one-on-one-cancel-edit-btn mt-20 mr-10"
              >
                Save Changes
              </button>
              <button
                className="btn btn-dark-gray mt-20"
                onClick={(e) => onCancel(e)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  };

  const renderShifts = () => {
    let localstart = moment.utc(item.from_date).toDate();
    const start = moment(localstart);

    let localend = moment.utc(item.to_date).toDate();
    const end = moment(localend);

    const duration = item.duration;

    let shiftTime = start;
    let shifts = [];
    while (shiftTime < end) {
      let toDate = moment(shiftTime).add(duration, "minutes");
      shifts.push({
        title: `${shiftTime.format("hh:mm A")} - ${toDate.format("hh:mm A")} `,
        date: shiftTime.format("YYYY-MM-DD HH:mm:ss"),
      });
      shiftTime = toDate;
    }
    return shifts;
  };

  return (
    <Fragment>
      {editMode && editMeetingForm()}
      <div className="row">
        <div className="col-sm-6">
          {/* render an input in edit mode  */}
          {!editMode && (
            <Fragment>
              <div className="pl-15">
                <label className="title">
                  Date:{" "}
                  <span className="span-data">
                    {getLocalTime(item.from_date, "dddd MMM DD, YYYY")}
                  </span>
                </label>
              </div>
              <div className="pl-15">
                <label className="title">
                  Time:{" "}
                  <span className="span-data">
                    {getLocalTime(item.from_date, "hh:mm A")} - {" "}
                    {getLocalTime(item.to_date, "hh:mm A")}
                  </span>
                </label>
              </div>

              <div className="pl-15">
                <label className="title">
                  Duration / Meet:{" "}
                  <span className="span-data">{item.duration} minutes</span>
                </label>
              </div>
              <div className="pl-15">
                <label className="title">
                  Location: <span className="span-data"> {location}</span>
                </label>
              </div>

              <div className="pl-15">
                <label className="title">
                  Moderator: <span className="span-data">{item.user.name}</span>
                </label>
              </div>

              <div className="pl-15">
                <label className="title">
                  Type: <span className="span-data">Individual</span>
                </label>
              </div>
            </Fragment>
          )}
        </div>

        <div className="col-sm-6 text-right">
          {/* Add, Delete, Complete, Save and Cancel Buttons */}
          {item.status === "0" ? (
            !editMode && (
              <ActionButtons
                editMode={editMode}
                toggleEditMode={toggleEditMode}
                onDeleteMeeting={onDeleteMeeting}
                markComplete={markComplete}
                meetingId={item.id}
              />
            )
          ) : (
            <p className="btn-green page-link green-border">Complete</p>
          )}
        </div>
      </div>
      {!editMode && (
        <div className="pl-15">
          <div className="box removeBox collapsed-box">
            <div className="box-header">
              <label className="title">Operators </label>
              <button
                type="button"
                className="btn btn-box-tool"
                data-widget="collapse"
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
            <div
              className="box-body attendeesBody p-0"
              style={{ display: "none" }}
            >
              <ul>
                {renderShifts().map((shift, index) => (
                  <div className="flex " key={"1on1-meeting-attendee-" + index}>
                    <li className="title ">{shift.title}</li>
                    <span className="pb-5 flexGrow">
                      {getAttendee(shift.date)}
                    </span>
                    <hr />
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default OneOnOneMeeting;
