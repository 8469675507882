import { operatorsConstants, usersConstants } from "../constants";

const initialState = {
  isFetching: false,
  didInvalidate: false,
  shifts: null,
  errorMessage: null,
  operators: null,
};

const operatorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case operatorsConstants.FETCH_OPERATORS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case operatorsConstants.FETCH_OPERATORS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        operators: action.payload,
      });
    case operatorsConstants.FETCH_OPERATORS_SHIFTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
      });
    
    case operatorsConstants.FETCH_OPERATORS_SHIFTS_SUCCESS:
      return Object.assign({}, state, {
        shifts: action.payload,
      });

    case operatorsConstants.ADD_OPERATOR_SHIFT_SUCCESS:
      return Object.assign({}, state, {
        shifts: [...state.shifts, action.payload],
      });

    case operatorsConstants.UPDATE_OPERATOR_SHIFT_SUCCESS:
      return Object.assign({}, state, {
        shifts: state.shifts.map((shift) => {
          if (shift.id === action.payload.id) return action.payload.shift;
          else return shift;
        }),
      });
    case operatorsConstants.DELETE_OPERATOR_SHIFT:
      return Object.assign({}, state, {
        shifts: state.shifts.filter(
          (item, index) => item.id !== action.payload
        ),
      });
    case operatorsConstants.SHOW_ERROR_MESSAGE:
      return Object.assign({}, state, {
        errorMessage: action.payload,
      });
    case operatorsConstants.CLEAR_ERROR_MESSAGE:
      return Object.assign({}, state, {
        errorMessage: null,
      });
    case operatorsConstants.DELETE_OPERATOR_SHIFTS_BY_DATE:
      return Object.assign({}, state, {
        shifts: state.shifts.filter(
          (item) => item.from_date.split(" ")[0] < action.payload
        ),
      });

    case usersConstants.REMOVE_DELETED_USER:
      return Object.assign({}, state, {
        operators: state.operators
          ? state.operators.filter((user) => user.id !== action.payload.id)
          : null,
          shifts: state.shifts ? state.shifts.filter(shift => !(shift.from_date.split(" ")[0] > action.payload.date && shift.user_id === action.payload.id) ) : null
      });

    default:
      return state;
  }
};

export default operatorsReducer;
