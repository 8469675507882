import { authUserConstants, API_URL } from "../constants";
import Axios from "axios";
import { errorAlert, successAlert } from "./alertActions";
import {
  updatingEmail,
  updatingPassword,
  updatingProfile,
} from "./loadingActions";

const validateCredentials = () => {
  return { type: authUserConstants.LOGIN_REQUEST };
};

const loginSuccess = (user) => ({
  type: authUserConstants.LOGIN_SUCCESS,
  payload: { user: user },
});

const loginFailure = () => {
  return { type: authUserConstants.LOGIN_FAILURE };
};

const logoutSuccess = () => {
  return { type: authUserConstants.LOGOUT };
};

const updateProfileSuccess = (user) => ({
  type: authUserConstants.UPDATE_PROFILE_SUCCESS,
  payload: user,
});

const updateUserLocalStorage = (user) => {
  localStorage.setItem("embrace-admin-user", JSON.stringify(user));
};

const fetchNotificationsSuccess = (data) => ({
  type: authUserConstants.FETCH_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const setAuthorizationToken = (token) => {
  if (token) Axios.defaults.headers.common["Authorization"] = token;
  else delete Axios.defaults.headers.common["Authorization"];
};

export const login = (data, rememberMe) => {
  return function (dispatch) {
    dispatch(validateCredentials());
    return Axios.post(API_URL + "/admin/action_login", data)
      .then((response) => {
        if (response.data.status === "1") {
          //set token for all axios requests
          setAuthorizationToken(response.data.token);

          if (rememberMe) {
            localStorage.setItem(
              "embrace-admin-token",
              JSON.stringify(response.data.token)
            );
            localStorage.setItem(
              "embrace-admin-user",
              JSON.stringify(response.data.user)
            );
          } else {
            if (localStorage.getItem("embrace-admin-token")) {
              localStorage.removeItem("embrace-admin-token");
              localStorage.removeItem("embrace-admin-user");
            }
            sessionStorage.setItem(
              "embrace-admin-token",
              JSON.stringify(response.data.token)
            );
            sessionStorage.setItem(
              "embrace-admin-user",
              JSON.stringify(response.data.user)
            );
          }

          dispatch(loginSuccess(response.data.user));
        } else {
          dispatch(loginFailure());
          dispatch(
            errorAlert("The email or password you entered is incorrect. ")
          );
        }
      })
      .catch((error) => dispatch(errorAlert("Something went wrong..")));
  };
};

export const logoutUser = () => {
  return function (dispatch) {
    setAuthorizationToken(null);
    if (localStorage.getItem("embrace-admin-token")) {
      localStorage.removeItem("embrace-admin-token");
      localStorage.removeItem("embrace-admin-user");
    } else {
      sessionStorage.removeItem("embrace-admin-token");
      sessionStorage.removeItem("embrace-admin-user");
    }
    dispatch(logoutSuccess());
  };
};

export const updateUserEmail = (data) => {
  return function (dispatch) {
    dispatch(updatingEmail(true));
    return Axios.post(API_URL + "/api/change_user_email", data)
      .then((response) => {
        dispatch(updatingEmail(false));
        if (response.data.status === "1") {
          dispatch(updateProfileSuccess(response.data.user));
          dispatch(successAlert("Email updated successfully!"));
          updateUserLocalStorage(response.data.user);
        } else {
          dispatch(
            errorAlert("The email is already taken by another account.")
          );
        }
      })
      .catch((error) => {
        dispatch(updatingEmail(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const updateUserPassword = (data) => {
  return function (dispatch) {
    dispatch(updatingPassword(true));
    return Axios.post(API_URL + "/api/change_user_password", data)
      .then((response) => {
        dispatch(updatingPassword(false));
        if (response.data.status === "1") {
          dispatch(updateProfileSuccess(response.data.user));
          updateUserLocalStorage(response.data.user);
          dispatch(successAlert("Password updated successfully!"));
        } else {
          dispatch(errorAlert("The password you entered is incorrect."));
        }
      })
      .catch((error) => {
        dispatch(updatingPassword(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const updateUserProfile = (data) => {
  return function (dispatch) {
    dispatch(updatingProfile(true));
    return Axios.post(API_URL + "/api/change_user_profile", data)
      .then((response) => {
        dispatch(updatingProfile(false));
        if (response.data.status === "1") {
          dispatch(updateProfileSuccess(response.data.user));
          dispatch(successAlert("Profile updated successfully!"));
          updateUserLocalStorage(response.data.user);
        } else {
          dispatch(errorAlert(response.data.msg));
        }
      })
      .catch((error) => {
        dispatch(updatingProfile(false));
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};

export const fetchNotifications = (id) => {
  return function (dispatch) {
    return Axios.post(API_URL + "/api/get_user_notifications?id=" + id)
      .then((response) => {
        dispatch(updatingProfile(false));
        if (response.data.status === "1") {
          dispatch(fetchNotificationsSuccess(response.data.notifications));
        } else {
          if (response.data.msg !== "No notifications")
            dispatch(errorAlert(response.data.msg));
        }
      })
      .catch((error) => {
        dispatch(errorAlert("Something went wrong.."));
      });
  };
};
