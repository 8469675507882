import React, { useState } from "react";
import "../../css/stylesheet.css";
import UserRow from "./UserRow";
import PaginationButtons from "../PaginationButtons";
import Jumbotron from "../Jumbotron";
import BoxLoading from "../BoxLoading";
import PermissionsModal from "./PermissionsModal";

function TableOfUsers(props) {
  let { data, permissions, editPermissions, selectUser, isLoading } = props;

  //pagination values
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const showEditForm = (user, index) => {
    selectUser({ index: index, user: user });
    //open the AddUser modal in edit mode
    document.getElementById("editModalButton").click();
  };

  const showPermissionsModal = (user) => {
    setSelectedUser(user);
    document.getElementById("permissions-modal-button").click();
  };

  const onDelete = (user, index) => {
    props.setUserToDelete({ index: index, user: user });
  };

  // column titles
  const tableHeader = () => {
    return (
      <tr>
        <th style={{ width: "10px" }}>#</th>
        <th>Name</th>
        <th>Email</th>
        <th>Phone</th>
        <th>Age</th>
        <th>Gender</th>
        <th>Start Date</th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    );
  };

  return (
    <div>
      <div className="box box-success">
        <div className="box-header mb-10 mt-10">
          <h2></h2>
          <div className="box-tools">{props.children}</div>
        </div>
        <div className="box-body no-padding users-table ">
          <table className="table table-bordered">
            <tbody>
              {/* list of users */}
              {filteredUsers.length > 0 && (
                <>
                  {tableHeader()}
                  {filteredUsers.map((item, index) => {
                    return (
                      <UserRow
                        key={"user-" + index}
                        item={item}
                        index={index}
                        editUser={showEditForm}
                        deleteUser={onDelete}
                        setSelectedUser={showPermissionsModal}
                      />
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
        {filteredUsers.length === 0 && <Jumbotron message="No Users" />}
        <div className="box-footer clearfix">
          <PaginationButtons data={data} displayItems={setFilteredUsers} />
        </div>
        {isLoading && <BoxLoading />}
      </div>

      <button
        className="display-none"
        data-toggle="modal"
        id="permissions-modal-button"
        data-target="#PermissionsModal"
      ></button>

      <PermissionsModal
        user={selectedUser}
        permissions={permissions}
        editPermissions={editPermissions}
      />
    </div>
  );
}

export default TableOfUsers;
